import {
  layoutWidth1200,
  mediaDesktop,
  mediaPhoneOnly,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../Heading";
import Text from "../Text";

interface Props {
  items?: Array<{
    description?: string;
    icon?: string;
    title?: string;
  }>;
  maxItems?: number;
}

const IconList: FunctionComponent<Props> = ({
  items,
  maxItems = Number.MAX_SAFE_INTEGER,
}) => {
  return items && items.length ? (
    <ul
      css={css`
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        list-style: none;
        margin: 0;
        max-width: ${layoutWidth1200};
        padding: 4rem 1rem;
        row-gap: 3rem;
        width: 100%;

        @media (min-width: ${mediaPhoneOnly}) {
          column-gap: 7.33%;
        }

        @media (min-width: ${mediaTabletLandscape}) {
          row-gap: 6rem;
        }

        @media (min-width: ${mediaDesktop}) {
          padding: 3rem 0;
        }
      `}
    >
      {items.slice(0, maxItems).map((item) => {
        const { description, icon, title } = item;
        return (
          <li
            css={css`
              box-sizing: border-box;
              display: flex;

              /* fallback style for no grid support */
              margin-bottom: 3rem;

              /* removes fallback style */
              @supports (display: grid) {
                margin-bottom: 0;
              }
            `}
            key={icon}
          >
            {icon ? (
              <img
                alt={""}
                css={css`
                  flex: none;
                  height: 4rem;
                  margin-right: 2rem;
                  width: 4rem;
                `}
                loading={"lazy"}
                role={"presentation"}
                src={icon}
              />
            ) : null}

            <div>
              {title ? (
                <Heading
                  as={"h4"}
                  css={css`
                    margin-bottom: 0.5rem;
                  `}
                  size={"medium"}
                  weight={"semibold"}
                >
                  {title}
                </Heading>
              ) : null}

              {description ? (
                <Text
                  as={"p"}
                  color={"midgray"}
                  css={css`
                    margin-bottom: 0;
                  `}
                  responsive={true}
                  size={"medium"}
                >
                  {description}
                </Text>
              ) : null}
            </div>
          </li>
        );
      })}
    </ul>
  ) : null;
};

export default IconList;
