import TabsWithPills from ".";
import Figures from "./Figures";
import type { Figure } from "./types";

interface Tab {
  figures: Figure[];
  id: string;
  label: string;
}

interface Props {
  mode?: "dark" | "light";
  tabs: Tab[];
  title: string;
}

const Block = ({ tabs, title, mode = "dark" }: Props) => {
  const tabsWithFigures = tabs.map((tab) => {
    return {
      content: <Figures figures={tab.figures} />,
      id: tab.id,
      label: tab.label,
    };
  });
  return <TabsWithPills mode={mode} tabs={tabsWithFigures} title={title} />;
};

export default Block;
