import { colorBlueMedium } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../../../Heading";
import Text from "../../../Text";
import type { Step } from "../types";
import Callout from "./Callout";
import Resources from "./Resources";

type Props = {
  index?: number;
  style?: any;
  title?: string;
  width?: number;
} & Step;

const WorkflowCard: FunctionComponent<Props> = ({
  callout,
  description,
  icon,
  index = 0,
  resources,
  style,
  title,
}) => {
  return (
    <li
      css={css({
        "@media (max-width: ${mediaTabletLandscape})": {
          padding: 16,
          scrollSnapAlign: "center",
          width: 360,
        },
        backgroundColor: "white",
        border: "1px solid rgba(68, 89, 121, 0.06)",
        borderRadius: 6,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
        height: "auto",
        justifyContent: "space-between",
        padding: 32,
        scrollSnapAlign: "start",
        width: 450,
        ...style,
      })}
    >
      <div>
        <span
          css={css`
            color: ${colorBlueMedium};
            font-size: 6rem;
            line-height: 0.8;
            position: absolute;
          `}
        >
          {index + 1}
        </span>
        <img
          alt={`Step ${index + 1}`}
          css={css`
            display: block;
            margin-bottom: 1.5rem;
            width: 50%;
          `}
          loading={"lazy"}
          src={icon}
        />
        <Heading as={"h4"} responsive={true} size={"xlarge"}>
          {title}
        </Heading>
        <Text
          as={"p"}
          color={"midgray"}
          css={css`
            margin-bottom: 1.5rem;
          `}
          responsive={true}
          size={"medium"}
        >
          {description}
        </Text>

        {callout ? <Callout callout={callout} /> : null}
      </div>
      <div>{resources ? <Resources resources={resources} /> : null}</div>
    </li>
  );
};

export default WorkflowCard;
