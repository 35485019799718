import { css } from "@emotion/react";
import type { Link as LinkType } from "../types";
import type { Text as TextInterface, Emphasis } from "../types";

import Grid from "../../../Grid";
import Text from "../../../Text";
import WistiaVideo from "../../../WistiaVideo";
import Link from "../Link";

export type OnPageNav = {
  include: boolean;
  label?: string;
};

export interface Props {
  title?: TextInterface;
  summary?: TextInterface;
  emphasis?: Emphasis;
  video?: {
    wistiaId: string;
    caption: string;
  };
  onPageNav?: OnPageNav;
  link: LinkType;
}

const Header = ({ title, summary, emphasis, video, link }: Props) => {
  const hasContent = summary || emphasis || video || link;
  return (
    <Grid gap={"1rem"}>
      {title && (
        <Grid
          gridTemplateColumns={{
            base: "1fr",
            tabletLandscape: "1fr 400px",
          }}
          gap={{ base: "2rem", tabletLandscape: "4.5rem" }}
        >
          <Text
            as={"h3"}
            size={title.size || "xlarge"}
            color={title.color || "base"}
            weight={"semibold"}
          >
            {title.content}
          </Text>
        </Grid>
      )}

      {hasContent && (
        <Grid
          gridTemplateColumns={{
            base: "1fr",
            tabletLandscape: "1fr 400px",
          }}
          gap={{ base: "2rem", tabletLandscape: "4.5rem" }}
          alignItems={"start"}
        >
          <Grid gap={"1rem"}>
            {summary && (
              <Text
                as={"p"}
                size={summary.size || "small"}
                color={summary.color || "base"}
              >
                {summary.content}
              </Text>
            )}
            {emphasis && (
              <Text
                as={"p"}
                css={css`
                  font-style: ${emphasis.style === "italic"
                    ? "italic"
                    : "normal"};
                `}
                size={emphasis.size || "small"}
                color={emphasis.color || "base"}
                weight={emphasis.style === "italic" ? "regular" : "semibold"}
              >
                {emphasis.content}
              </Text>
            )}
            {link?.url && (
              <Link label={link.label} url={link.url} variant={link.variant} />
            )}
          </Grid>

          {video && (
            <Grid gap={"1rem"}>
              <WistiaVideo wistiaID={video?.wistiaId} />
              <Text
                as={"p"}
                css={css`
                  text-align: center;
                `}
                size={"small"}
                color={"base"}
              >
                {video.caption}
              </Text>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
