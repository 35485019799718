import { css } from "@emotion/react";
import { borderStandard } from "@10xdev/design-tokens";

import Grid from "../../../Grid";
import Text from "../../../Text";

interface Props {
  title: string;
  description: string;
}

const Description = ({ title, description }: Props) => {
  return (
    <Grid
      alignItems={"center"}
      css={css`
        padding: 0.75rem 0;
        border-bottom: ${borderStandard};
      `}
      gap={"1rem 5rem"}
      gridTemplateColumns={{
        base: "1fr",
        tabletPortrait: "32% auto",
      }}
    >
      <Text as={"h4"} size={"large"} weight={"semibold"}>
        {title}
      </Text>
      <Text as={"p"} color={"midgray"} size={"xsmall"}>
        {description}
      </Text>
    </Grid>
  );
};

export default Description;
