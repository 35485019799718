import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

import Text from "../Text";

interface Props {
  children?: ReactNode;
}

const CellStyle = css`
  padding: 1.5rem;
  vertical-align: top;
`;

const ListStyle = css`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const CellList: FunctionComponent<Props> = ({ children }) => {
  return (
    <Text as={"td"} css={CellStyle} size={"small"}>
      <ul css={ListStyle}>{children}</ul>
    </Text>
  );
};

export default CellList;
