import { sizeXxxlarge } from "@10xdev/design-tokens";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";

import Button from "../Button";
import Text from "../Text";

export interface Action {
  label: string;
  target: string;
  url: string;
}

interface Props {
  /**
   * A call-to-action object that defines what
   * the button does when clicked.
   */
  action: Action;

  /**
   * A CSS class name. This has no effect on styling;
   * it is used to identify the button to Google Analytics.
   * */
  className?: string;

  /**
   * A unique identifier. Useful for identifying
   * the button to Google Analytics or other services.
   * */
  id?: string;

  /**
   * Specifies blue text on a white background (dark mode)
   * or white text on a blue background (light mode). */
  mode?: "dark" | "light";

  /**
   * The width of the CTA button, defaults to 240px */
  width?: string;
}

/**
 * A thin wrapper around Button that knows how to interpret
 * call-to-action definitions when the button is clicked.
 */
const CTAButton: FunctionComponent<Props> = ({
  action,
  className,
  id,
  mode,
  width = "240px",
}) => {
  const router = useRouter();

  const handleClick = () => router.push(action.url);

  return (
    <Button
      background={mode === "light" ? "blue" : "white"}
      className={className}
      css={{
        height: sizeXxxlarge,
        width: width,
      }}
      id={id}
      onClick={handleClick}
      shadow={"standard"}
    >
      <Text
        as={"span"}
        color={mode === "light" ? "white" : "blue"}
        size={"medium"}
        weight={"semibold"}
      >
        {action.label}
      </Text>
    </Button>
  );
};

export default CTAButton;
