import {
  borderRadiusMedium,
  colorBlueLight,
  colorBlueLighter,
  colorSteelLighter,
  colorWhite,
  layoutWidth1200,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Paragraph from "../../Paragraph";
import Slash from "../../Slash";
import Text from "../../Text";
import WistiaVideo from "../../WistiaVideo";
import Columns from "./Layout/Columns";
import Stack from "./Layout/Stack";
import ResourceCard from "./ResourceCard";
import type { Resource } from "./types";

interface Props {
  availability: string;
  cta: {
    label: string;
    url: string;
  };
  description: string;
  eyebrow: string;
  list: Array<string>;
  listTitle: string;
  resources: Array<Resource>;
  sectionTitle: string;
  slash: boolean;
  updateList: Array<any>;
}

const Update: FunctionComponent<Props> = ({
  sectionTitle,
  slash = true,
  updateList,
}) => {
  return (
    <div
      className={"Update"}
      css={css`
        max-width: ${layoutWidth1200};
        padding: 6rem 1rem 6rem;
        width: 100%;
      `}
    >
      <Slash slash={slash}>
        <Heading
          as={"h2"}
          css={css`
            display: block;
            margin-bottom: 1.5rem;
          `}
          responsive={true}
          size={"xlarge"}
        >
          {sectionTitle}
        </Heading>
      </Slash>

      <Stack border={true} borderTop={true} gap={"6rem"}>
        {updateList.map((update) => {
          const {
            availability,
            cta,
            description,
            eyebrow,
            list,
            listTitle,
            resources,
            title,
            wistiaId,
          } = update;

          const ctaUrl: string = cta.marketoLastTouchOfferName
            ? `${cta.url}?Last_Touch_Offer_Name__c=${cta.marketoLastTouchOfferName}`
            : cta.url;

          return (
            <div key={title}>
              <Columns>
                <div
                  css={css`
                    padding-bottom: 2.5rem;

                    @media (min-width: ${mediaTabletLandscape}) {
                      padding-bottom: 0;
                      padding-right: 10%;
                    }
                  `}
                >
                  {eyebrow ? (
                    <Text
                      as={"div"}
                      color={"blue"}
                      css={css`
                        margin-bottom: 1rem;
                      `}
                      size={"large"}
                      weight={"semibold"}
                    >
                      {eyebrow}
                    </Text>
                  ) : null}

                  {title ? (
                    <Heading
                      as={"h3"}
                      css={css`
                        margin-bottom: 2.5rem;
                      `}
                      responsive={true}
                      size={"xxlarge"}
                    >
                      {title}
                    </Heading>
                  ) : null}

                  {availability ? (
                    <Text
                      as={"div"}
                      color={"midgray"}
                      css={css`
                        margin-bottom: 1rem;
                      `}
                      size={"small"}
                      weight={"medium"}
                    >
                      {availability}
                    </Text>
                  ) : null}

                  {cta.url ? (
                    <div
                      css={css`
                        background: ${colorWhite};
                        border: solid 1px;
                        border-radius: ${borderRadiusMedium};
                        border-color: ${colorSteelLighter};
                        max-width: 190px;

                        :hover {
                          background: ${colorBlueLighter};
                          border-color: ${colorBlueLight};
                        }
                      `}
                    >
                      <Text
                        as={"div"}
                        color={"blue"}
                        css={css`
                          text-align: center;
                        `}
                        size={"small"}
                        weight={"medium"}
                      >
                        <Anchor
                          color={"inherit"}
                          css={{ display: "block", padding: ".5rem 1rem" }}
                          href={ctaUrl}
                        >
                          {cta.label}
                        </Anchor>
                      </Text>
                    </div>
                  ) : null}
                </div>

                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                  `}
                >
                  {description ? (
                    <Paragraph color={"midgray"} size={"medium"}>
                      {description}
                    </Paragraph>
                  ) : null}

                  {listTitle ? (
                    <Heading as={"h4"} size={"medium"} weight={"semibold"}>
                      {listTitle}
                    </Heading>
                  ) : null}

                  {list && list.length ? (
                    <ul
                      css={css`
                        margin: 0 0 1.5rem;
                        padding-left: 1rem;
                      `}
                    >
                      {list.map((item: string) => {
                        return (
                          <Text
                            as={"li"}
                            color={"midgray"}
                            css={css`
                              margin-bottom: 0.5rem;
                            `}
                            key={item[0]}
                            size={"medium"}
                          >
                            {item}
                          </Text>
                        );
                      })}
                    </ul>
                  ) : null}

                  {wistiaId ? <WistiaVideo wistiaID={wistiaId} /> : null}

                  {resources?.map((resource: Resource) => {
                    const { cardColor, citation, href, hover, title, type } =
                      resource;
                    return (
                      <ResourceCard
                        cardColor={cardColor}
                        citation={citation}
                        hover={hover}
                        href={href}
                        key={citation}
                        title={title}
                        type={type}
                      />
                    );
                  })}
                </div>
              </Columns>
            </div>
          );
        })}
      </Stack>
    </div>
  );
};

export default Update;
