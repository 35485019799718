/** FIXME This component seems not to be used anywhere */

import {
  colorBlueDark,
  layoutWidth1200,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import type { IconSource } from "../../Icon/types";
import SectionHeading from "../../SectionHeading";
import Text from "../../Text";
import { MIN_CARD_WIDTH } from "./constants";
import { getCard } from "./utils";

// Props that come in from graphql queries and are aliased
// by necessity
interface AliasedProps {
  cssColumns: string;
}

interface UnaliasedProps {
  columns: string;
}

interface BaseProps {
  cardList?: Array<any>;
  gridGap?: string;
  label?: string;
  link?: {
    label?: string;
    type?: IconSource;
    url?: string;
  };
  padding?: string;
  sectionHeading?: {
    description?: string;
    eyebrow?: string;
    heading: string;
    headingLink?: {
      label?: string;
      type?: IconSource;
      url?: string;
    };
    headingSize: "xxxlarge" | "xxlarge";
    slash: boolean;
  };
}

type Props = (AliasedProps & BaseProps) | (UnaliasedProps & BaseProps);

const DEFAULT_CSS_COLUMNS = "col-1";

const Collection: FunctionComponent<Props> = ({
  cardList,
  gridGap = "0px",
  label,
  link,
  padding = "4rem 1rem",
  sectionHeading,
  ...props
}) => {
  let columns;

  // delete all this once all pages are migrated to graphql
  if ("cssColumns" in props) {
    columns = props.cssColumns || DEFAULT_CSS_COLUMNS;
  } else {
    columns = props.columns || DEFAULT_CSS_COLUMNS;
  }

  return (
    <div
      css={css`
        max-width: ${layoutWidth1200};
        padding: 2rem 1rem;
        width: 100%;

        @media (min-width: ${mediaTabletLandscape}) {
          padding: ${padding};
        }
      `}
    >
      {sectionHeading ? (
        <SectionHeading
          description={sectionHeading.description}
          eyebrow={sectionHeading.eyebrow}
          heading={sectionHeading.heading}
          headingSize={sectionHeading.headingSize}
          link={sectionHeading.headingLink}
          marginBottom={sectionHeading.heading ? "4rem" : "0px"}
          narrow={cardList?.[0]?.type === "topicCard" ? true : false}
          slash={sectionHeading.slash}
        />
      ) : null}

      {label ? (
        <Heading
          as={"h4"}
          css={css`
            margin-bottom: 3.5rem;
          `}
          responsive={true}
          size={"xlarge"}
        >
          {label}
        </Heading>
      ) : null}

      {cardList?.length ? (
        <ul
          css={css`
            display: grid;
            gap: ${gridGap};
            grid-template-columns: repeat(
              auto-fill,
              minmax(${MIN_CARD_WIDTH[columns]}, 1fr)
            );
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;
          `}
        >
          {cardList.map((card) => {
            return getCard(card);
          })}
        </ul>
      ) : null}

      {link?.url ? (
        <div
          css={css`
            align-items: center;
            display: flex;
            flex-direction: column;
            width: 100%;
          `}
        >
          {link?.url ? (
            <Text
              as={"div"}
              color={"blue"}
              css={css`
                padding-right: 1rem;
              `}
              key={link.url}
              size={"small"}
              weight={"semibold"}
            >
              <Anchor
                color={"inherit"}
                hoverColor={colorBlueDark}
                href={link.url}
              >
                {link.label}
                {link?.type ? (
                  <Icon
                    size={"12px"}
                    source={link.type}
                    xPos={"16px"}
                    yPos={"1px"}
                  />
                ) : null}
              </Anchor>
            </Text>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Collection;
