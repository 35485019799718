import {
  borderRadiusMedium,
  borderStandard,
  colorBlueDark,
  colorBlueMedium,
  layoutWidth1200,
  mediaTabletLandscape,
  mediaTabletPortrait,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Paragraph from "../../Paragraph";
import Text from "../../Text";

interface Props {
  columns?: Array<{
    cta?: {
      label: string;
      url: string;
    };
    description?: string;
    image?: {
      alt?: string;
      url?: string;
    };
    title?: string;
  }>;
  slash?: boolean;
  title?: string;
}

const FeatureOptions: FunctionComponent<Props> = ({
  columns,
  slash = true,
  title,
}) => {
  return (
    <div
      className={"FeatureOptions"}
      css={css`
        box-sizing: border-box;
        max-width: ${layoutWidth1200};
        padding: 5rem 1rem;
        width: 100%;

        ::before {
          border-top: ${slash ? "1px" : 0} solid ${colorBlueMedium};
          content: "";
          position: absolute;
          transform: translate(-48px, 16px) rotate(120deg);
          transform-origin: 0% 0%;
          width: 300px;
        }

        @media (max-width: ${mediaTabletLandscape}) {
          padding: 0 1rem;
        }
      `}
    >
      <Heading
        as={"h3"}
        css={css`
          margin-bottom: 4rem;
        `}
        responsive={true}
        size={"xxxlarge"}
      >
        {title}
      </Heading>

      <div
        css={css`
          box-sizing: border-box;
          display: flex;

          @media (max-width: ${mediaTabletLandscape}) {
            display: block;
            padding-right: 25%;
          }

          @media (max-width: ${mediaTabletPortrait}) {
            padding-right: 0;
          }
        `}
      >
        {columns && columns.length
          ? columns.map((column, index) => {
              const { cta, description, image, title } = column;

              return (
                <div
                  css={css`
                    border-right: ${borderStandard};
                    display: flex;
                    flex-direction: column;
                    padding-left: 4rem;
                    padding-right: 4rem;
                    width: 33%;

                    :first-of-type {
                      padding-left: 0;
                    }

                    :last-of-type {
                      border-right: none;
                      padding-right: 0;
                    }

                    @media (max-width: ${mediaTabletLandscape}) {
                      border: 0;
                      border-bottom: ${borderStandard};
                      margin-bottom: 3rem;
                      padding: 0 0 2rem;
                      width: 100%;
                    }
                  `}
                  key={index}
                >
                  {image?.url ? (
                    <div
                      aria-label={image.alt}
                      css={css`
                        background-image: url(${image.url});
                        background-position: center;
                        background-size: cover;
                        border-radius: ${borderRadiusMedium};
                        height: 180px;
                        margin-bottom: 1.5rem;

                        @media (max-width: ${mediaTabletPortrait}) {
                          height: 240px;
                        }
                      `}
                      role={"img"}
                    ></div>
                  ) : null}

                  <Heading as={"h4"} size={"large"}>
                    {title}
                  </Heading>
                  <Paragraph color={"midgray"} size={"medium"}>
                    {description}
                  </Paragraph>

                  {cta ? (
                    <div
                      css={css`
                        margin-top: auto;
                      `}
                    >
                      <Text
                        as={"span"}
                        color={"blue"}
                        size={"small"}
                        weight={"medium"}
                      >
                        <Anchor
                          color={"inherit"}
                          hoverColor={colorBlueDark}
                          href={cta.url}
                        >
                          {cta.label}
                          <Icon
                            size={"14px"}
                            source={"nav-right"}
                            xPos={"12px"}
                            yPos={"2px"}
                          />
                        </Anchor>
                      </Text>
                    </div>
                  ) : null}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default FeatureOptions;
