import { css } from "@emotion/react";
import type { KeyMetric } from "./Masthead";
import Grid from "../../../Grid";
import Text from "../../../Text";
import { borderRadiusMedium, colorBlueLightest } from "@10xdev/design-tokens";

interface Props {
  keyMetrics: KeyMetric[];
}

const KeyMetrics = ({ keyMetrics }: Props) => {
  return (
    <Grid
      gap={"2rem"}
      gridTemplateColumns={"repeat(auto-fit, minmax(150px, 1fr))"}
    >
      {keyMetrics.map((metric) => {
        const { primary, secondary, tertiary } = metric;
        return (
          <Grid
            alignItems={{ base: "start", tabletPortrait: "center" }}
            css={css`
              padding: 1rem;
              border-radius: ${borderRadiusMedium};
              background: ${colorBlueLightest};
              box-shadow: 0px 1.72px 4.29px 0px rgba(0, 0, 0, 0.06);
            `}
            gap={".25rem"}
          >
            {primary && (
              <Text as={"p"} size={"xlarge"} weight={"semibold"}>
                {primary}
              </Text>
            )}
            {secondary && (
              <Text as={"p"} size={"medium"} weight={"semibold"}>
                {secondary}
              </Text>
            )}
            {tertiary && (
              <Text as={"p"} size={"medium"} weight={"regular"}>
                {tertiary}
              </Text>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default KeyMetrics;
