import {
  layoutWidth1200,
  mediaDesktop,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useState } from "react";

import Button from "../../Button";
import Icon from "../../Icon";

interface Props {
  aspectRatio?: number;
  gap?: number;
  imageHeight?: number;
  images: Array<{
    alt: string;
    url: string;
  }>;
  imagesPerScroll?: number;
}

const ImageCarousel: FunctionComponent<Props> = ({
  aspectRatio = 1.1,
  gap = 16,
  images,
  imagesPerScroll = 3,
  imageHeight = 312,
}) => {
  const imageWidth = Math.round(imageHeight * aspectRatio);
  const viewWidth = (imageWidth + gap) * imagesPerScroll;
  const carouselWidth = (imageWidth + gap) * images.length;

  const [offSet, setOffSet] = useState(0);

  const handlePreviousClick = () => {
    setOffSet(offSet + viewWidth);
  };

  const handleNextClick = () => {
    setOffSet(offSet - viewWidth);
  };

  return (
    <div
      className={"ImageCarousel"}
      css={css`
        max-width: ${layoutWidth1200};
        overflow-x: hidden;
        padding: 4rem 1rem;

        @media (min-width: ${mediaDesktop}) {
          padding-left: 0;
          padding-right: 0;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          overflow-x: scroll;
          padding-bottom: 2rem;
          width: 100%;

          @media (min-width: ${mediaTabletLandscape}) {
            margin-bottom: 1.5rem;
            overflow: visible;
            padding-bottom: 0;
            transform: translateX(${offSet}px);
            transition: transform 0.4s cubic-bezier(0.77, 0.17, 0.21, 0.81);
          }
        `}
      >
        {images?.map((image) => {
          const { alt, url } = image;

          return (
            <div
              css={css`
                flex: 1;
                margin-right: ${gap}px;
              `}
              key={url}
            >
              <img
                alt={alt}
                css={css`
                  border-radius: 0.375rem;
                  height: ${imageHeight}px;
                  object-fit: cover;
                  width: ${imageWidth}px;
                `}
                key={url}
                src={url}
              />
            </div>
          );
        })}
      </div>

      <div
        css={css`
          display: none;

          @media (min-width: ${mediaTabletLandscape}) {
            display: flex;
            justify-content: flex-end;
          }
        `}
      >
        <Button
          background={"white"}
          border={true}
          color={"blue"}
          css={{ marginRight: "1rem", width: "3rem" }}
          disabled={offSet >= 0}
          onClick={handlePreviousClick}
        >
          <Icon color={"inherit"} size={"1rem"} source={"nav-left"} />
        </Button>
        <Button
          background={"white"}
          border={true}
          color={"blue"}
          css={{
            width: "3rem",
          }}
          disabled={offSet + carouselWidth < 1200}
          onClick={handleNextClick}
        >
          <Icon size={"1rem"} source={"nav-right"} />
        </Button>
      </div>
    </div>
  );
};

export default ImageCarousel;
