import { css } from "@emotion/react";
import type { Group } from "./ResourceCards";

import Grid from "../../../Grid";
import Text from "../../../Text";
import Anchor from "../../../Anchor";
import {
  borderStandard,
  borderRadiusMedium,
  colorSteelDarker,
} from "@10xdev/design-tokens";

const Card = ({ title, list }: Group) => {
  return (
    <Grid
      alignContent={"start"}
      css={css`
        border: ${borderStandard};
        border-radius: ${borderRadiusMedium};
      `}
      padding={"1rem 1.5rem"}
    >
      <div
        css={css`
          padding-bottom: 1rem;
          border-bottom: ${borderStandard};
        `}
      >
        <Text as={"h4"} size={"medium"} weight={"semibold"}>
          {title}
        </Text>
      </div>
      <ul
        css={css`
          display: grid;
          gap: 0.5rem;
          margin: 0;
          padding: 1rem 0 0 1.25rem;
          color: ${colorSteelDarker};
        `}
      >
        {list.map((item) => (
          <li key={item.label}>
            <Anchor href={item.url}>
              <Text as={"p"} color={"inherit"} size={"small"}>
                {item.label}
              </Text>
            </Anchor>
          </li>
        ))}
      </ul>
    </Grid>
  );
};

export default Card;
