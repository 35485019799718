import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../../Heading";
import ThroughputLevel from "./ThroughputLevel";

interface Props {
  versions: {
    levels: {
      cells: string;
      label: string;
      note?: string;
    }[];
    mode?: string;
  }[];
}

const Throughput: FunctionComponent<Props> = ({ versions }) => {
  return (
    <>
      {versions.map((version) => {
        const { levels, mode } = version;

        return (
          <div
            css={css`
              :first-of-type {
                margin-bottom: 2rem;
              }
            `}
            key={levels[0].cells}
          >
            {mode ? (
              <Heading
                as={"h5"}
                css={css`
                  margin-bottom: 8px;
                `}
                responsive={true}
                size={"xsmall"}
                weight={"semibold"}
              >
                {mode}
              </Heading>
            ) : null}

            {levels.map((level) => {
              return <ThroughputLevel key={level.label} level={level} />;
            })}
          </div>
        );
      })}
    </>
  );
};

export default Throughput;
