import {
  colorBlueDark,
  mediaTabletLandscape,
  sizeLarge,
  sizeMedium,
  sizeXlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Text from "../../Text";

interface Props {
  product: { name?: string | null; slug: string };
}

const ProductHeading: FunctionComponent<Props> = ({ product }) => {
  const { name, slug } = product;

  return (
    <th
      css={css`
        padding: ${sizeLarge} ${sizeXlarge};
        text-align: left;
        vertical-align: top;

        @media (max-width: ${mediaTabletLandscape}) {
          max-width: 200px;
          padding: ${sizeMedium};
        }
      `}
    >
      <Heading
        as={"h4"}
        css={css`
          margin-bottom: 2rem;
        `}
        responsive={true}
        size={"medium"}
      >
        {name}
      </Heading>
      <Anchor
        aria-label={`view ${name} details`}
        color={"blue"}
        hoverColor={colorBlueDark}
        href={`/products/${slug}`}
      >
        <Text
          as={"span"}
          color={"inherit"}
          css={css`
            display: inline-block;
            margin-right: 1rem;
          `}
          size={"small"}
          weight={"medium"}
        >
          {"View details"}
        </Text>
        <Icon size={"12px"} source={"nav-right"} yPos={"2px"} />
      </Anchor>
    </th>
  );
};

export default ProductHeading;
