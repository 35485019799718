import { css } from "@emotion/react";
import Grid from "../../Grid";
import type { Props as Header } from "../../ProductPage/SectionHeader";
import SectionHeader from "../../ProductPage/SectionHeader";
import Carousel from "../../Carousel";
import Analysis from "../../ProductPage/cards/analysis";

interface Props {
  header: Header;
  data: {
    name: string;
    image: string;
    description: string;
    pageUrl: string;
  }[];
}

const carouselSettings = {
  itemHeight: 282,
  aspectRatio: 360 / 282,
  gap: 16,
  itemsPerScroll: 1,
};

const DataAnalysis = ({ header, data }: Props) => {
  return (
    <Grid
      css={css`
        box-sizing: border-box;
        max-width: 1232px;
        width: 100%;
      `}
      gap={"1.5rem"}
      padding={{ base: "0 1rem 2rem", tabletPortrait: "0 1rem 4.5rem" }}
    >
      {header ? <SectionHeader {...header} /> : null}
      {data?.length ? (
        <Carousel {...carouselSettings}>
          {data.map((analysis) => (
            <Analysis {...analysis} key={analysis.name} />
          ))}
        </Carousel>
      ) : null}
    </Grid>
  );
};

export default DataAnalysis;
