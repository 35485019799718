import {
  colorWhite,
  layoutWidth878,
  mediaTabletPortrait,
  sizeMedium,
  sizeXlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Card from "../Card";
import Heading from "../Heading";
import Icon from "../Icon";
import Text from "../Text";

interface Props {
  background?: string;
  hover?: boolean;
  id?: string;
  info?: string;
  title?: string;
  url: string;
}

const DocumentCard: FunctionComponent<Props> = ({
  background,
  url,
  hover,
  info,
  title,
}) => {
  return (
    <Anchor
      css={css`
        display: block;
        margin: 0;
        margin-bottom: ${sizeMedium};

        @media (max-width: ${layoutWidth878}) {
          margin-right: 1rem;
        }
      `}
      href={url}
    >
      <Card
        css={css`
          background: ${background || "none"};
          box-shadow: 0px 1px 0px rgba(21, 48, 87, 0.15);
          display: flex;
          flex-direction: row;
          min-height: 214px;
          overflow: hidden;
          padding: 3rem 6rem 3rem 3rem;
          position: relative;
          text-align: left;
          text-decoration: none;
          transition: all 0.2s ease-in;
          width: 100%; /* for IE11 */

          &:hover {
            .document-card {
              opacity: 1;
            }
          }

          @media (max-width: ${layoutWidth878}) {
            background-color: ${colorWhite};
            display: block;
            min-height: 298px;
            padding: 1.5rem 1.5rem 1rem 1.5rem;
            min-width: 280px;
          }
        `}
        hoverable={hover}
      >
        <div
          css={css`
            flex: none;
            height: 2rem;
            margin: 0.25rem 3rem 0 0;
            width: 2rem;

            @media (max-width: ${layoutWidth878}) {
              height: ${sizeXlarge};
              margin: 0 0 1rem;
              width: ${sizeXlarge};
            }
          `}
        >
          <Icon color={"blue"} size={"32px"} source={"file-alt"} />
        </div>
        <div
          css={css`
            @media (max-width: ${layoutWidth878}) {
              display: block;
              flex-direction: column;
              max-height: 100%;
              justify-content: space-between;
              overflow-y: hidden;
            }
          `}
        >
          <div
            css={css`
              display: block;

              @media (max-width: ${layoutWidth878}) {
                display: none;
              }
            `}
          >
            <Heading
              as={"h4"}
              css={css`
                margin-bottom: 0.75rem;
              `}
              size={"large"}
            >
              {title}
            </Heading>
          </div>
          <div
            css={css`
              display: none;

              @media (max-width: ${layoutWidth878}) {
                display: block;
              }
            `}
          >
            <Heading
              as={"h4"}
              css={css`
                margin-bottom: 0.75rem;
              `}
              size={"medium"}
            >
              {title}
            </Heading>
          </div>
          <div
            css={css`
              display: block;

              @media (max-width: ${layoutWidth878}) {
                display: none;
              }
            `}
          >
            <Text as={"p"} color={"steelDark"} size={"xsmall"}>
              {info}
            </Text>
          </div>
          <div
            css={css`
              display: none;

              @media (max-width: ${layoutWidth878}) {
                bottom: ${sizeMedium};
                display: block;
                position: absolute;
              }
            `}
          >
            <Text as={"p"} color={"steelDark"} size={"xxsmall"}>
              {info}
            </Text>
          </div>
        </div>
        <div
          css={css`
            position: absolute;
            right: 1.5rem;
            top: 50%;
            transform: translateY(-50%);
            transition: opacity 0.2s ease-in;

            @media (max-width: ${layoutWidth878}) {
              display: none;
            }
          `}
        >
          <div
            className={"document-card"}
            css={css`
              opacity: 0;
              transition: opacity 0.2s ease-in;

              @media (max-width: ${mediaTabletPortrait}) {
                opacity: 1;
              }
            `}
          >
            <Icon color={"blue"} size={"20px"} source={"download"} />
          </div>
        </div>
      </Card>
    </Anchor>
  );
};

export default DocumentCard;
