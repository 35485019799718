import { colorBlueDark, sizeMedium } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Text from "../../Text";

interface Props {
  link?: {
    label?: string;
    url?: string;
  };
  title?: string;
}

const SectionHeading: FunctionComponent<Props> = ({ link, title }) => {
  const { label, url } = link || {};
  return (
    <>
      <Heading
        as={"h3"}
        css={css`
          margin-bottom: ${sizeMedium};
          text-align: center;
        `}
        responsive={true}
        size={"xxlarge"}
        weight={"semibold"}
      >
        {title}
      </Heading>
      <Text
        as={"div"}
        color={"blue"}
        css={css`
          margin-bottom: 2rem;
          padding-right: 1rem;
          text-align: center;
        `}
        size={"medium"}
        weight={"semibold"}
      >
        {url ? (
          <Anchor color={"inherit"} hoverColor={colorBlueDark} href={url}>
            {label}
            <Icon
              size={"12px"}
              source={"nav-right"}
              xPos={"16px"}
              yPos={"2px"}
            />
          </Anchor>
        ) : (
          label
        )}
      </Text>
    </>
  );
};

export default SectionHeading;
