import {
  mediaTabletLandscape,
  spacing32,
  spacing48,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { Property } from "csstype";

import Text from "../../Text";
import Figure from "../Figure";
import type { Figure as FigureType } from "../TabsWithPills/types";
import type { Mode } from "../types";

interface Props {
  columns: number | Property.GridTemplateColumns;
  figures: FigureType[];
  heading?: string;
  mode?: Mode;
  rows: number | Property.GridTemplateRows;
}
const FiguresGrid = ({
  columns,
  heading,
  figures,
  mode = "dark",
  rows,
}: Props) => {
  const gridTemplateColumns =
    typeof columns === "number" ? `repeat(${columns}, 1fr)` : columns;
  const gridTemplateRows =
    typeof rows === "number" ? `repeat(${rows}, 1fr)` : rows;
  return (
    <div>
      {heading ? (
        <Text
          as={"h4"}
          color={"white"}
          css={css`
            align-self: center;
            @media (min-width: ${mediaTabletLandscape}) {
              align-self: flex-start;
            }
          `}
          size={"medium"}
          weight={"semibold"}
        >
          {heading}
        </Text>
      ) : null}
      <div
        css={css`
          display: grid;
          gap: ${spacing32};
          grid-template-columns: 1fr;
          justify-items: center;

          @media (min-width: ${mediaTabletLandscape}) {
            gap: ${spacing48};
            grid-template-columns: ${gridTemplateColumns};
            grid-template-rows: ${gridTemplateRows};
          }
        `}
      >
        {figures.map((figure) => (
          <Figure
            caption={figure.caption}
            css={css`
              min-width: 0;
            `}
            image={figure.image}
            key={figure.caption.text}
            layout={figure.layout}
            mode={mode}
          />
        ))}
      </div>
    </div>
  );
};

export default FiguresGrid;
