import {
  borderRadiusMedium,
  colorGreyscale7,
  colorSteelLighter,
  colorSteelMedium,
  colorWhite,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { kebabCase } from "lodash-es";
import type { ReactNode } from "react";
import { useState } from "react";

import { EASE } from "../../../constants";
import type { colors } from "../../Text";
import Text from "../../Text";
import Tab from "./Tab";

export interface Tab {
  content: ReactNode;
  id: string;
  label: string;
}

interface Props {
  mode: "dark" | "light";
  tabs: Tab[];
  title: string;
}

const TabsWithPills = ({ title, tabs, mode = "dark" }: Props) => {
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);

  const theme =
    mode === "dark"
      ? {
          backgroundColor: colorGreyscale7,
          borderColor: colorSteelMedium,
          color: "white",
        }
      : {
          backgroundColor: colorWhite,
          borderColor: colorSteelLighter,
          color: "steelDarkest",
        };

  return (
    <div
      css={css`
        background-color: ${theme.backgroundColor};
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        max-width: 1200px;
      `}
      id={kebabCase(title)}
    >
      <div
        css={css`
          outline: none;
          padding: 0;
          text-align: left;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 1rem;
          `}
        >
          <Text
            as={"h4"}
            color={theme.color as keyof typeof colors}
            size={"medium"}
            weight={"semibold"}
          >
            {title}
          </Text>
          <div
            css={css`
              display: flex;
              gap: 1rem;
              width: 100%;
              flex-wrap: wrap;
            `}
          >
            {tabs.map((tab) => {
              const active = activeTab.id === tab.id;

              return (
                <Tab
                  active={active}
                  key={tab.id}
                  mode={mode}
                  onClick={() => setActiveTab(tab)}
                >
                  <Text
                    as={"span"}
                    color={"inherit"}
                    size={"small"}
                    weight={"semibold"}
                  >
                    {tab.label}
                  </Text>
                </Tab>
              );
            })}
          </div>
        </div>
      </div>

      <div
        css={css`
          background-color: ${theme.backgroundColor};
          border: 1px solid ${theme.borderColor};
          border-radius: ${borderRadiusMedium};
          width: 100%;
          box-sizing: border-box;
          overflow: hidden;
          position: relative;
          transition: all 0.3s ${EASE};
          padding: 48px 64px;

          @media (max-width: ${mediaTabletLandscape}) {
            padding: 36px;
          }
        `}
      >
        {activeTab.content}
      </div>
    </div>
  );
};

export default TabsWithPills;
