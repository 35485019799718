import {
  borderRadiusMedium,
  colorBlueDark,
  colorBlueLightest,
  colorBlueMedium,
  fontFamilyBase,
  fontLetterSpacingCaps,
  layoutWidth1200,
  mediaDesktop,
  mediaTabletLandscape,
  sizeLarge,
  sizeMedium,
  sizeSmall,
  sizeXlarge,
  sizeXxlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Paragraph from "../../Paragraph";
import Text from "../../Text";

interface Props {
  description?: string;
  steps?: Array<{
    callout?: {
      description?: string;
      title?: string;
    };
    description?: string;
    icon?: string;
    resources?: {
      links?: Array<any>;
      title?: string;
    };
    title?: string;
  }>;
  title?: string;
}

const Workflow: FunctionComponent<Props> = ({ description, steps, title }) => {
  return (
    <div
      className={"Worflow"}
      css={css`
        max-width: ${layoutWidth1200};
        padding: 4rem 1rem;

        ::before {
          border-top: 1px solid ${colorBlueMedium};
          content: "";
          position: absolute;
          transform: translate(-48px, 16px) rotate(120deg);
          transform-origin: 0% 0%;
          width: 300px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-bottom: ${sizeXlarge};

          @media (min-width: ${mediaTabletLandscape}) {
            flex-direction: row;
            justify-content: space-between;
          }
        `}
      >
        <Heading
          as={"h3"}
          css={css`
            display: block;
          `}
          responsive={true}
          size={"xxxlarge"}
        >
          {title}
        </Heading>

        {description ? (
          <div
            css={css`
              @media (min-width: ${mediaTabletLandscape}) {
                padding-top: ${sizeLarge};
                width: 55%;
              }

              @media (min-width: ${mediaDesktop}) {
                padding-right: 10%;
                width: 50%;
              }
            `}
          >
            <Paragraph color={"midgray"} responsive={true} size={"medium"}>
              {description}
            </Paragraph>
          </div>
        ) : null}
      </div>

      <ol
        css={css`
          list-style: none;
          margin: 0;
          padding: 0;
        `}
      >
        {steps && steps.length
          ? steps.map((step, index) => {
              const { callout, description, icon, resources, title } = step;
              return (
                <li
                  css={css`
                    @media (min-width: ${mediaDesktop}) {
                      /* Extra wrap for IE11 only - makes flex children same height */
                      display: flex;
                      flex-direction: column;
                    }
                  `}
                  key={title}
                >
                  <div
                    css={css`
                      border-bottom: solid 1px #f4f6f9;
                      padding: ${sizeXlarge} 0;

                      :last-of-type {
                        border-bottom: 0;
                      }

                      @media (min-width: ${mediaTabletLandscape}) {
                        align-items: flex-start;
                        display: flex;
                        justify-content: space-between;
                        padding: ${sizeXxlarge} 0;
                      }

                      @media (min-width: ${mediaDesktop}) {
                        padding: 4rem 0;
                      }
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        flex: 0 0 auto;
                        margin-bottom: ${sizeXlarge};
                        width: 50%;
                        height: 100%;

                        @media (min-width: ${mediaTabletLandscape}) {
                          margin-bottom: 0;
                          width: 35%;
                        }

                        @media (min-width: ${mediaDesktop}) {
                          width: 30%;
                        }
                      `}
                    >
                      <span
                        css={css`
                          color: ${colorBlueMedium};
                          font-family: ${fontFamilyBase};
                          font-size: 6rem;
                          line-height: 0.8;
                          position: absolute;

                          @media (min-width: ${mediaTabletLandscape}) {
                            font-size: 8rem;
                            line-height: 0.8;
                          }

                          @media (min-width: ${mediaDesktop}) {
                            font-size: 8rem;
                          }
                        `}
                      >
                        {index + 1}
                      </span>
                      <img
                        alt={`Step ${index + 1}`}
                        css={css`
                          display: block;
                          height: 100%;
                          width: 100%;
                        `}
                        loading={"lazy"}
                        src={icon}
                      />
                    </div>

                    <div
                      css={css`
                        @media (min-width: ${mediaTabletLandscape}) {
                          padding-top: ${sizeLarge};
                          width: 55%;
                        }

                        @media (min-width: ${mediaDesktop}) {
                          padding: ${sizeLarge} 10% 0 0;
                          width: 50%;
                        }
                      `}
                    >
                      <Heading
                        as={"h4"}
                        css={css`
                          margin-bottom: ${sizeXlarge};
                        `}
                        responsive={true}
                        size={"xlarge"}
                        weight={"semibold"}
                      >
                        {title}
                      </Heading>
                      <Paragraph
                        color={"midgray"}
                        css={css`
                          margin-bottom: ${sizeXlarge};
                        `}
                        responsive={true}
                        size={"medium"}
                      >
                        {description}
                      </Paragraph>

                      {callout ? (
                        <div
                          css={css`
                            background-color: ${colorBlueLightest};
                            border-radius: ${borderRadiusMedium};
                            margin-bottom: ${sizeXlarge};
                            padding: ${sizeLarge};

                            @media (min-width: ${mediaTabletLandscape}) {
                              max-width: 70%;
                            }
                          `}
                        >
                          <Text
                            as={"p"}
                            color={"midgray"}
                            css={css`
                              letter-spacing: 0.05rem;
                              margin-bottom: ${sizeSmall};
                              text-transform: uppercase;
                            `}
                            size={"xxsmall"}
                            weight={"semibold"}
                          >
                            {callout.title}
                          </Text>
                          <Text as={"p"} color={"midgray"} size={"xsmall"}>
                            {callout.description}
                          </Text>
                        </div>
                      ) : null}

                      {resources ? (
                        <>
                          <div
                            css={css`
                              margin-bottom: ${sizeMedium};
                            `}
                          >
                            <Text
                              as={"span"}
                              color={"gray"}
                              css={css`
                                letter-spacing: ${fontLetterSpacingCaps};
                                text-transform: uppercase;
                              `}
                              size={"xxsmall"}
                              weight={"semibold"}
                            >
                              {resources.title}
                            </Text>
                          </div>

                          <ul
                            css={css`
                              display: flex;
                              flex-direction: column;
                              gap: 0.5rem;
                              list-style: none;
                              padding: 0;
                            `}
                          >
                            {resources?.links?.map((link) => {
                              const { label, type, url } = link;

                              return (
                                <li key={label}>
                                  {url ? (
                                    <Text
                                      as={"span"}
                                      color={"blue"}
                                      size={"small"}
                                      weight={"semibold"}
                                    >
                                      <Anchor
                                        color={"inherit"}
                                        hoverColor={colorBlueDark}
                                        href={url}
                                      >
                                        {label}
                                        <Icon
                                          size={"12px"}
                                          source={type}
                                          xPos={"16px"}
                                          yPos={"1px"}
                                        />
                                      </Anchor>
                                    </Text>
                                  ) : (
                                    <Heading
                                      as={"h4"}
                                      css={css`
                                        margin-bottom: ${sizeSmall};
                                      `}
                                      size={"small"}
                                    >
                                      {label}
                                    </Heading>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      ) : null}
                    </div>
                  </div>
                </li>
              );
            })
          : null}
      </ol>
    </div>
  );
};

export default Workflow;
