import {
  borderStandard,
  layoutWidth1200,
  mediaDesktop,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import classNames from "classnames";
import type { FunctionComponent, ReactNode } from "react";

import Anchor from "../../Anchor";
import Button from "../../Button";
import Icon from "../../Icon";
import type { IconSource } from "../../Icon/types";
import Text from "../../Text";
import SubnavList from "./SubnavList";

interface Props {
  blocks?: {
    includeInNav?: boolean;
    index: number;
    navTitle?: string;
    slug: string;
    type?: string;
  }[];
  button?: {
    icon: IconSource;
    id?: string;
    label: string;
    url: string;
  };
  className?: string;
  link?: {
    label: string;
    url: string;
  };
  list?: ReactNode;
}

/**
 * An inner page navigation component that assembles
 * itself from the passed sections.
 */
const Subnav: FunctionComponent<Props> = ({
  blocks,
  button,
  link,
  className,
  list,
}) => {
  return (
    <nav
      aria-label={"in-page navigation"}
      className={classNames("Subnav", className)}
      css={css`
        align-items: center;
        border-bottom: ${borderStandard};
        display: flex;
        height: 4.5rem;
        justify-content: space-between;
        width: ${layoutWidth1200};

        @media (max-width: ${mediaDesktop}) {
          display: none;
        }
      `}
    >
      {list || (blocks && <SubnavList blocks={blocks} />)}

      {button?.label ? (
        <Button
          background={"white"}
          border={true}
          color={"blue"}
          css={css`
            height: 40px;
            padding: 0 1rem;
            width: auto;
          `}
          id={button.id}
          onClick={() => {
            // This button is used to open Marketo-based PDFs in new tabs:
            window.open(button.url, "_blank", "noopener,noreferrer");
          }}
        >
          <Text as={"div"} color={"inherit"} size={"small"} weight={"medium"}>
            {button.label}
          </Text>
          {button?.icon && (
            <Icon
              color={"inherit"}
              size={"12px"}
              source={button.icon}
              yPos={"1px"}
            />
          )}
        </Button>
      ) : null}

      {link ? (
        <Anchor href={link.url}>
          <Text
            as={"span"}
            color={"inherit"}
            size={"small"}
            weight={"semibold"}
          >
            {link.label}
          </Text>
        </Anchor>
      ) : null}
    </nav>
  );
};

export default Subnav;
