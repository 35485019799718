import { mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

import Toggles from "./Toggles";
import useReel from "./use-reel";

interface Props {
  children: ReactNode;
  gap?: number;
  itemWidth?: number;
}

const Reel: FunctionComponent<Props> = ({
  children,
  gap = 16,
  itemWidth = 320,
}) => {
  const {
    handleToggleLeft,
    handleToggleRight,
    handleScroll,
    reelRef,
    showToggle,
  } = useReel(itemWidth);

  const ReelStyle = css`
    grid-area: 1/1/2/2;
    overflow-x: auto;
    position: relative;
    scroll-snap-type: x mandatory;
    width: 100%;
    @media (max-width: ${mediaTabletLandscape}) {
      padding-left: 0;
      scroll-snap-type: none;
    }
  `;

  const List = css`
    display: inline-flex;
    flex-wrap: no-wrap;
    gap: ${gap}px;
    margin: 0;
    padding-left: 0;
    padding-right: 20%;
  `;

  return (
    <>
      <div css={ReelStyle} onScroll={handleScroll} ref={reelRef}>
        <ul css={List}>{children}</ul>
      </div>
      <Toggles
        actionLeft={handleToggleLeft}
        actionRight={handleToggleRight}
        showToggle={showToggle}
      />
    </>
  );
};

export default Reel;
