import { css } from "@emotion/react";
import Grid from "../../Grid";
import Text from "../../Text";
import WistiaVideo from "../../WistiaVideo";
import { borderRadiusMedium, borderStandard } from "@10xdev/design-tokens";

interface Props {
  title: string;
  wistiaID: string;
}

const Video = ({ title, wistiaID }: Props) => {
  return (
    <Grid
      css={css`
        align-content: start;
        border-radius: ${borderRadiusMedium};
        border: ${borderStandard};
        box-sizing: border-box;
        flex: 0 0 390px;
        max-width: 390px;
        padding: 1.5rem;
      `}
      gap={"1rem"}
    >
      <WistiaVideo wistiaID={wistiaID} />
      <Text as={"h5"} size={"medium"} weight={"semibold"}>
        {title}
      </Text>
    </Grid>
  );
};

export default Video;
