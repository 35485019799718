import {
  borderStandard,
  colorSteelMedium,
  layoutWidth1200,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../../Heading";
import Text from "../../Text";
import SoftwareOverview from "./SoftwareOverview";
import type { Software } from "./types";

interface Props {
  overviewList?: Software[];
  padding?: string;
  subtitle?: string;
  title?: string;
}

const Overview: FunctionComponent<Props> = ({
  overviewList,
  padding = "4rem 1rem",
  title,
  subtitle,
}) => {
  return (
    <div
      className={"Overview"}
      css={css`
        display: grid;
        padding: ${padding};
        width: 100%;

        @media (min-width: ${mediaTabletLandscape}) {
          grid-template-columns: repeat(10, minmax(60px, 120px));
          max-width: ${layoutWidth1200};
        }
      `}
    >
      <div
        css={css`
          margin-bottom: 3rem;

          @media (min-width: ${mediaTabletLandscape}) {
            grid-column-end: 4;
            grid-column-start: 1;
            padding-right: 3rem;
          }
        `}
      >
        <Heading
          as={"h3"}
          css={css`
            margin-bottom: 1rem;
          `}
          size={"xlarge"}
        >
          {title}
        </Heading>
        <Text
          as={"p"}
          color={"inherit"}
          css={css`
            color: ${colorSteelMedium};
          `}
          size={"medium"}
          weight={"semibold"}
        >
          {subtitle}
        </Text>
      </div>

      <div
        css={css`
          display: grid;
          row-gap: 2rem;

          @media (min-width: ${mediaTabletLandscape}) {
            border-left: ${borderStandard};
            grid-column-end: 11;
            grid-column-start: 4;
            padding-left: 14.3%;
          }
        `}
      >
        {overviewList?.map((overview) => {
          return <SoftwareOverview {...overview} key={overview.title} />;
        })}
      </div>
    </div>
  );
};

export default Overview;
