import { css } from "@emotion/react";
import Anchor from "../../Anchor";
import Card from "../../Card";
import Flex from "../../Flex";
import Text from "../../Text";
import ThematicBreak from "../../ThematicBreak";
import pluralize from "pluralize";
import { ProductCardDetails } from "./types";
import { useEffect, useState } from "react";
import ItemRow from "./ItemRow";
import { VersionSelector } from "./VersionSelector";

interface ProductCardProps {
  hideName?: boolean;
  isSearched: boolean;
  product: ProductCardDetails;
  showCompatibleProducts: boolean;
  handleSelectProduct: (
    productName: string | null,
    version?: string,
    replaceUrl?: boolean,
  ) => Promise<void>;
}

const ProductCard = ({
  isSearched,
  product,
  showCompatibleProducts,
}: ProductCardProps) => {
  const { type, versions } = product;
  const [selectedVersion, setSelectedVersion] = useState(versions[0]);
  const hasMultipleVersions = versions.length > 1;
  const compatibleProductType = type === "instrument" ? "Assay" : "Instrument";

  // Handles updating the version when the version is changed through the selector
  const handleSelectVersion = async (versionName: string) => {
    const version = versions.find(({ name }) => name === versionName);
    if (!version) {
      return;
    }

    setSelectedVersion(version);
  };

  // Handles updating the version when the filters change. If the filters
  // change and the current selected version still has matches, update the
  // selected version with the new version. If the current version does not
  // have any matches, fallback to the first version object in the list.
  useEffect(() => {
    const matchedVersion = versions.find(
      ({ name }) => name === selectedVersion.name,
    );
    if (matchedVersion) {
      setSelectedVersion(matchedVersion);
    } else {
      setSelectedVersion(versions[0]);
    }
  }, [versions, selectedVersion.name]);

  return (
    <Card
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      `}
    >
      <div>
        {type === "assay" ? (
          <Flex alignItems="end" gap="0.5rem">
            <Text as="p" color="steelDarkest" size="medium" weight="semibold">
              Consumables
            </Text>
            {hasMultipleVersions ? (
              <Text as="p" color="steelDarker" size="small" weight="regular">
                Choose a version
              </Text>
            ) : null}
          </Flex>
        ) : null}
        {type === "instrument" && hasMultipleVersions ? (
          <Flex alignItems="end" gap="0.5rem">
            <Text as="p" color="steelDarkest" size="medium" weight="semibold">
              Instrument Variants
            </Text>
            <Text as="p" color="steelDarker" size="small" weight="regular">
              Choose a variant
            </Text>
          </Flex>
        ) : null}
        {hasMultipleVersions ? (
          <>
            <VersionSelector
              isSearched={isSearched}
              selectedVersion={selectedVersion}
              onSelectVersion={handleSelectVersion}
              versions={versions}
            />
            <ThematicBreak
              css={css`
                margin: 12px auto 4px;
              `}
            />
          </>
        ) : null}

        <Flex flexDirection={"column"} gap={".5rem"} padding={".25rem 0 0"}>
          {selectedVersion.subgroups.map(({ name, details }) => {
            return (
              <Flex
                key={name}
                flexDirection="column"
                justifyContent="center"
                gap=".5rem"
              >
                <div
                  css={css`
                    padding: 0.25rem 0;
                  `}
                >
                  <Text
                    as="p"
                    color="steelDarker"
                    size="xsmall"
                    weight="semibold"
                  >
                    {name}
                  </Text>
                </div>
                <Flex
                  flexDirection="column"
                  gap="0.25rem"
                  justifyContent="start"
                >
                  {details.map((detail) => {
                    return <ItemRow key={detail.id} productDetail={detail} />;
                  })}
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </div>
      {showCompatibleProducts &&
      selectedVersion.compatibleProducts.length > 0 ? (
        <div>
          <Text as="p" color="steelDarkest" size="medium" weight="semibold">
            {`Compatible ${pluralize(
              compatibleProductType,
              selectedVersion.compatibleProducts.length,
            )}`}
          </Text>
          <ThematicBreak
            css={css`
              margin: 8px auto;
            `}
          />
          <Flex alignItems="start" flexDirection="column" gap="0.25rem">
            {selectedVersion.compatibleProducts.map(({ display, url }) => {
              return (
                <Anchor key={display} href={url}>
                  <Text as={"span"} color={"inherit"} size={"small"}>
                    {display}
                  </Text>
                </Anchor>
              );
            })}
          </Flex>
        </div>
      ) : null}
    </Card>
  );
};

export default ProductCard;
