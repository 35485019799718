import {
  borderRadiusMedium,
  colorBlueDark,
  colorBlueLightest,
  colorBlueMedium,
  layoutWidth1200,
  mediaTabletLandscape,
  sizeLarge,
  sizeMedium,
  sizeXlarge,
  sizeXxlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Image from "../../Image";
import Text from "../../Text";

interface Props {
  items: Array<{
    description?: string;
    image: string;
    links: Array<{
      description?: string;
      label: string;
      url?: string;
    }>;
    title?: string;
  }>;
  title?: string;
}

const Solution: FunctionComponent<Props> = ({ items, title }) => {
  return (
    <div
      css={css`
        max-width: ${layoutWidth1200};
        padding: 4rem 1rem;

        ::before {
          border-top: 1px solid ${colorBlueMedium};
          content: "";
          position: absolute;
          transform: translate(-48px, 16px) rotate(120deg);
          transform-origin: 0% 0%;
          width: 300px;
        }

        @media (max-width: ${mediaTabletLandscape}) {
          max-width: 100%;
        }
      `}
    >
      {title ? (
        <Heading
          as={"h3"}
          css={css`
            margin-bottom: ${sizeXxlarge};
          `}
          responsive={true}
          size={"xxxlarge"}
        >
          {title}
        </Heading>
      ) : null}
      <div
        css={css`
          display: flex;

          @media (max-width: ${mediaTabletLandscape}) {
            -webkit-overflow-scrolling: touch;
            overflow-x: auto;
            overflow-y: hidden;
          }
        `}
      >
        {items && items.length
          ? items.slice(0, 4).map((item) => {
              const { description, image, links, title } = item;
              return (
                <div
                  css={css`
                    background: ${colorBlueLightest};
                    border-radius: ${borderRadiusMedium};
                    box-sizing: border-box;
                    flex: 1 1 280px;
                    justify-content: space-between;
                    padding: ${sizeMedium} ${sizeLarge} ${sizeLarge}
                      ${sizeXlarge};
                    margin-right: ${sizeMedium};
                    max-width: 352px;

                    @media (max-width: ${mediaTabletLandscape}) {
                      flex: 1 0 280px;
                    }
                  `}
                  key={title}
                >
                  <div
                    css={css`
                      display: flex;
                      height: 200px;
                      justify-content: center;
                      margin-bottom: ${sizeMedium};
                    `}
                  >
                    <Image
                      alt={""}
                      css={css`
                        align-self: center;
                      `}
                      loading={"lazy"}
                      src={image}
                      title={title}
                    />
                  </div>

                  <Heading
                    as={"h4"}
                    css={css`
                      margin-bottom: ${sizeLarge};
                    `}
                    size={"medium"}
                  >
                    {title}
                  </Heading>

                  {description ? (
                    <Text
                      as={"p"}
                      color={"midgray"}
                      css={css`
                        margin-bottom: ${links ? "1rem" : 0};
                      `}
                      size={"xsmall"}
                    >
                      {description}
                    </Text>
                  ) : null}

                  {links
                    ? links.map((link) => {
                        const { description, label, url } = link;
                        return (
                          <div
                            css={css`
                              margin-bottom: ${description
                                ? sizeLarge
                                : sizeMedium};
                            `}
                            key={label}
                          >
                            <Text
                              as={"div"}
                              color={url ? "blue" : "base"}
                              css={css`
                                margin-bottom: 0.25rem;
                              `}
                              size={"small"}
                              weight={"medium"}
                            >
                              {url ? (
                                <Anchor
                                  color={"inherit"}
                                  hoverColor={colorBlueDark}
                                  href={url}
                                >
                                  {label}
                                  <Icon
                                    size={"12px"}
                                    source={"nav-right"}
                                    xPos={"16px"}
                                    yPos={"1px"}
                                  />
                                </Anchor>
                              ) : (
                                label
                              )}
                            </Text>
                            <Text as={"p"} color={"midgray"} size={"xsmall"}>
                              {description}
                            </Text>
                          </div>
                        );
                      })
                    : null}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default Solution;
