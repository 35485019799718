import { css } from "@emotion/react";
import { Link as LinkInterface } from "../types";
import type { Text as TextInterface } from "../types";

import Grid from "../../../Grid";
import Text from "../../../Text";
import Link from "../Link";
import Image from "../../../Image";
import { borderStandard, borderRadiusMedium } from "@10xdev/design-tokens";

export interface Props {
  image?: {
    alt: string;
    src: string;
  };
  actions?: {
    title?: TextInterface;
    summary?: TextInterface;
    link?: LinkInterface;
  }[];
}

const CTA = ({ image, actions }: Props) => {
  return (
    <Grid
      css={css`
        background: linear-gradient(180deg, #ebf4fc 0.46%, #fff 116.08%);
        border-radius: ${borderRadiusMedium};
      `}
      alignItems={"center"}
      gap={"2rem 3.5rem"}
      padding={"1.5rem 2.5rem"}
      gridTemplateColumns={{ base: "1fr", tabletLandscape: "300px 1fr" }}
    >
      {image && <Image alt={image.alt} src={image.src} />}
      <Grid>
        {actions &&
          actions.map((action) => {
            const { title, summary, link } = action;
            return (
              <Grid
                css={css`
                  :not(:last-of-type) {
                    padding-bottom: 1rem;
                    border-bottom: ${borderStandard};
                  }
                  :not(:first-of-type) {
                    padding-top: 1rem;
                  }
                `}
                alignContent={"start"}
                gap={"1rem"}
              >
                <Grid gap={".5rem"}>
                  {title && (
                    <Text as={"h4"} size={"medium"} weight={"semibold"}>
                      {title.content}
                    </Text>
                  )}
                  {summary && (
                    <Text as={"h4"} size={"small"}>
                      {summary.content}
                    </Text>
                  )}
                </Grid>
                {link && <Link {...link} />}
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};

export default CTA;
