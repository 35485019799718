import { css, keyframes } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";

import MarketoForm from "../MarketoForm";
import type { MarketoFormResponse } from "../MarketoForm/types";
import type { FormStep } from "../ModalForm";
import Text from "../Text";

interface Props {
  passedValues?: any;
  steps: Array<FormStep>;
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const SignupForm: FunctionComponent<Props> = ({ passedValues, steps }) => {
  const [stepNumber, setStepNumber] = useState(0);
  const [form, setForm] = useState<MarketoFormResponse | null>(null);

  const step = steps?.[stepNumber];

  const handleFormSubmitSuccess = () => {
    setForm(null);

    const nextStep = stepNumber + 1;
    if (nextStep < steps.length) {
      setStepNumber(nextStep);
    }
  };

  useEffect(() => {
    const loadFormConfig = async () => {
      if (step.marketoFormID) {
        try {
          const response = await fetch(`/api/form/${step.marketoFormID}`);
          const json = await response.json();
          setForm(json);
        } catch (error) {
          console.warn(error);
          setForm(null);
        }
      }
    };
    loadFormConfig();
  }, [stepNumber, step.marketoFormID]);

  return (
    <div className={"Form"}>
      {step?.title || step?.description ? (
        <div
          css={css`
            animation: ${fadeIn} 1s;
          `}
        >
          <Text
            as={"div"}
            css={css`
              margin-bottom: 1rem;
            `}
            size={"xxlarge"}
            weight={"semibold"}
          >
            {step.title}
          </Text>
          <Text
            as={"p"}
            color={"midgray"}
            css={css`
              margin-bottom: 1.5rem;
            `}
            size={"medium"}
          >
            {step.description}
          </Text>
        </div>
      ) : null}

      {form ? (
        <MarketoForm
          form={form}
          onSuccess={handleFormSubmitSuccess}
          passedValues={passedValues}
          submitID={"certifiedServiceProvider"}
        />
      ) : null}
    </div>
  );
};

export default SignupForm;
