import { layoutWidth878, mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Text from "../../Text";

interface Props {
  metrics: Array<{
    label: string;
    value: string;
  }>;
}

const Metrics: FunctionComponent<Props> = ({ metrics }) => {
  return (
    <ul
      className={"Metrics"}
      css={css`
        display: flex;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        max-width: ${layoutWidth878};
        padding: 48px 24px;
        width: 100%;

        @media (max-width: ${mediaTabletLandscape}) {
          flex-direction: column;
          padding: 72px 24px;
        }
      `}
    >
      {metrics.map((item) => {
        const { label, value } = item;

        return (
          <li
            css={css`
              max-width: 280px;
              text-align: center;

              @media (max-width: ${mediaTabletLandscape}) {
                margin-bottom: 56px;
                max-width: 100%;
                text-align: left;
              }
            `}
            key={`${value} ${label}`}
          >
            <Text
              as={"div"}
              css={css`
                margin-bottom: 24px;
              `}
              responsive={true}
              size={"xxxlarge"}
              weight={"semibold"}
            >
              {value}
            </Text>
            <Text as={"div"} size={"medium"}>
              {label}
            </Text>
          </li>
        );
      })}
    </ul>
  );
};

export default Metrics;
