import { borderStandard, sizeLarge } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { MDXRemoteSerializeResult } from "next-mdx-remote";
import { MDXRemote } from "next-mdx-remote";

import Accordion from "../../Accordion/Accordion";
import Anchor from "../../Anchor";
import MDXComponents from "../../Blocks/MDX/MDXComponents";
import Text from "../../Text";

export interface FaqItem {
  answer: string;
  link?: {
    label: string;
    url: string;
  };
  mdx: MDXRemoteSerializeResult;
  question: string;
}

export function FaqAccordion({ item }: { item: FaqItem }) {
  const { link, mdx, question } = item;

  return (
    <Accordion
      css={css`
        &.active {
          padding-bottom: 1rem;
        }
      `}
      headingAttrs={{ title: question }}
      styles={{
        contents: css`
          padding-left: 1rem;
          width: 90%;
        `,
        heading: css`
          border-top: ${borderStandard};
          padding: 1.5rem 1rem;
        `,
      }}
    >
      <Text as={"div"} color={"midgray"} responsive={true} size={"medium"}>
        <MDXRemote {...mdx} components={MDXComponents} />
      </Text>

      {link && link.url.length > 0 ? (
        <Anchor href={link.url} target={"_blank"}>
          <Text
            as={"span"}
            color={"inherit"}
            css={css`
              margin-bottom: ${sizeLarge};
            `}
            responsive={true}
            size={"small"}
            weight={"semibold"}
          >
            {link.label}
          </Text>
        </Anchor>
      ) : null}
    </Accordion>
  );
}
