import {
  layoutWidth750,
  layoutWidth1106,
  layoutWidth1200,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../../Heading";
import Iframe from "../../Iframe";
import Text from "../../Text";

interface Props {
  chart: {
    description: string;
    title: string;
  };
  controls: {
    description: string;
    title: string;
  };
  url: string;
}

const SpatialVisualization: FunctionComponent<Props> = ({
  chart,
  controls,
  url,
}) => {
  return (
    <div
      className={"SpatialVisualization"}
      css={css`
        margin: 4rem 1rem;
        max-width: ${layoutWidth1200};
        width: 100%;

        @media (min-width: ${layoutWidth750}) {
          display: flex;
        }
      `}
    >
      <div
        css={css`
          box-sizing: border-box;
          margin-bottom: 2rem;
          width: 100%;

          @media (min-width: ${layoutWidth750}) {
            box-sizing: border-box;
            margin-bottom: 0;
            padding-right: 5%;
            width: 30%;
          }

          @media (min-width: ${layoutWidth750}) {
            box-sizing: border-box;
            height: 392px;
            padding-top: 1rem;

            /* Keeps the labels in line with visualization rows */
            :first-of-type {
              height: 768px;
            }
          }
        `}
      >
        <div
          css={css`
            :first-of-type {
              margin-bottom: 2rem;
            }

            @media (min-width: ${layoutWidth1106}) {
              :first-of-type {
                height: 392px;
              }
            }
          `}
        >
          <Heading
            as={"h4"}
            css={css`
              margin-bottom: 1rem;
            `}
            size={"large"}
          >
            {controls.title}
          </Heading>
          <Text as={"p"} color={"midgray"} size={"medium"}>
            {controls.description}
          </Text>
        </div>

        <div>
          <Heading
            as={"h4"}
            css={css`
              margin-bottom: 1rem;
            `}
            size={"large"}
          >
            {chart.title}
          </Heading>
          <Text as={"p"} color={"midgray"} size={"medium"}>
            {chart.description}
          </Text>
        </div>
      </div>

      <div
        css={css`
          @media (min-width: ${layoutWidth750}) {
            /* Keeps shadow on sides while as wide as possible */
            width: calc(70% - 2px);
          }

          /* Keeps the labels in line with visualization rows */
          @media (min-width: ${layoutWidth1106}) {
            height: 800px;
          }
        `}
      >
        <Iframe
          height={"1320px"}
          source={url}
          title={"Interactive Spatial Visualization "}
          width={"100%"}
        />
      </div>
    </div>
  );
};

export default SpatialVisualization;
