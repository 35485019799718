import { css } from "@emotion/react";
import type { CategoryProps } from "./Specifications";

import Grid from "../../../Grid";
import Text from "../../../Text";
import List from "./List";
import Link from "../../../ProductPage/Link";
import { borderStandard } from "@10xdev/design-tokens";
import type { Link as LinkType } from "./Specifications";

interface Props {
  categories: CategoryProps[];
  title: string;
  link?: LinkType;
}

const Categories = ({ categories, title, link }: Props) => {
  return (
    <Grid
      css={css`
        padding: 0.75rem 0;
        border-bottom: ${borderStandard};
      `}
      gap={".75rem"}
    >
      <div>
        <Text as={"h4"} size={"large"} weight={"semibold"}>
          {title}
        </Text>
        {link ? (
          <Link url={link.url} label={link.label} icon={link.icon} />
        ) : null}
      </div>
      <Grid gap={"2.5rem"}>
        {categories.map((category) => {
          const { list, title } = category;
          return <List list={list} title={title} variant={"category"} />;
        })}
      </Grid>
    </Grid>
  );
};

export default Categories;
