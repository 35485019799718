import { mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { Fragment, useState } from "react";

import Text from "../../Text/Text";
import Tab from "../../XeniumDatasetExplorer/TabsWithPills/Tab";
import Carousel from "./Carousel";
import { createCarousel } from "./RenderService";

export type Tab = {
  id: string;
  label: string;
};

interface Props {
  aspectRatio: number;
  filterTabs?: Tab[];
  gap: number;
  itemHeight: number;
  items: any[];
  itemsPerScroll: number;
  padding?: string;
  title?: string;
}

const FilterCarousel = ({
  aspectRatio,
  filterTabs,
  gap,
  itemHeight,
  items,
  itemsPerScroll,
  padding = "0",
  title,
}: Props) => {
  const [activeTab, setActiveTab] = useState<Tab>({ id: "all", label: "All" });

  return (
    <div
      css={css`
        display: grid;
        gap: 1.5rem;
        width: 100%;
        padding: ${padding};
        @media (min-width: ${mediaTabletLandscape}) {
          padding-left: calc((100vw - 1200px) / 2);
        }
      `}
    >
      {title ? (
        <Text as={"h3"} size={"large"} weight={"semibold"}>
          {title}
        </Text>
      ) : null}

      {filterTabs && filterTabs.length ? (
        <div
          css={css`
            display: none;
            @media (min-width: ${mediaTabletLandscape}) {
              display: flex;
              gap: 0.5rem;
            }
          `}
        >
          {filterTabs.map((tab) => {
            const active = activeTab.id === tab.id;
            return (
              <Tab
                active={active}
                key={tab.id}
                mode={"light"}
                onClick={() => setActiveTab(tab)}
              >
                <Text
                  as={"span"}
                  color={"inherit"}
                  size={"small"}
                  weight={"medium"}
                >
                  {`${tab.label} (${
                    items.filter((item) => item.data.filters?.includes(tab.id))
                      .length || items.length
                  })`}
                </Text>
              </Tab>
            );
          })}
        </div>
      ) : null}

      <Carousel
        active={activeTab.id}
        aspectRatio={aspectRatio}
        gap={gap}
        itemHeight={itemHeight}
        itemsPerScroll={itemsPerScroll}
      >
        {items
          .filter(
            (item) =>
              item.data.filters?.includes(activeTab.id) ||
              activeTab.id === "all",
          )
          .map((item, index) => {
            return (
              <Fragment key={`${item.type}-${index}`}>
                {createCarousel(item)}
              </Fragment>
            );
          })}
      </Carousel>
    </div>
  );
};

export default FilterCarousel;
