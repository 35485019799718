import { css } from "@emotion/react";

import FlexItem from "../../Flex/FlexItem";
import Text from "../../Text";
import { ProductDetails } from "./types";
import Pill from "./Pill";
import { mediaTabletPortrait } from "@10xdev/design-tokens";

interface ItemRowProps {
  productDetail: ProductDetails;
}

export const TypeNames: Record<string, string> = {
  RGT: "Reagent",
  CHIP: "Chip",
  ACC: "Accessory",
  INST: "Instrument",
  TRNG: "Training",
  UPG: "Upgrade",
  SVC: "Service",
  INSTALL: "Install",
  POC: "POC",
};

export const TypeColors: Record<string, string> = {
  ACC: "#BFEAE7",
  CHIP: "#D4CFE6",
  INST: "#FEE9B9",
  INSTALL: "#BFEBF8",
  RGT: "#CCE3F7",
  SVC: "#D8EBC6",
  TRNG: "#E7C3D5",
  UPG: "#FABFCE",
};

const ItemRow = ({ productDetail }: ItemRowProps) => {
  const { id, name, type = "" } = productDetail;
  const typeColor = TypeColors[type];
  const typeName = TypeNames[type];

  if (!id) {
    return null;
  }

  return (
    <div
      css={css`
        flex-wrap: wrap;
        display: flex;
        gap: 0.75rem;
        height: fit-content;
        min-height: 30px;
        @media (min-width: ${mediaTabletPortrait}) {
          align-items: center;
        }
      `}
    >
      <FlexItem flex={"0 0 90px"}>
        <Pill
          css={css`
            margin-top: 2px;
          `}
          color={typeColor}
          name={typeName}
        />
      </FlexItem>
      <FlexItem flex={"0 0 60px"}>
        <Text as="p" color="steelDarkest" size="xsmall" weight="regular">
          {id}
        </Text>
      </FlexItem>
      <Text
        as="p"
        color="steelDarkest"
        css={css`
          flex: 1 1 0;
        `}
        size="xsmall"
        weight="regular"
      >
        {name}
      </Text>
    </div>
  );
};

export default ItemRow;
