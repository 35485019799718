import { css } from "@emotion/react";
import { ReactNode } from "react";

import Anchor from "../Anchor";
import type { ButtonType } from "../Blocks/Masthead/types";
import {
  getPrimaryButtonCss,
  getSecondaryButtonCss,
  getTertiaryButtonCss,
} from "./styles";

interface Props {
  children: ReactNode;
  href: string;
  id?: string;
  type?: ButtonType;
}

const Button = ({ children, href = "/", id, type = "primary" }: Props) => {
  const getButtonStyles = (type: ButtonType) => {
    const styles = {
      ["primary"]: getPrimaryButtonCss(),
      ["secondary"]: getSecondaryButtonCss(),
      ["tertiary"]: getTertiaryButtonCss(),
    };
    return styles[type];
  };
  return (
    <Anchor
      css={css`
        ${getButtonStyles(type)}
      `}
      href={href}
      id={id}
      target={"_self"}
    >
      {children}
    </Anchor>
  );
};

export default Button;
