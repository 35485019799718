import { css } from "@emotion/react";
import { IconSource } from "../../Icon/types";
import type { Props as Header } from "../../ProductPage/SectionHeader";

import Resource from "../../ProductPage/cards/resource";
import SectionHeader from "../../ProductPage/SectionHeader";
import Grid from "../../Grid";
import Repeater from "../../ProductPage/Repeater";

interface Props {
  header?: Header;
  resources: {
    title?: string;
    links: {
      label?: string;
      icon?: IconSource;
      url?: string;
    }[];
    cta: {
      label?: string;
      icon?: IconSource;
      url?: string;
    };
  }[];
}

const ProductPageResources = ({ header, resources }: Props) => {
  return (
    <Grid
      css={css`
        max-width: 1200px;
        width: 100%;
      `}
      gap={"3.5rem"}
      padding={{ base: "2rem 1rem", tabletPortrait: "4.5rem 1rem" }}
    >
      {header ? <SectionHeader {...header} /> : null}
      {resources.length ? <Repeater card={Resource} list={resources} /> : null}
    </Grid>
  );
};

export default ProductPageResources;
