import { layoutWidth1200 } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Anchor from "../../Anchor";
import Grid from "../../Grid";
import Icon from "../../Icon";
import type { IconSource } from "../../Icon/types";
import Image from "../../Image";
import Text from "../../Text";

type Advantage = {
  description?: string;
  title?: string;
};

interface Props {
  advantageImage?: {
    alt: string;
    src: string;
  };
  advantageLinks?: {
    icon?: IconSource;
    label: string;
    url: string;
  }[];
  advantages?: Advantage[];
  description: string;
  eyebrow?: string;
  title: string;
}

const Advantage = ({
  advantages,
  description,
  eyebrow,
  advantageImage,
  advantageLinks,
  title,
}: Props) => {
  return (
    <Grid
      css={css`
        max-width: ${layoutWidth1200};
        padding: 4rem 1rem;
      `}
      gap={"2.5rem"}
    >
      <Grid
        css={css`
          padding-bottom: 5rem;
        `}
        gap={"3rem"}
        gridTemplateColumns={"repeat(auto-fit, minmax(320px, 1fr))"}
      >
        <Grid gap={"1rem"}>
          <Text as={"p"} color={"blue"} size={"medium"} weight={"semibold"}>
            {eyebrow}
          </Text>
          <Text as={"h3"} size={"xxlarge"} weight={"semibold"}>
            {title}
          </Text>
          <Text as={"p"} size={"medium"}>
            {description}
          </Text>
          {advantageLinks?.map((link) => {
            return (
              <Anchor
                css={css`
                  align-items: center;
                  display: flex;
                  gap: 0.75rem;
                `}
                href={link.url}
                key={link.url}
              >
                <Text
                  as={"div"}
                  color={"inherit"}
                  responsive={true}
                  size={"medium"}
                  weight={"semibold"}
                >
                  {link.label}
                </Text>
                {link.icon ? (
                  <Icon
                    color={"inherit"}
                    size={"12px"}
                    source={link.icon}
                    yPos={"1px"}
                  />
                ) : null}
              </Anchor>
            );
          })}
        </Grid>
        {advantageImage ? (
          <Image
            alt={advantageImage.alt}
            css={css`
              align-self: end;
            `}
            src={advantageImage.src}
          />
        ) : null}
      </Grid>
      <Grid
        gap={"1.5rem"}
        gridTemplateColumns={"repeat(auto-fit, minmax(200px, 1fr))"}
      >
        {advantages?.map((advantage) => {
          return (
            <Grid alignContent={"start"} gap={"1rem"} key={advantage.title}>
              <Text as={"h4"} size={"xlarge"} weight={"semibold"}>
                {advantage.title}
              </Text>
              <Text as={"p"} size={"medium"}>
                {advantage.description}
              </Text>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Advantage;
