import BulletList from "./Content/BulletList";
import Illustration from "./Content/Illustration";
import type { Query } from "./types";

export const getContent = (item: Record<string, any>, activeTab: Query) => {
  switch (item.type) {
    case "bulletList":
      return (
        <BulletList
          activeTab={activeTab}
          button={item.button}
          description={item.description}
          link={item.link}
          list={item.list}
          title={item.title}
        />
      );
    case "illustration":
      return (
        <Illustration
          activeTab={activeTab}
          button={item.button}
          description={item.description}
          image={item.image}
          link={item.link}
          list={item.list}
          secondaryLink={item.secondaryLink}
          title={item.title}
        />
      );
    default:
      return null;
  }
};
