import {
  borderRadiusMedium,
  borderStandard,
  colorBlueDark,
  colorBlueLight,
  colorBlueLighter,
  colorBlueMedium,
  colorSteelMedium,
  colorWhite,
  fontFamilyBase,
  sizeSmall,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

export const getBaseButtonCss = () => {
  return css`
    align-items: center;
    background: none;
    border-radius: ${borderRadiusMedium};
    border: none;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    display: flex;
    font-family: ${fontFamilyBase};
    gap: ${sizeSmall};
    justify-content: center;
    max-width: 24rem;
    overflow: hidden;
    padding: 8px 1.5rem 9px;
    text-decoration: none;
    white-space: nowrap;
    transition: border 0.225s ease-out, color 0.225s ease-out,
      background 0.225s ease-out;
  `;
};

const disabledBaseCss = css`
  cursor: default;
  pointer-events: none;
`;

export const getPrimaryButtonCss = () => {
  return css`
    ${getBaseButtonCss()}
    background: ${colorBlueMedium};
    color: ${colorWhite};
    &:hover {
      background: ${colorBlueDark};
      border: none;
      color: ${colorWhite};
    }
    &:disabled {
      ${disabledBaseCss}
      background: ${colorBlueLight};
      color: ${colorWhite};
    }
  `;
};

export const getSecondaryButtonCss = () => {
  return css`
    ${getBaseButtonCss()}
    background: ${colorWhite};
    border: ${borderStandard};
    color: ${colorBlueMedium};
    &:hover {
      background: ${colorBlueLighter};
      border: solid 1px ${colorBlueLight};
      color: ${colorBlueMedium};
    }
    &:disabled {
      ${disabledBaseCss}
      color: ${colorBlueLight};
    }
  `;
};

export const getTertiaryButtonCss = () => {
  return css`
    ${getBaseButtonCss()}
    background: transparent;
    border: solid 1px;
    color: ${colorWhite};
    &:hover {
      background: rgba(255, 255, 255, 0.15);
      border: solid 1px;
    }
    &:disabled {
      ${disabledBaseCss}
      $color: ${colorSteelMedium}
    }
  `;
};
