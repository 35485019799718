import {
  borderRadiusLarge,
  borderStandard,
  boxShadowLight,
  colorGrayLightest,
  colorSteelLightest,
  colorWhite,
  layoutWidth1200,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { useState } from "react";
import Cookies from "universal-cookie";

import Anchor from "../Anchor";
import Icon from "../Icon";
import Text from "../Text";

const cookies = new Cookies();
const COOKIE_ATTRIBUTE = "announcementDismissed";

const Announcement = () => {
  const [isDismissed, setIsDismissed] = useState(cookies.get(COOKIE_ATTRIBUTE));

  const handleClick = () => {
    cookies.set(COOKIE_ATTRIBUTE, true);
    setIsDismissed(true);
  };

  return isDismissed ? null : (
    <section
      css={css`
        align-items: center;
        background-color: ${colorGrayLightest};
        border: ${borderStandard};
        border-radius: ${borderRadiusLarge};
        bottom: 1rem;
        box-shadow: ${boxShadowLight};
        box-sizing: border-box;
        display: flex;
        height: 56px;
        left: 50%;
        justify-content: space-between;
        max-width: ${layoutWidth1200};
        overflow: hidden;
        padding: 0 1rem 0 1.5rem;
        position: fixed;
        right: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        z-index: ${Number.MAX_SAFE_INTEGER};

        @media (max-width: ${mediaTabletLandscape}) {
          display: none;
        }
      `}
    >
      <div
        css={css`
          align-items: center;
          display: flex;
          gap: 1rem;
          overflow: hidden;
          padding-right: 1rem;
        `}
      >
        <Icon
          aria-label={"Headset icon"}
          color={"steelDark"}
          css={css`
            min-width: 1rem;
          `}
          size={"1.25rem"}
          source={"headset"}
        />
        <Text
          as={"h1"}
          css={css`
            overflow: hidden;
            text-overflow: ellipis;
            white-space: nowrap;
          `}
          size={"medium"}
        >
          {"技術的なサポートメールは日本語でも可能です"}
          {"（"}
          <Anchor href={"mailto:support@10xgenomics.com"}>
            {"support@10xgenomics.com"}
          </Anchor>
          {"）"}
        </Text>
      </div>

      <button
        aria-label={"Dismiss announcement banner"}
        css={css`
          align-items: center;
          background-color: ${colorWhite};
          border: ${borderStandard};
          border-radius: 100%;
          cursor: pointer;
          display: flex;
          height: 2rem;
          justify-content: center;
          margin: 0;
          min-height: 2rem;
          min-width: 2rem;
          padding: 0;
          width: 2rem;

          :hover {
            background-color: ${colorSteelLightest};
          }
        `}
        onClick={handleClick}
      >
        <Icon
          aria-label={"Close icon"}
          color={"base"}
          size={"0.75rem"}
          source={"close"}
        />
      </button>
    </section>
  );
};

export default Announcement;
