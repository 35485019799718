import {
  borderStandard,
  layoutWidth1200,
  mediaPhoneOnly,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../../Heading";
import Text from "../../Text";

interface Props {
  items?: {
    contents?: {
      description?: string;
      title?: string;
    }[];
    heading?: string;
  }[];
}

const HeadingList: FunctionComponent<Props> = ({ items }) => {
  return (
    <div
      className={"HeadingList"}
      css={css`
        max-width: ${layoutWidth1200};
        padding: 4rem 1rem;

        @media (max-width: ${mediaPhoneOnly}) {
          margin-bottom: 3rem;
          padding: 0 1rem;
        }
      `}
    >
      {items?.map((item) => {
        const { contents, heading } = item;
        return (
          <div
            css={css`
              border-top: ${borderStandard};
              box-sizing: border-box;
              display: flex;
              min-height: 12rem;
              padding-top: 3rem;
              padding-bottom: 3rem;

              @media (max-width: ${mediaPhoneOnly}) {
                display: block;
                padding-left: 1rem;
                padding-right: 1rem;
              }
            `}
            key={heading}
          >
            <div
              css={css`
                padding-right: 10%;
                width: 40%;

                @media (max-width: ${mediaPhoneOnly}) {
                  padding: 0;
                  width: 100%;
                }
              `}
            >
              <Heading as={"h4"} responsive={true} size={"xlarge"}>
                {heading}
              </Heading>
            </div>
            <div
              css={css`
                padding-right: 10%;
                padding-top: 0.5rem;
                width: 60%;

                @media (max-width: ${mediaPhoneOnly}) {
                  padding: 0;
                  width: 100%;
                }
              `}
            >
              {contents?.map((content) => {
                return (
                  <Text
                    as={"p"}
                    color={"midgray"}
                    css={css`
                      margin-bottom: 1rem;
                    `}
                    key={content.description}
                    responsive={true}
                    size={"medium"}
                  >
                    {content.title ? (
                      <Text
                        as={"span"}
                        color={"inherit"}
                        size={"medium"}
                        weight={"semibold"}
                      >
                        {`${content.title} - `}
                      </Text>
                    ) : null}
                    {content.description}
                  </Text>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HeadingList;
