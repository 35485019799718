import { mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../../Heading";
import Reel from "../../Reel";
import Text from "../../Text";
import type { Step } from "./types";
import WorkflowCard from "./WorkflowCard";

interface Props {
  description?: string;
  steps?: Step[];
  title?: string;
}

const WorkflowShelf: FunctionComponent<Props> = ({
  description,
  title,
  steps: items = [],
}) => {
  return (
    <div
      css={css`
        align-items: center;
        background: #fafafa;
        display: grid;
        grid-template-columns: 1fr minmax(300px, 360px) minmax(300px, 840px) 1fr;
        grid-template-rows: auto;
        height: 100%;
        padding: 4rem 0;
        width: 100%;

        @media (max-width: ${mediaTabletLandscape}) {
          display: flex;
          flex-direction: column;
        }
      `}
    >
      <div
        css={css`
          grid-area: 1/2/2/3;
          padding-left: 1rem;
          padding-right: 60px;

          @media (max-width: ${mediaTabletLandscape}) {
            margin-bottom: 2rem;
            padding: 0 1rem;
          }
        `}
      >
        <Heading as={"h3"} responsive={true} size={"xxxlarge"}>
          {title}
        </Heading>
        <Text as={"p"} color={"midgray"} responsive={true} size={"medium"}>
          {description}
        </Text>
      </div>
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: auto;
          grid-area: 1/3/2/5;

          @media (max-width: ${mediaTabletLandscape}) {
            padding-left: 1rem;
          }
        `}
      >
        <Reel itemWidth={450}>
          {items.map((item, index) => {
            const { callout, description, icon, title, resources } = item;
            return (
              <WorkflowCard
                callout={callout}
                description={description}
                icon={icon}
                index={index}
                key={item.title}
                resources={resources}
                title={title}
              />
            );
          })}
        </Reel>
      </div>
    </div>
  );
};

export default WorkflowShelf;
