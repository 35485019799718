import {
  borderLighter,
  borderRadiusMedium,
  colorBlueDark,
  colorBlueLighter,
  colorBlueMedium,
  colorGrayLightest,
  colorSteelLighter,
  colorWhite,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { kebabCase } from "lodash-es";
import type { FunctionComponent } from "react";
import { useCallback, useEffect, useRef, useState } from "react";

import { EASE } from "../../../constants";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Text from "../../Text";
import type { Query, SolutionType } from "./types";
import { getContent } from "./utils";

const Solution: FunctionComponent<SolutionType> = ({
  content = [],
  description,
  initial,
  title,
}) => {
  const [activePanel, setActivePanel] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<Query>("");

  const [height, setHeight] = useState("0px");
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleActivePanel = useCallback(() => {
    setActivePanel(!activePanel);
  }, [activePanel]);

  /* Depending on the situation this function will:
   * 1. If closed, open panel and display chosen content
   * 2. If open, switch to chosen content (like tabs)
   * 3. If open and same content chosen, close the panel
   */
  const toggleActiveTab = useCallback(
    (tab: Query) => {
      if (tab === activeTab) {
        toggleActivePanel();
      } else if (activePanel === false) {
        setActiveTab(tab);
        toggleActivePanel();
      } else {
        setActiveTab(tab);
      }
    },
    [activeTab, activePanel, toggleActivePanel],
  );

  // Opens product panel based on url query string (optional)
  useEffect(() => {
    content.forEach((item) => {
      const title = kebabCase(item.title);
      if (title === initial) {
        toggleActiveTab(title);
      }
    });
  }, [initial, content, toggleActiveTab]);

  const contentHeight = contentRef.current?.scrollHeight;

  useEffect(() => {
    setHeight(activePanel ? `${contentHeight}px` : "0px");
  }, [activePanel, contentHeight]);

  return (
    <div
      css={css`
        margin-bottom: ${activePanel ? "2rem" : 0};
        padding: 2rem;
        transition: all 0.25s ${EASE};

        @media (min-width: ${mediaTabletLandscape}) {
          padding: 0 0 2rem;
        }
      `}
      id={kebabCase(title)}
    >
      <div
        css={css`
          outline: none;
          padding: 0;
          text-align: left;
          transition: margin-bottom 0.25s ${EASE};
        `}
      >
        <div
          css={css`
            @media (min-width: ${mediaTabletLandscape}) {
              width: 60%;
            }
          `}
        >
          <Heading
            as={"h4"}
            css={css`
              margin-bottom: 0.5rem;
            `}
            size={"xlarge"}
          >
            {title}
          </Heading>
          <Text
            as={"p"}
            color={"midgray"}
            css={css`
              margin-bottom: 1.5rem;
            `}
            size={"medium"}
          >
            {description}
          </Text>
        </div>

        {content?.length ? (
          <>
            {content?.map((item) => {
              const title = kebabCase(item.title);
              const active = activePanel && activeTab === title;
              return (
                <button
                  css={css`
                    background: ${active ? colorBlueMedium : colorWhite};
                    border: solid 1px
                      ${active ? colorBlueMedium : colorSteelLighter};
                    border-radius: 3rem;
                    color: ${active ? colorWhite : colorBlueMedium};
                    cursor: pointer;
                    margin: 0 0.5rem 1.5rem 0;
                    padding: 0.5rem 1.5rem;
                    transition: all 0.1s ${EASE};

                    :hover {
                      background: ${active ? colorBlueDark : colorBlueLighter};
                    }
                  `}
                  key={item.title}
                  onClick={() => toggleActiveTab(title)}
                >
                  <Text
                    as={"span"}
                    color={"inherit"}
                    size={"small"}
                    weight={"medium"}
                  >
                    {item.title}
                  </Text>
                </button>
              );
            })}
          </>
        ) : null}
      </div>

      <div
        css={css`
          background: ${colorGrayLightest};
          border: ${borderLighter};
          border-radius: ${borderRadiusMedium};
          height: ${height};
          max-width: 90%;
          opacity: ${activePanel ? 1 : 0};
          overflow: hidden;
          position: relative;
          transition: all 0.3s ${EASE};
        `}
        ref={contentRef}
      >
        <button
          aria-expanded={activePanel}
          css={css`
            align-items: center;
            background: ${colorWhite};
            border: solid 1px ${colorSteelLighter};
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            height: 2rem;
            justify-content: center;
            position: absolute;
            right: 1rem;
            top: 1rem;
            width: 2rem;
          `}
          onClick={toggleActivePanel}
        >
          <Icon color={"midgray"} size={"12px"} source={"close"} />
        </button>

        {content?.length ? (
          <div>
            {content?.map((item) => {
              return getContent(item, activeTab);
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Solution;
