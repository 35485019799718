import { css } from "@emotion/react";

import type { Props as Header } from "../../ProductPage/SectionHeader";
import SectionHeader from "../../ProductPage/SectionHeader";
import Carousel from "../../Carousel";
import Video from "../../ProductPage/cards/video";

import Grid from "../../Grid";

interface Props {
  header?: Header;
  videos: {
    title: string;
    wistiaID: string;
  }[];
}

const carouselSettings = {
  itemHeight: 282,
  aspectRatio: 360 / 282,
  gap: 16,
  itemsPerScroll: 1,
};

const ProductPageVideos = ({ header, videos }: Props) => {
  return (
    <Grid
      css={css`
        box-sizing: border-box;
        max-width: 1232px;
        width: 100%;
      `}
      gap={"1.5rem"}
      padding={{ base: "0 1rem 2rem", tabletPortrait: "0 1rem 4.5rem" }}
    >
      {header ? <SectionHeader {...header} /> : null}
      {videos.length ? (
        <Carousel {...carouselSettings}>
          {videos.map((video) => (
            <Video
              key={video.wistiaID}
              wistiaID={video.wistiaID}
              title={video.title}
            />
          ))}
        </Carousel>
      ) : null}
    </Grid>
  );
};

export default ProductPageVideos;
