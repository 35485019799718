import { css } from "@emotion/react";
import { borderRadiusMedium, colorBlueLightest } from "@10xdev/design-tokens";
import Grid from "../../Grid";
import Text from "../../Text";
import Icon from "../../Icon";

interface Props {
  title: string;
  list: string[];
}

const Incentives = ({ title, list }: Props) => {
  return (
    <Grid
      css={css`
        max-width: 1200px;
        width: 100%;
      `}
      padding={{ base: "0 1rem 2rem", tabletPortrait: "0 1rem 3.25rem" }}
    >
      <Grid
        css={css`
          background: ${colorBlueLightest};
          border-radius: ${borderRadiusMedium};
          box-shadow: 0px 1.72px 4.29px 0px rgba(0, 0, 0, 0.06);
          padding: 2rem;
        `}
        gap={"2rem 3rem"}
        gridTemplateColumns={{ base: "1fr", tabletLandscape: "320px 1fr" }}
      >
        <Text as={"h3"} size={"xlarge"} weight={"semibold"}>
          {title}
        </Text>
        <ul
          css={css`
            display: grid;
            gap: 1rem;
            list-style: none;
            margin: 0;
            padding: 0;
          `}
        >
          {list.map((item, index) => (
            <Grid key={index} gap={"1rem"} gridTemplateColumns={"20px 1fr"}>
              <Icon source={"gradient-blue-check"} size={"20px"} yPos={"4px"} />
              <Text
                as={"span"}
                color={"midgray"}
                size={"medium"}
                weight={"regular"}
              >
                {item}
              </Text>
            </Grid>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
};

export default Incentives;
