import {
  borderRadiusMedium,
  borderStandard,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

import ColGroup from "./ColGroup";

interface Props {
  children: ReactNode;
  className?: string;
  columns: number;
}

const TableWrap = css`
  max-width: 1200px;
  border: ${borderStandard};
  border-radius: ${borderRadiusMedium};
  @media (max-width: ${mediaTabletLandscape}) {
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

const TableStyle = css`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  @media (max-width: ${mediaTabletLandscape}) {
    table-layout: auto;
    width: 900px;
  }
`;

const Table: FunctionComponent<Props> = ({ className, columns, children }) => {
  return (
    <div className={className} css={TableWrap}>
      <table css={TableStyle}>
        <ColGroup columns={columns} />
        {children}
      </table>
    </div>
  );
};

export default Table;
