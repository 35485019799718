import {
  colorGreyscale7,
  colorSteelLighter,
  colorSteelMedium,
  colorWhite,
  mediaPhoneOnly,
  mediaTabletLandscape,
  spacing64,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Image from "../../Image";
import type { colors } from "../../Text";
import Text from "../../Text";

interface Props {
  disclaimer?: string;
  imageSrc?: string;
  metrics: Array<{
    metricAttribute: string;
    value: string;
  }>;
  mode: "dark" | "light";
  showImage?: boolean;
  title?: string;
}

interface Theme {
  attributeColor: keyof typeof colors;
  backgroundColor: string;
  borderColor: string;
  disclaimerColor: keyof typeof colors;
  titleColor: keyof typeof colors;
  valueColor: keyof typeof colors;
}

const KeyMetrics = ({
  disclaimer,
  imageSrc,
  title,
  metrics,
  mode = "dark",
  showImage = false,
}: Props) => {
  const theme: Theme =
    mode === "dark"
      ? {
          attributeColor: "steelLighter",
          backgroundColor: colorGreyscale7,
          borderColor: colorSteelMedium,
          disclaimerColor: "steelLight",
          titleColor: "white",
          valueColor: "grayLightest",
        }
      : {
          attributeColor: "steelDarker",
          backgroundColor: colorWhite,
          borderColor: colorSteelLighter,
          disclaimerColor: "steelDarkest",
          titleColor: "steelDarkest",
          valueColor: "steelDarkest",
        };
  return (
    <div
      css={css`
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        gap: ${spacing64};
        justify-content: flex-start;

        @media (max-width: ${mediaPhoneOnly}) {
          flex-direction: column;
          gap: 1rem;
        }
      `}
    >
      {showImage && imageSrc ? (
        <Image
          alt={""}
          css={css`
            max-width: 360px;

            @media (max-width: ${mediaPhoneOnly}) {
              max-width: 100%;
            }
          `}
          src={imageSrc}
        />
      ) : null}
      <section
        css={css`
          background-color: ${theme.backgroundColor};
          border-bottom: ${showImage
            ? "none"
            : `1px solid ${theme.borderColor}`};
          display: flex;
          flex-direction: column;
          gap: 2.25rem;
          max-width: 1200px;
          padding-bottom: 2.5rem;
          width: 100%;

          @media (max-width: ${mediaPhoneOnly}) {
            gap: 1rem;
          }
        `}
      >
        {title ? (
          <Text
            as={"h3"}
            color={theme.titleColor}
            css={css`
              @media (max-width: ${mediaPhoneOnly}) {
                font-size: 1.5rem;
              }
            `}
            size={"xlarge"}
            weight={"semibold"}
          >
            {title}
          </Text>
        ) : null}

        <ul
          css={css`
            column-gap: ${showImage ? "3rem" : "7rem"};
            display: grid;
            grid-template-columns: ${showImage
              ? "repeat(3, auto)"
              : "repeat(4, auto)"};
            margin: 0;
            padding: 0;
            row-gap: 2rem;

            @media (max-width: ${mediaTabletLandscape}) {
              grid-template-columns: ${showImage ? "auto" : "repeat(2, auto)"};
            }

            @media (max-width: ${mediaPhoneOnly}) {
              grid-template-columns: auto;
              row-gap: 1.5rem;
            }
          `}
        >
          {metrics.map(({ metricAttribute, value }) => {
            return (
              <li
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 0.25rem;
                `}
                key={metricAttribute}
              >
                <Text
                  as={"div"}
                  color={theme.attributeColor}
                  size={"small"}
                  weight={"regular"}
                >
                  {metricAttribute}
                </Text>
                <Text
                  as={"div"}
                  color={theme.valueColor}
                  css={css`
                    @media (max-width: ${mediaPhoneOnly}) {
                      font-size: 1.25rem;
                    }
                  `}
                  size={"large"}
                  weight={"semibold"}
                >
                  {value}
                </Text>
              </li>
            );
          })}
        </ul>
        {disclaimer ? (
          <Text
            as={"span"}
            color={theme.disclaimerColor}
            size={"xxsmall"}
            weight={"regular"}
          >
            {disclaimer}
          </Text>
        ) : null}
      </section>
    </div>
  );
};

export default KeyMetrics;
