import { colorBlueDark } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Text from "../../Text";

export interface Attributes {
  list: {
    label: string | null;
    url: string | null;
  }[];
}

const ProductAttribute: FunctionComponent<Attributes> = ({ list }) => {
  const emptyCell = (
    <li>
      <Text as={"span"} responsive={true} size={"small"} weight={"medium"}>
        {"–"}
      </Text>
    </li>
  );

  const cell = list.map(({ url, label }) => {
    if (!url) {
      return (
        <Text
          as={"li"}
          css={css`
            padding-bottom: 0.75rem;
          `}
          key={label}
          responsive={true}
          size={"small"}
        >
          {label}
        </Text>
      );
    } else {
      return (
        <Text
          as={"li"}
          color={"blue"}
          css={css`
            margin-bottom: 0.75rem;
          `}
          key={url}
          responsive={true}
          size={"small"}
          weight={"medium"}
        >
          <Anchor hoverColor={colorBlueDark} href={url} target={"_blank"}>
            {label}
          </Anchor>
        </Text>
      );
    }
  });

  return (
    <ul
      css={css`
        list-style: none;
        margin: 0;
        padding: 0;
      `}
    >
      {list.length ? cell : emptyCell}
    </ul>
  );
};

export default ProductAttribute;
