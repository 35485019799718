import { css } from "@emotion/react";

import Text from "../../../Text";
import TableHead from "./TableHead";
import Cell from "./Cell";
import Grid from "../../../Grid";
import {
  borderRadiusMedium,
  borderStandard,
  colorBlueLightest,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import Header from "../Header";
import type { Props as HeaderInterface } from "../Header/Header";
import Cta from "../CTA";
import type { Props as CtaInterface } from "../CTA/CTA";

interface Props {
  header: HeaderInterface;
  table: {
    columns: string[];
    rows: {
      label?: string;
      cells: Array<string[]>;
    }[];
  };
  cta: CtaInterface;
}

export const TableWrap = css`
  max-width: 1200px;
  border: ${borderStandard};
  border-radius: ${borderRadiusMedium};
  @media (max-width: ${mediaTabletLandscape}) {
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

export const TableStyle = css`
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
  @media (max-width: ${mediaTabletLandscape}) {
    table-layout: auto;
    width: 900px;
  }
`;

const Table = ({ header, table, cta }: Props) => {
  return (
    <Grid gap={"2rem"}>
      <Header {...header} />
      <div css={TableWrap}>
        <table css={TableStyle}>
          <colgroup
            css={css`
              col:nth-child(2) {
                background-color: ${colorBlueLightest};
              }
              col:nth-child(n + 2) {
                width: 17.8%;
                border-left: ${borderStandard};
              }

              col:nth-child(1) {
                width: 10.2%;
              }
            `}
          >
            <col />
            {table.columns.map((column) => (
              <col key={column} />
            ))}
          </colgroup>
          <thead>
            <tr>
              <TableHead>{null}</TableHead>
              {table.columns.map((column) => (
                <TableHead key={column}>
                  <Text as={"span"} size={"small"} weight={"semibold"}>
                    {column}
                  </Text>
                </TableHead>
              ))}
            </tr>
          </thead>
          <tbody>
            {table.rows.map((row) => (
              <tr
                css={css`
                  :not(:last-of-type) {
                    border-bottom: ${borderStandard};
                  }
                `}
                key={row.label}
              >
                <td
                  css={css`
                    vertical-align: top;
                    padding: 1rem 0.75rem;
                  `}
                >
                  <Text as={"span"} size={"small"} weight={"semibold"}>
                    {row.label}
                  </Text>
                </td>
                {row.cells.map((cell, index) => (
                  <Cell content={cell} key={index} />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Cta {...cta} />
    </Grid>
  );
};

export default Table;
