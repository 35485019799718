import {
  ContentCard,
  DisplayCard,
  InstrumentCard,
  MediaCard,
  OverviewCard,
  ProductCard,
  ProductCardLarge,
  ProductCardXlarge,
  RoadMapCard,
  RowCard,
  TopicCard,
} from "../../Cards";

export const getCard = (item: Record<string, any>) => {
  switch (item.type) {
    case "productCard":
      return (
        <ProductCard
          description={item.description}
          key={item.product.slug}
          product={item.product}
          url={item.url}
        />
      );
    case "productCardLarge":
      return (
        <ProductCardLarge
          description={item.description}
          key={item.product.slug}
          product={item.product}
          url={item.url}
        />
      );
    case "productCardXlarge":
      return (
        <ProductCardXlarge
          description={item.description}
          key={item.product.slug}
          list={item.list}
          product={item.product}
          url={item.url}
        />
      );
    case "contentCard":
      return (
        <ContentCard
          description={item.description}
          image={item.image}
          key={item.title}
          link={item.link}
          minHeight={item.minHeight}
          textSize={item.textSize}
          title={item.title}
        />
      );
    case "roadMapCard":
      return (
        <RoadMapCard
          description={item.description}
          key={item.title}
          tag={item.tag}
          title={item.title}
        />
      );
    case "topicCard":
      return (
        <TopicCard
          border={item.border}
          callout={item.callout}
          description={item.description}
          image={item.image}
          key={item.title}
          linkList={item.linkList}
          padding={item.padding}
          title={item.title}
        />
      );
    case "displayCard":
      return (
        <DisplayCard
          alignLayout={item.alignLayout}
          alignText={item.alignText}
          description={item.description}
          image={item.image}
          key={item.title || item.description}
          link={item.link}
          padding={item.padding}
          reverse={item.reverse}
          title={item.title}
          titleSize={item.titleSize}
        />
      );
    case "mediaCard":
      return (
        <MediaCard
          border={item.border}
          description={item.description}
          image={item.image}
          key={item.title || item.description}
          linkList={item.linkList}
          list={item.list}
          reverse={item.reverse}
          title={item.title}
          wistiaID={item.wistiaID}
        />
      );
    case "overviewCard":
      return (
        <OverviewCard
          description={item.description}
          elevate={item.elevate}
          image={{
            alt: item.image?.alt,
            url: item.image?.url,
          }}
          key={item.title || item.description}
          linkList={item.linkList}
          list={item.list}
          title={item.title}
        />
      );
    case "rowCard":
      return (
        <RowCard
          content={item.content}
          cta={item.cta}
          image={{
            alt: item.image?.alt,
            source: item.image?.source,
          }}
          key={item.title || item.description}
          link={item.link}
          title={item.title}
        />
      );
    case "instrumentCard":
      return (
        <InstrumentCard
          eyebrow={item.eyebrow}
          highlights={item.highlights}
          image={{
            alt: item.image?.alt,
            source: item.image?.source,
          }}
          key={item.title}
          links={item.links}
          title={item.title}
        />
      );
    default:
      return null;
  }
};
