import { colorBlueMedium, colorProductCnv } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Image from "../../Image/Image";
import Text from "../../Text/Text";

type themeColors = "blue" | "purple";

interface Props {
  color?: themeColors;
  steps: {
    description: string;
    image: {
      alt?: string;
      url: string;
    };
    title: string;
  }[];
}

const getThemeColor = (color: themeColors) => {
  const themes = {
    blue: colorBlueMedium,
    purple: colorProductCnv,
  };
  return themes[color];
};

const getThemeBackground = (color: themeColors) => {
  const themes = {
    blue: "https://cdn.10xgenomics.com/image/upload/v1689796188/products/Xenium/graphics-panel-path-blue.svg",
    purple:
      "https://cdn.10xgenomics.com/image/upload/v1689796193/products/Xenium/graphics-panel-path-purple.svg",
  };
  return themes[color];
};

const PanelsGraphic = ({ color = "blue", steps }: Props) => {
  return (
    <div
      css={css`
        background-image: url(${getThemeBackground(color)});
        background-repeat: no-repeat;
        background-position: center center;
        box-sizing: border-box;
        margin-bottom: 4rem;
        padding: 0 1.5rem;
        @media (max-width: 995px) {
          background: none;
        }
        width: 100%;
      `}
    >
      <div
        css={css`
          display: grid;
          gap: 2rem;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          margin: 0 auto;
          min-height: 692px;
          max-width: 1264px;
        `}
      >
        {steps.map((step, index) => {
          const { description, image, title } = step;
          return (
            <div
              css={css`
                align-self: ${index % 2 === 0 ? "start" : "end"};
              `}
              key={step.title}
            >
              <div
                css={css`
                  display: grid;
                  gap: 1rem;
                  min-height: 550px;
                `}
              >
                <div
                  css={css`
                    display: grid;
                    gap: 0.5rem;
                    order: ${index % 2 === 0 ? 0 : 2};
                    @media (max-width: 995px) {
                      order: 0;
                    }
                  `}
                >
                  <Text
                    as={"p"}
                    color={color}
                    css={css`
                      margin: 0;
                    `}
                    size={"xlarge"}
                    weight={"semibold"}
                  >
                    {`0${index + 1}`}
                  </Text>
                  {title ? (
                    <Text
                      as={"p"}
                      css={css`
                        margin: 0;
                      `}
                      size={"large"}
                      weight={"semibold"}
                    >
                      {title}
                    </Text>
                  ) : null}

                  {description ? (
                    <Text
                      as={"p"}
                      color={"midgray"}
                      css={css`
                        margin: 0;
                      `}
                      size={"medium"}
                      weight={"regular"}
                    >
                      {description}
                    </Text>
                  ) : null}
                </div>

                <div
                  css={css`
                    background-color: ${getThemeColor(color)};
                    border-radius: 3px;
                    margin: 0 auto;
                    min-height: 6.25rem;
                    order: 1;
                    width: 3px;
                  `}
                />
                <Image
                  alt={image.alt ? image.alt : `Step ${index}, ${title}`}
                  css={css`
                    margin: 0 auto;
                    order: ${index % 2 === 0 ? 2 : 0};
                    @media (max-width: 995px) {
                      order: 2;
                    }
                  `}
                  src={image.url}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PanelsGraphic;
