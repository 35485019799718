import { mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Icon from "../Icon";

interface Props {
  actionLeft: () => void;
  actionRight: () => void;
  showToggle: any;
}

const Toggles: FunctionComponent<Props> = ({
  actionLeft,
  actionRight,
  showToggle,
}) => {
  const ToggleStyle = css`
    background-color: rgba(110, 127, 153);
    border: none;
    border-radius: 50%;
    height: 48;
    opacity: 0;
    position: relative;
    transition: opacity 0.4s ease;
    width: 48;
    z-index: 10;

    @media (max-width: ${mediaTabletLandscape}) {
      display: none;
    }
  `;

  const ToggleShow = css({
    "&:hover": {
      boxShadow: "0px 2px 5px rgba(110, 127, 153, 0.1)",
      opacity: 0.75,
      transition: "opacity 0.2s ease",
    },
    cursor: "pointer",
    opacity: 0.5,
    transition: "opacity 0.4s ease",
  });

  const PrevToggle = css`
    align-self: center;
    grid-column-start: 1;
    grid-row-start: 1;
    right: 24px;
  `;

  const NextToggle = css`
    ${PrevToggle};
    justify-self: end;
    position: relative;
  `;

  return (
    <>
      <button
        css={[
          showToggle.prev ? [ToggleStyle, ToggleShow] : ToggleStyle,
          PrevToggle,
        ]}
        onClick={() => actionLeft()}
      >
        <Icon
          color={"white"}
          size={"20px"}
          source={"nav-left"}
          xPos={"-2px"}
          yPos={"1px"}
        />
      </button>
      <button
        css={[
          showToggle.next ? [ToggleStyle, ToggleShow] : ToggleStyle,
          NextToggle,
        ]}
        onClick={() => actionRight()}
      >
        <Icon
          color={"white"}
          size={"24px"}
          source={"nav-right"}
          xPos={"2px"}
          yPos={"1px"}
        />
      </button>
    </>
  );
};

export default Toggles;
