import type { Card } from "@10xdev/cms/types";
import {
  borderStandard,
  colorBlueMedium,
  colorSteelDarkest,
  colorSteelLighter,
  colorWhite,
  fontLineHeightXxxlarge,
  fontSizeXxlarge,
  layoutWidth878,
  layoutWidth1200,
  sizeXlarge,
  sizeXxlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";

import DocumentCard from "../../DocumentCard";
import Heading from "../../Heading";
import Icon from "../../Icon";
import IconButton from "../../IconButton";
import PublicationCard from "../../PublicationCard";
import Text from "../../Text";
import VideoCard from "../../VideoCard";

interface Props {
  setBackground?: (color: string) => void;
  subtitle?: string;
  title?: string;
  topics?: Array<any>;
}

const pageArrowCss = css`
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: ${fontSizeXxlarge};
  width: ${fontSizeXxlarge};
  position: absolute;
  bottom: 4rem;

  :last-of-type {
    left: 4rem;
  }

  :disabled {
    opacity: 0.25;
  }

  @media (max-width: ${layoutWidth878}) {
    bottom: 1rem;
  }
`;

const topicCss = css`
  border: ${borderStandard};
  border-width: 0 0 1px 0;
  color: ${colorSteelDarkest};
`;

const Exploration: FunctionComponent<Props> = ({
  setBackground,
  subtitle,
  title,
  topics = [],
}) => {
  const [showDetail, setShowDetail] = useState(false);
  const [current, setCurrent] = useState(0);

  const handleShowDetail = () => setShowDetail(true);
  const handleCloseButton = () => setShowDetail(false);
  const handleCurrent = (curr: number) => setCurrent(curr);

  const updateState = (index: number) => {
    handleShowDetail();
    handleCurrent(index);
  };

  useEffect(() => {
    if (setBackground) {
      setBackground(showDetail ? colorSteelDarkest : colorWhite);
    }
  }, [showDetail, setBackground]);

  const getCard = (item: Card) => {
    const { source, thumbnail, title, type, url, wistiaID } = item;
    switch (type) {
      case "document":
        return (
          <DocumentCard
            background={colorWhite}
            hover={true}
            info={source}
            key={title}
            title={title}
            url={url}
          />
        );
      case "publication":
        return (
          <PublicationCard
            background={colorWhite}
            hover={true}
            info={source}
            key={title}
            title={title}
            url={url}
          />
        );
      case "video":
        return (
          <VideoCard
            background={colorWhite}
            hover={true}
            info={source}
            key={title}
            thumbnail={thumbnail}
            title={title}
            wistiaId={wistiaID}
          />
        );
    }
  };

  return (
    <div
      className={"Explore"}
      css={css`
        max-width: ${layoutWidth1200};
        min-height: 800px;
        padding: 1rem 1rem 4rem;
        position: relative;

        ::before {
          border-top: 1px solid ${colorBlueMedium};
          content: "";
          position: absolute;
          transform: translate(-48px, 64px) rotate(120deg);
          transform-origin: 0% 0%;
          width: 300px;
        }

        @media (max-width: ${layoutWidth878}) {
          min-height: inherit;

          ::before {
            content: none;
          }
        }
      `}
    >
      {showDetail ? (
        <div
          css={css`
            padding-top: 4rem;
          `}
        >
          <Heading
            as={"h3"}
            color={"white"}
            css={css`
              margin-bottom: 4rem;
            `}
            size={"xlarge"}
          >
            {subtitle}
          </Heading>

          <IconButton
            css={css`
              cursor: pointer;
              position: absolute;
              right: -1rem;
              top: 2rem;
            `}
            onClick={() => handleCloseButton()}
          >
            <Icon color={"white"} size={"20px"} source={"close"} />
          </IconButton>

          <div
            css={css`
              display: flex;
              justify-content: space-between;

              @media (max-width: ${layoutWidth878}) {
                display: block;
              }
            `}
          >
            <div
              css={css`
                flex-basis: 40%;
                padding-right: 4rem;

                @media (max-width: ${layoutWidth878}) {
                  margin-bottom: 2rem;
                  width: 100%;
                }
              `}
            >
              <Heading
                as={"h4"}
                color={"white"}
                css={css`
                  line-height: 1;
                  margin-bottom: ${sizeXxlarge};
                `}
                size={"xxxlarge"}
              >
                {topics[current].title}
              </Heading>
              <Text as={"p"} color={"white"} size={"large"}>
                {topics[current].description}
              </Text>
            </div>

            <div
              css={css`
                flex-basis: 60%;

                @media (max-width: ${layoutWidth878}) {
                  margin-bottom: 2rem;
                  width: 100%;
                  display: flex;
                  overflow-x: scroll;
                }
              `}
            >
              {topics[current].resources.slice(0, 2).map((item: Card) => {
                return getCard(item);
              })}
            </div>
          </div>

          <button
            css={pageArrowCss}
            disabled={current === 0}
            onClick={() => handleCurrent(current - 1)}
          >
            <Icon color={"white"} size={"20px"} source={"nav-left"} />
          </button>
          <button
            css={pageArrowCss}
            disabled={current === topics.length - 1}
            onClick={() => handleCurrent(current + 1)}
          >
            <Icon color={"white"} size={"20px"} source={"nav-right"} />
          </button>
        </div>
      ) : (
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            padding-top: 3rem;

            > h3 {
              line-height: ${fontLineHeightXxxlarge};
              width: 33%;
            }

            @media (max-width: ${layoutWidth878}) {
              display: block;

              > h3 {
                font-size: 2.5rem;
                line-height: 1;
                margin-bottom: 3.5rem;
                width: 100%;
              }
            }
          `}
        >
          <Text as={"h3"} size={"xxxlarge"} weight={"semibold"}>
            {title}
          </Text>
          <ul
            css={css`
              display: flex;
              flex-basis: 55%;
              flex-wrap: wrap;
              justify-content: space-between;
              list-style: none;
              margin-bottom: 0;
              margin-top: 1rem;
              padding-left: 0;

              @media (max-width: ${layoutWidth878}) {
                display: block;
                padding-left: 0;
              }
            `}
          >
            {topics.map((topic, index) => {
              const { title } = topic;
              return (
                <li
                  css={css`
                    flex: 0 0 48%;

                    @media (max-width: ${layoutWidth878}) {
                      width: 100%;
                    }
                  `}
                  key={title}
                >
                  <button
                    css={css`
                      ${topicCss}

                      background: none;
                      display: flex;
                      flex-direction: column;
                      height: 90%; /* for IE11 */
                      justify-content: space-between;
                      margin-bottom: ${sizeXxlarge};
                      min-height: 320px;
                      padding: ${sizeXlarge};
                      text-align: left;
                      transition: all 0.15s ease;
                      width: 100%; /* for IE11 */

                      :hover {
                        background-color: ${colorSteelDarkest};
                        color: ${colorWhite};
                        cursor: pointer;
                      }

                      > .Icon {
                        align-self: flex-end;
                        color: ${colorSteelLighter};
                      }

                      :hover > .Icon {
                        color: ${colorWhite};
                      }

                      @media (max-width: ${layoutWidth878}) {
                        display: none;
                      }
                    `}
                    /* This ID is for Google tracking. Do not change plz. */
                    id={`topic-${index + 1}`}
                    onClick={() => updateState(index)}
                  >
                    <Heading as={"h4"} color={"inherit"} size={"xlarge"}>
                      {title}
                    </Heading>
                    <Icon
                      color={"inherit"}
                      size={"12px"}
                      source={"arrow-diag"}
                    />
                  </button>
                  <div
                    css={css`
                      ${topicCss}

                      display: none;
                      width: 100%;

                      @media (max-width: ${layoutWidth878}) {
                        display: block;
                        margin-bottom: ${sizeXlarge};
                      }
                    `}
                  >
                    <Heading as={"h4"} color={"inherit"} size={"medium"}>
                      {title}
                    </Heading>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Exploration;
