import { mediaTabletLandscape, spacing24 } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Image from "../Image";
import type { colors } from "../Text";
import Text from "../Text";
import type { Mode } from "./types";

interface Image {
  alt: string;
  maxWidth?: string;
  src: string;
  width?: string;
}

interface Caption {
  align?: "center" | "left" | "right";
  color?: keyof typeof colors;
  height?: string;
  text: string;
  width?: string;
}
interface Props {
  caption: Caption;
  className?: string;
  image: Image | Image[];
  layout?: {
    desktop?: "horizontal" | "vertical";
    mobile?: "horizontal" | "vertical";
  };
  mode?: Mode;
}

const Figure = ({
  caption,
  className,
  image,
  layout,
  mode = "dark",
}: Props) => {
  const {
    desktop: desktopLayout = "vertical",
    mobile: mobileLayout = "horizontal",
  } = layout || {};

  const images = Array.isArray(image) ? image : [image];

  return (
    <figure
      className={className}
      css={css`
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: ${spacing24};
        margin: 0;
        padding: 0;
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex: 1;
          flex-direction: ${mobileLayout === "horizontal" ? "row" : "column"};
          gap: ${spacing24};
          overflow-x: auto;
          width: 100%;
          @media (min-width: ${mediaTabletLandscape}) {
            flex-direction: ${desktopLayout === "horizontal"
              ? "row"
              : "column"};
          }
        `}
      >
        {images.map((img) => (
          <div
            css={css`
              flex: 1 1 auto;
            `}
            key={img.src}
          >
            <Image
              alt={img.alt}
              css={css`
                height: 100%;
                margin: 0 auto;
                max-width: ${img.maxWidth || "none"};
                object-fit: cover;
                object-position: center;
                width: ${img.width || "100%"};
              `}
              src={img.src}
            />
          </div>
        ))}
      </div>
      <Text
        as={"figcaption"}
        color={mode === "dark" ? "white" : "base"}
        css={css`
          text-align: ${caption.align || "center"};
          max-width: ${caption.width || "80%"};
          @media (min-width: ${mediaTabletLandscape}) {
            height: ${caption.height || "auto"};
          }
        `}
        size={"xsmall"}
      >
        {caption.text}
      </Text>
    </figure>
  );
};

export default Figure;
