import {
  borderStandard,
  borderRadiusMedium,
  colorWhite,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";

import Grid from "../../../../Grid";

interface Props {
  children: ReactNode;
}

const Card = ({ children }: Props) => {
  return (
    <Grid
      css={css`
        background: ${colorWhite};
        border-radius: ${borderRadiusMedium};
        border: ${borderStandard};
        padding: 1.5rem 2rem;
      `}
      gap={"1rem"}
    >
      {children}
    </Grid>
  );
};

export default Card;
