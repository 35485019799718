import type { Link as LinkProps } from "./Masthead";

import Flex from "../../../Flex";
import Link from "../../../ProductPage/Link";

interface Props {
  links: LinkProps[];
}

const CTAs = ({ links }: Props) => {
  return (
    <Flex gap={"1rem 3rem"} flexWrap={"wrap"}>
      {links.map((link) => {
        return <Link icon={link.icon} label={link.label} url={link.url} />;
      })}
    </Flex>
  );
};

export default CTAs;
