import type { Positive } from "../ClarificationCards";
import Card from "./Card";
import CardHeader from "./CardHeader";
import Text from "../../../../Text";
import Grid from "../../../../Grid";
import ListItem from "./ListItem";

const PositiveList = ({
  label,
  title,
  summary,
  list,
}: Omit<Positive, "type">) => {
  return (
    <Card>
      <CardHeader title={title} label={label} />
      <Text as={"p"} size={"small"}>
        {summary}
      </Text>
      {list?.title ? (
        <Text as={"p"} size={"small"} weight={"semibold"}>
          {list.title}
        </Text>
      ) : null}
      <Grid
        gridTemplateColumns={{ base: "1fr", tabletLandscape: "228px 228px" }}
        gap={".5rem 3.5rem"}
      >
        {list?.items.length
          ? list.items.map((item, index) => (
              <ListItem key={index} label={item.label} image={item.image} />
            ))
          : null}
      </Grid>
    </Card>
  );
};

export default PositiveList;
