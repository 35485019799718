import { mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { Fragment } from "react";

import Image from "../../Image";
import Text from "../../Text";
import type { Figure } from "./types";

interface Props {
  figures: Figure[];
  mode?: "dark" | "light";
}

const Figures = ({ figures, mode = "dark" }: Props) => {
  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 64px;

          @media (max-width: ${mediaTabletLandscape}) {
            grid-template-columns: 1fr;
            row-gap: 0.5rem;
          }
        `}
      >
        {figures.map(({ caption, image: img }, index) => {
          return (
            <Fragment key={`${img.src}-${index}`}>
              <Image
                alt={img.alt}
                css={css`
                  height: 100%;
                  justify-self: center;
                  max-width: ${img.maxWidth || "100%"};
                  object-fit: cover;
                  object-position: center;
                  width: ${img.width || "auto"};
                `}
                src={img.src}
              />
              <div
                css={css`
                  align-items: center;
                  display: none;
                  flex-direction: column;
                  gap: 4px;
                  justify-content: flex-start;
                  padding-bottom: 1rem;
                  @media (max-width: ${mediaTabletLandscape}) {
                    display: flex;
                  }
                `}
                key={`${caption.text}-${index}`}
              >
                <Text
                  as={"figcaption"}
                  color={mode === "dark" ? "white" : "base"}
                  css={css`
                    text-align: ${caption.align || "center"};
                    max-width: ${caption.width || "100%"};
                    @media (min-width: ${mediaTabletLandscape}) {
                      height: ${caption.height || "auto"};
                    }
                  `}
                  size={"xsmall"}
                >
                  {caption.text}
                </Text>
                {caption.subText ? (
                  <Text
                    as={"figcaption"}
                    color={mode === "dark" ? "steelLight" : "base"}
                    css={css`
                      text-align: ${caption.align || "center"};
                    `}
                    size={"xxsmall"}
                    weight={"medium"}
                  >
                    {caption.subText}
                  </Text>
                ) : null}
              </div>
            </Fragment>
          );
        })}
      </div>
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 64px;

          @media (max-width: ${mediaTabletLandscape}) {
            display: none;
          }
        `}
      >
        {figures.map(({ caption }, index) => {
          return (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 4px;
                align-items: center;
                justify-content: flex-start;
              `}
              key={`${caption.text}-${index}`}
            >
              <Text
                as={"figcaption"}
                color={mode === "dark" ? "white" : "base"}
                css={css`
                  text-align: ${caption.align || "center"};
                  max-width: ${caption.width || "100%"};
                  @media (min-width: ${mediaTabletLandscape}) {
                    height: ${caption.height || "auto"};
                  }
                `}
                size={"xsmall"}
              >
                {caption.text}
              </Text>
              {caption.subText ? (
                <Text
                  as={"figcaption"}
                  color={mode === "dark" ? "steelLight" : "base"}
                  css={css`
                    text-align: ${caption.align || "center"};
                  `}
                  size={"xxsmall"}
                  weight={"medium"}
                >
                  {caption.subText}
                </Text>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Figures;
