import {
  colorBlueLightest,
  layoutWidth1200,
  mediaTabletPortrait,
  sizeXxlarge,
  sizeXxxlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import CTAButton from "../../CTAButton";
import Heading from "../../Heading";
import Image from "../../Image";

interface Props {
  action?: any;
  image?: string;
  title?: string;
}

const GetStarted: FunctionComponent<Props> = ({ action, image, title }) => {
  return (
    <div
      css={css`
        align-items: center;
        display: flex;
        max-width: ${layoutWidth1200};
        min-height: ${image ? "576px" : "540px"};
        overflow: hidden;
        padding: 0 1rem ${sizeXxxlarge};
        position: relative;
        width: 100%;
      `}
    >
      <svg
        css={css`
          bottom: 0;
          fill: ${colorBlueLightest};
          pointer-events: none;
          position: absolute;

          @media (max-width: ${mediaTabletPortrait}) {
            display: none;
          }
        `}
        viewBox={"0 0 100 50"}
      >
        <circle cx={50} cy={50} r={50} />
      </svg>

      <div
        css={css`
          align-items: center;
          display: flex;
          flex-direction: column;
          position: relative;
          width: 100%;
          z-index: 1;
        `}
      >
        {image ? (
          <div
            css={css`
              flex: 0 0 auto;
              width: 75%;
            `}
          >
            <Image
              alt={"Single Cell Gene Expression"}
              loading={"lazy"}
              src={image}
            />
          </div>
        ) : null}

        <Heading
          as={"h3"}
          css={css`
            margin-bottom: ${sizeXxlarge};
            text-align: center;
            width: 15ch;
          `}
          size={"xxlarge"}
        >
          {title}
        </Heading>

        <CTAButton
          action={action}
          /* This class name is for Google tracking. Do not change plz. */
          className={"cta-primary"}
          /* This ID is for Google tracking. Do not change plz. */
          id={"cta-primary-get-started"}
          mode={"light"}
        />
      </div>
    </div>
  );
};

export default GetStarted;
