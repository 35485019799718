import type { LinkItem } from "@10xdev/cms/types";
import { colorBlueDark, layoutWidth1200 } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Slash from "../../Slash";
import Text from "../../Text";
import Solution from "./Solution";
import Summary from "./Summary";
import type { ButtonType, ImageType, Query, SolutionType } from "./types";

interface Props {
  link?: LinkItem;
  slash: boolean;
  solutionList?: Array<SolutionType>;
  summary?: {
    button: ButtonType;
    description: string;
    image: ImageType;
    summaryLink: LinkItem;
    summaryTitle: string;
  };
  title: string;
}

const ProductSolutions: FunctionComponent<Props> = ({
  link,
  slash,
  solutionList,
  summary,
  title,
}) => {
  const { button, description, image, summaryLink, summaryTitle } =
    summary || {};

  const { query } = useRouter();
  const { product } = query;

  const [initial, setInitial] = useState<Query>(product);

  useEffect(() => {
    setInitial(product);
  }, [product]);

  return (
    <div
      className={"ProductSolutions"}
      css={css`
        max-width: ${layoutWidth1200};
        padding: 4rem 1rem 2rem;
        width: 100%;
      `}
    >
      <Slash slash={slash}>
        <Heading
          as={"h3"}
          css={css`
            margin-bottom: ${link ? "1.5rem" : "3rem"};
          `}
          size={"xxlarge"}
        >
          {title}
        </Heading>

        {link?.url ? (
          <Anchor
            color={"blue"}
            css={{
              alignItems: "center",
              display: "inline-flex",
              marginBottom: "3rem",
            }}
            hoverColor={colorBlueDark}
            href={link.url}
            target={link.target}
          >
            <Text
              as={"div"}
              color={"inherit"}
              css={css`
                margin-right: 1rem;
                white-space: nowrap;
              `}
              size={"small"}
              weight={"semibold"}
            >
              {link.label}
            </Text>
            {link.type ? (
              <Icon size={"12px"} source={link.type} yPos={"1px"} />
            ) : null}
          </Anchor>
        ) : null}
      </Slash>

      <Summary
        button={button}
        description={description}
        image={image}
        link={summaryLink}
        title={summaryTitle}
      />

      {solutionList?.length
        ? solutionList.map((solution) => {
            const { content, description, title } = solution;
            return (
              <Solution
                content={content}
                description={description}
                initial={initial}
                key={title}
                title={title}
              />
            );
          })
        : null}
    </div>
  );
};

export default ProductSolutions;
