import { borderRadiusMedium, colorBlueLightest } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Text from "../../../Text";

interface Props {
  callout: {
    description?: string;
    title?: string;
  };
}

const Callout: FunctionComponent<Props> = ({ callout }) => {
  const { description, title } = callout;
  return (
    <div
      css={css`
        background-color: ${colorBlueLightest};
        border-radius: ${borderRadiusMedium};
        margin-bottom: 1.5rem;
        padding: 1rem;
      `}
    >
      {title ? (
        <Text
          as={"p"}
          color={"midgray"}
          css={css`
            letter-spacing: 0.05rem;
            margin-bottom: 0.75rem;
            text-transform: uppercase;
          `}
          responsive={true}
          size={"xxsmall"}
          weight={"semibold"}
        >
          {title}
        </Text>
      ) : null}
      {description ? (
        <Text as={"p"} color={"midgray"} responsive={true} size={"xsmall"}>
          {description}
        </Text>
      ) : null}
    </div>
  );
};

export default Callout;
