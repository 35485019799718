import {
  borderStandard,
  colorBlueDark,
  colorBlueMedium,
  layoutWidth1200,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Button from "../../Button";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Paragraph from "../../Paragraph";
import Text from "../../Text";

interface Props {
  columns?: Array<{
    cta?: {
      label: string;
      url: string;
    };
    description?: string;
    panels?: Array<{
      geneList?: {
        label?: string;
        url?: string;
      };
      productSheet?: {
        label?: string;
        url?: string;
      };
      title?: string;
    }>;
    title?: string;
  }>;
  title?: string;
}

const PanelOptions: FunctionComponent<Props> = ({ columns, title }) => {
  return (
    <div
      className={"PanelOptions"}
      css={css`
        margin: 0 auto 7rem;
        max-width: ${layoutWidth1200};

        ::before {
          border-top: 1px solid ${colorBlueMedium};
          content: "";
          position: absolute;
          transform: translate(-48px, 16px) rotate(120deg);
          transform-origin: 0% 0%;
          width: 300px;
        }

        @media (max-width: ${mediaTabletLandscape}) {
          margin-bottom: 5.5rem;
          padding: 0 1rem;
        }
      `}
    >
      <Heading
        as={"h3"}
        css={css`
          margin-bottom: 4rem;
        `}
        responsive={true}
        size={"xxxlarge"}
      >
        {title}
      </Heading>

      <div
        css={css`
          box-sizing: border-box;
          display: flex;

          @media (max-width: ${mediaTabletLandscape}) {
            display: block;
          }
        `}
      >
        {columns && columns.length
          ? columns.map((column, index) => {
              const { cta, description, panels, title } = column;
              return (
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-left: 4rem;
                    padding-right: 4rem;
                    width: 33%;

                    :first-of-type {
                      border-right: ${borderStandard};
                      padding-left: 0;
                    }

                    :last-of-type {
                      border-left: ${borderStandard};
                      padding-right: 0;
                    }

                    @media (max-width: ${mediaTabletLandscape}) {
                      border: 0;
                      border-bottom: ${borderStandard};
                      margin-bottom: 3rem;
                      padding: 0 0 2rem;
                      width: 100%;
                    }
                  `}
                  key={index}
                >
                  <div>
                    <Heading as={"h4"} size={"large"}>
                      {title}
                    </Heading>
                    <Paragraph color={"midgray"} size={"medium"}>
                      {description}
                    </Paragraph>
                    {panels && panels.length ? (
                      <div
                        css={css`
                          margin-bottom: 2rem;

                          > * {
                            margin-bottom: 1rem;
                          }
                        `}
                      >
                        {panels.map((panel) => {
                          const { geneList, productSheet, title } = panel || {};
                          const links = [productSheet, geneList];
                          return (
                            <div key={title}>
                              <Heading
                                as={"h5"}
                                css={css`
                                  margin-bottom: 0.25rem;
                                `}
                                size={"small"}
                                weight={"medium"}
                              >
                                {title}
                              </Heading>

                              {links && links.length ? (
                                <ul
                                  css={css`
                                    align-items: center;
                                    display: flex;
                                    flex-wrap: wrap;
                                    justify-content: space-between;
                                    list-style: none;
                                    margin: 0;
                                    padding: 0;

                                    @media (max-width: ${mediaTabletLandscape}) {
                                      justify-content: flex-start;

                                      > li:first-of-type {
                                        margin-right: 3rem;
                                      }
                                    }
                                  `}
                                >
                                  {links && links.length
                                    ? links.map((link, index) => {
                                        return link &&
                                          link.label &&
                                          link.url ? (
                                          <li
                                            css={css`
                                              white-space: nowrap;
                                            `}
                                            key={index}
                                          >
                                            <Text
                                              as={"div"}
                                              color={"blue"}
                                              size={"small"}
                                              weight={"medium"}
                                            >
                                              <a
                                                css={css`
                                                  color: ${colorBlueMedium};
                                                  text-decoration: none;
                                                  :hover {
                                                    color: ${colorBlueDark};
                                                  }
                                                `}
                                                href={link.url}
                                              >
                                                {link.label}
                                                <Icon
                                                  size={"14px"}
                                                  source={"download"}
                                                  xPos={"12px"}
                                                  yPos={"2px"}
                                                />
                                              </a>
                                            </Text>
                                          </li>
                                        ) : null;
                                      })
                                    : null}
                                </ul>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>

                  {cta ? (
                    <Anchor href={cta.url}>
                      <Button background={"white"} border={true}>
                        <Text
                          as={"span"}
                          color={"blue"}
                          size={"small"}
                          weight={"medium"}
                        >
                          {cta.label}
                        </Text>
                      </Button>
                    </Anchor>
                  ) : null}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default PanelOptions;
