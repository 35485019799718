import { layoutWidth1200, mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

interface Props {
  rows: Array<
    Array<{
      altText: string;
      image: string;
    }>
  >;
  title: string;
  type?: string;
}

const Gallery: FunctionComponent<Props> = ({ rows }) => {
  return (
    <div
      className={"Gallery"}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 92px 24px;
        width: ${layoutWidth1200};

        @media (max-width: ${mediaTabletLandscape}) {
          flex-direction: row;
          overflow-x: scroll;
        }
      `}
    >
      {rows.map((row, rowIndex) => {
        return (
          <div
            css={css`
              display: flex;
              flex-grow: 1;
              gap: 1rem;
              justify-content: center;
              max-height: 320px;

              :nth-of-type(odd) {
                align-self: flex-start;
              }

              :nth-of-type(even) {
                align-self: flex-end;
              }

              @media (max-width: ${mediaTabletLandscape}) {
                margin-right: 1rem;
              }
            `}
            key={`row-${rowIndex}`}
          >
            {row.map((image) => {
              const { image: src, altText } = image;
              return (
                <img
                  alt={altText}
                  css={css`
                    border-radius: 0.375rem;
                    height: 320px;
                  `}
                  key={src}
                  src={src}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Gallery;
