import {
  borderStandard,
  colorBlueDark,
  mediaPhoneOnly,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Image from "../../Image";
import Text from "../../Text";
import type { Software } from "./types";

const SoftwareOverview: FunctionComponent<Software> = ({
  image,
  description,
  linkList,
  title,
}) => {
  return (
    <div
      css={css`
        border-bottom: ${borderStandard};
        padding-bottom: 2rem;

        @media (min-width: ${mediaPhoneOnly}) {
          border-bottom: ${borderStandard};
          display: flex;
          padding-bottom: 2rem;
        }
      `}
    >
      {image?.url ? (
        <Image
          alt={image.alt}
          css={css`
            align-self: flex-start;
            margin-right: 2.5rem;
          `}
          src={image.url}
          width={"160px"}
        />
      ) : null}

      <div
        css={css`
          @media (max-width: ${mediaPhoneOnly}) {
            padding-top: 2rem;
          }
        `}
      >
        <Heading
          as={"h4"}
          css={css`
            margin-bottom: 0.75rem;
          `}
          size={"large"}
        >
          {title}
        </Heading>
        <Text
          as={"p"}
          color={"midgray"}
          css={css`
            margin-bottom: 1rem;
          `}
          size={"small"}
        >
          {description}
        </Text>

        {linkList?.length ? (
          <ul
            css={css`
              display: grid;
              grid-auto-flow: column;
              grid-column-gap: 1.5rem;
              justify-content: start;
              justify-items: start;
              list-style: none;
              margin: 0;
              padding: 0;
            `}
          >
            {linkList.map((link) => {
              const { label, type, url } = link;
              return (
                <li key={label}>
                  {url ? (
                    <Anchor
                      aria-label={`${title}: ${label}`}
                      color={"blue"}
                      hoverColor={colorBlueDark}
                      href={url}
                    >
                      <Text
                        as={"span"}
                        color={"inherit"}
                        css={css`
                          display: inline-block;
                          margin-right: 0.75rem;
                        `}
                        size={"small"}
                        weight={"semibold"}
                      >
                        {label}
                      </Text>
                      {type ? (
                        <Icon
                          color={"inherit"}
                          size={"11px"}
                          source={type}
                          yPos={"1px"}
                        />
                      ) : null}
                    </Anchor>
                  ) : null}
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default SoftwareOverview;
