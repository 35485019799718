import {
  borderRadiusMedium,
  colorGrayLightest,
  colorSteelDark,
  colorWhite,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import classnames from "classnames";
import type { FunctionComponent } from "react";

import { EASE } from "../../../constants";
import Anchor from "../../Anchor";
import Icon from "../../Icon";
import type { IconSource } from "../../Icon/types";
import Text from "../../Text";
import type { Resource, ResourceCardColor, ResourceCardType } from "./types";

const backgrounds: Record<ResourceCardColor, string> = {
  ["gray"]: colorGrayLightest,
  ["white"]: colorWhite,
};

const cardIcons: Record<ResourceCardType, IconSource> = {
  ["document"]: "file-alt",
  ["publication"]: "book-open",
};

const ResourceCard: FunctionComponent<Resource> = ({
  cardColor = "white",
  citation,
  href,
  hover,
  id,
  title,
  type,
}) => {
  return (
    <>
      <Anchor href={href} id={id}>
        <div
          className={classnames("ResourceCard", type)}
          css={css`
            background: ${backgrounds[cardColor] || "none"};
            border-radius: ${borderRadiusMedium};
            box-shadow: 0px 1px 0px rgba(21, 48, 87, 0.15);
            box-sizing: border-box;
            display: flex;
            min-height: 214px;
            padding: 2rem 0 2rem 2.5rem;
            transition: all 0.225s ${EASE};

            :hover {
              border-color: rgba(68, 89, 121, 0.06);
              box-shadow: ${hover
                ? "0px 2px 24px rgba(0, 0, 0, 0.08)"
                : "0px 1px 0px rgba(21, 48, 87, 0.15)"};
            }

            :hover > .ActionIcon {
              opacity: 1;
            }
          `}
        >
          <div
            css={css`
              margin-right: 2.5rem;
            `}
          >
            <Icon color={"blue"} size={"40px"} source={cardIcons[type]} />
          </div>

          <div>
            <Text
              as={"div"}
              color={"blue"}
              css={css`
                margin-bottom: 0.5rem;
                text-transform: capitalize;
              `}
              size={"xsmall"}
              weight={"semibold"}
            >
              {type}
            </Text>

            {title ? (
              <Text
                as={"div"}
                css={css`
                  margin-bottom: 1.5rem;
                `}
                size={"large"}
                weight={"semibold"}
              >
                {title}
              </Text>
            ) : null}

            {citation ? (
              <Text
                as={"div"}
                css={css`
                  color: ${colorSteelDark};
                `}
                size={"xsmall"}
              >
                {citation}
              </Text>
            ) : null}
          </div>

          <div
            /** Do not delete this classname, it's being use by CSS above */
            className={"ActionIcon"}
            css={css`
              align-items: center;
              display: flex;
              opacity: 0;
              padding: 0 1.5rem 0 1rem;
              transition: opacity 0.225s ${EASE};
            `}
          >
            <Icon color={"blue"} size={"14px"} source={"nav-right"} />
          </div>
        </div>
      </Anchor>
    </>
  );
};

export default ResourceCard;
