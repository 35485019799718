import { mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { MDXRemoteSerializeResult } from "next-mdx-remote";
import { MDXRemote } from "next-mdx-remote";
import type { ComponentProps } from "react";

import { MDXComponents } from "../Blocks/MDX";
import Callout from "./Callout";
import FiguresGrid from "./FiguresGrid";
import KeyMetrics from "./KeyMetrics";
import Section from "./Section";
import TabsWithPills from "./TabsWithPills/Block";
import XeniumCardCollection from "./XeniumCardCollection";

type WithType<P extends Record<string, any>, T extends string> = P & {
  hideOn?: "none" | "mobile" | "desktop";
  type: T;
};
type Block =
  | WithType<ComponentProps<typeof Callout>, "callout">
  | WithType<ComponentProps<typeof FiguresGrid>, "figuresGrid">
  | WithType<ComponentProps<typeof KeyMetrics>, "keyMetrics">
  | WithType<ComponentProps<typeof MDX>, "mdx">
  | WithType<ComponentProps<typeof TabsWithPills>, "tabsWithPills">
  | WithType<
      ComponentProps<typeof XeniumCardCollection>,
      "xeniumCardCollection"
    >;

interface Props extends ComponentProps<typeof Section> {
  blocks: Block[];
}

const MDX = ({ content }: { content?: MDXRemoteSerializeResult }) => {
  return content ? <MDXRemote {...content} components={MDXComponents} /> : null;
};

const blockTypes = {
  callout: Callout,
  figuresGrid: FiguresGrid,
  keyMetrics: KeyMetrics,
  mdx: MDX,
  tabsWithPills: TabsWithPills,
  xeniumCardCollection: XeniumCardCollection,
};

const XeniumPageSection = (props: Props) => {
  const { blocks, ...sectionProps } = props;
  return (
    <Section {...sectionProps}>
      {blocks.map((block, index) => {
        const { hideOn = "none", type, ...blockProps } = block;
        const Element = blockTypes[type] as React.FunctionComponent;

        return (
          <div
            css={css`
              display: ${hideOn === "none" ? "block" : "none"};
              padding: 0;
              margin: 0;
              ${hideOn === "mobile" &&
              `
                @media (min-width: ${mediaTabletLandscape}) {
                  display: block;
                } 
              `}

              ${hideOn === "desktop" &&
              `
                @media (max-width: ${mediaTabletLandscape}) {
                  display: block;
                }  
              `}
            `}
            key={index}
          >
            <Element key={index} {...blockProps} />
          </div>
        );
      })}
    </Section>
  );
};

export default XeniumPageSection;
