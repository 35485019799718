import { css } from "@emotion/react";

import Grid from "../../Grid";
import Paragraph from "../../Paragraph";
import Text from "../../Text";
import Image from "../../Image";
import Links from "../Section/Links";
import { getBulletMarker } from "../Section/utils";
import type { Section } from "./PlatformOverview";
import { colorBlueLightest, borderRadiusMedium } from "@10xdev/design-tokens";

interface Props {
  className?: string;
  section: Section;
}

const OverviewSection = ({ section, className }: Props) => {
  const { cta, description, links, list, title } = section;
  return (
    <Grid
      className={className}
      gap={"2rem 3rem"}
      gridTemplateColumns={{ base: "1fr", tabletPortrait: "3.25fr 5fr" }}
    >
      <Text as={"h3"} responsive={true} size={"xlarge"} weight={"semibold"}>
        {title}
      </Text>

      <section>
        {description?.map((paragraph: string) => (
          <Paragraph color={"midgray"} key={paragraph} size={"medium"}>
            {paragraph}
          </Paragraph>
        ))}

        {list?.length ? (
          <ul
            css={css`
              padding: 0;
              display: grid;
              gap: 1.5rem;
              margin: 0 0 1.5rem;
            `}
          >
            {list?.map((item) => {
              return (
                <Text
                  as={"li"}
                  color={"midgray"}
                  css={css`
                    display: flex;
                    list-style: none;
                    gap: 1rem;
                    ::before {
                      content: ${getBulletMarker("checkBlue")};
                      transform: translateY(0.25rem);
                    }
                  `}
                  key={item}
                  size={"medium"}
                >
                  {item}
                </Text>
              );
            })}
          </ul>
        ) : null}

        <Grid gap={"2rem"}>
          {links ? <Links links={links} size={"medium"} /> : null}

          {cta ? (
            <Grid
              css={css`
                background: ${colorBlueLightest};
                border-radius: ${borderRadiusMedium};
              `}
              padding={"1rem"}
              gap={"1rem"}
              gridTemplateColumns={"64px 1fr"}
            >
              <Image alt={cta.image.alt} src={cta.image.src} />
              <Grid gap={".25rem"}>
                <Text as={"p"} size={"medium"}>
                  {cta.description}
                </Text>
                <Links links={[cta.link]} size={"medium"} />
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </section>
    </Grid>
  );
};

export default OverviewSection;
