import { mediaTabletLandscape } from "@10xdev/design-tokens";
import { css, Global } from "@emotion/react";
import { Fragment, useState } from "react";
import { LinkedInEmbed } from "react-social-media-embed";
import { Tweet } from "react-tweet";

import Button from "../../Button";
import Icon from "../../Icon";
import Text from "../../Text";
import type { Props as ContainerProps } from "../Section/Layout/Container";
import Container from "../Section/Layout/Container";

type Social = "twitter" | "linkedin";

interface Props {
  container: ContainerProps;
  gap: number;
  postHeight: number;
  postWidth: number;
  posts: {
    postUrl?: string;
    type: Social;
    url: string;
  }[];
  title: string;
}

const SocialCarousel = ({
  container,
  gap = 32,
  posts,
  postHeight = 800,
  postWidth = 430,
  title,
}: Props) => {
  const [offSet, setOffSet] = useState(0);

  const carouselWidth = posts.length * postWidth + (posts.length - 1) * gap;

  const handlePreviousClick = () => {
    setOffSet(offSet + postWidth + gap);
  };
  const handleNextClick = () => {
    setOffSet(offSet - postWidth - gap);
  };

  return (
    <Container {...container}>
      <div
        css={css`
          @media (min-width: ${mediaTabletLandscape}) {
            align-items: center;
            display: flex;
            justify-content: space-between;
          }
        `}
      >
        <Text
          as={"p"}
          css={css`
            margin: 0;
          `}
          size={"xxlarge"}
          weight={"semibold"}
        >
          {title}
        </Text>
        <div
          css={css`
            display: none;
            @media (min-width: ${mediaTabletLandscape}) {
              display: flex;
            }
          `}
        >
          <Button
            background={"white"}
            border={true}
            color={"blue"}
            css={{ marginRight: "1rem", width: "3rem" }}
            disabled={offSet >= 0}
            onClick={handlePreviousClick}
          >
            <Icon color={"inherit"} size={"1rem"} source={"nav-left"} />
          </Button>
          <Button
            background={"white"}
            border={true}
            color={"blue"}
            css={{
              width: "3rem",
            }}
            disabled={offSet + carouselWidth < 1200}
            onClick={handleNextClick}
          >
            <Icon size={"1rem"} source={"nav-right"} />
          </Button>
        </div>
      </div>

      <div
        css={css`
          max-width: 1200px;
          overflow-x: scroll;
        `}
      >
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr;
            gap: ${gap}px;
            overflow-x: scroll;
            padding-bottom: 2rem;
            width: 100%;

            @media (min-width: ${mediaTabletLandscape}) {
              margin-bottom: 1.5rem;
              overflow: visible;
              padding-bottom: 0;
              transform: translateX(${offSet}px);
              transition: transform 0.4s cubic-bezier(0.77, 0.17, 0.21, 0.81);
              grid-template-columns: repeat(${posts.length}, ${postWidth}px);
            }
          `}
          data-theme={"light"}
        >
          <Global
            styles={css`
              div[data-theme="light"] .react-tweet-theme {
                margin: 0;
              }
            `}
          />
          {posts.map((post) => {
            const socialCard: Record<Social, any> = {
              linkedin: (
                <LinkedInEmbed
                  height={postHeight}
                  postUrl={post.postUrl}
                  url={post.url}
                  width={postWidth}
                />
              ),
              twitter: <Tweet id={post.url.split("/status/")[1]} />,
            };
            return <Fragment key={post.url}>{socialCard[post.type]}</Fragment>;
          })}
        </div>
      </div>
    </Container>
  );
};

export default SocialCarousel;
