export const MIN_CARD_WIDTH: Record<string, string> = {
  ["col-1"]: "100%",
  ["col-2"]: "380px",
  ["col-3"]: "320px",
  ["col-4"]: "220px",
};

export const MIN_CARD_WIDTH_IE: Record<string, string> = {
  ["col-1"]: "100%",
  ["col-2"]: "48.77%",
  ["col-3"]: "31.25%",
  ["col-4"]: "22.34%",
};
