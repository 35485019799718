import {
  colorBlueDark,
  colorBlueMedium,
  colorWhite,
  layoutWidth1200,
  mediaDesktop,
  mediaPhoneOnly,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import ConditionalLink from "../../ConditionalLink";
import Heading from "../../Heading";
import Icon from "../../Icon";
import type { IconSource } from "../../Icon/types";
import Image from "../../Image";
import Text from "../../Text";
import WistiaVideo from "../../WistiaVideo";

// Props that come in from graphql queries and are aliased
// by necessity
interface AliasedProps {
  descriptionParagraphs?: string[];
  eyebrowText?: string;
  imageObject?: Image;
}

interface UnaliasedProps {
  description?: string[];
  eyebrow?: string;
  image?: Image;
}

type Image = {
  alt?: string;
  url: string;
};

type Link = {
  icon?: IconSource;
  label: string;
  type: IconSource;
  url: string;
};

interface BaseProps {
  fullwidth?: boolean;
  imageObject?: Image;
  links?: Array<Link>;
  list?: Array<string>;
  padding?: string;
  reverseLayout?: boolean;
  reverseText?: boolean;
  slash?: boolean;
  subtitle?: string;
  title?: string;
  titleSize?: "xxxlarge" | "xxlarge";
  wistiaID?: string;
}

type Props = (AliasedProps & BaseProps) | (UnaliasedProps & BaseProps);

// I'm sorry
function isAliasedProps(
  props: AliasedProps | UnaliasedProps,
): props is AliasedProps {
  return "imageObject" in props;
}

const linksCss = css`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Feature: FunctionComponent<Props> = ({
  fullwidth,
  links,
  list,
  padding = "4rem 1rem",
  reverseLayout = false,
  reverseText = false,
  slash,
  subtitle,
  title,
  titleSize = "xxxlarge",
  wistiaID,
  ...props
}) => {
  let description: string[] | undefined;
  let eyebrow: string | undefined;
  let image: Image | undefined;

  if (isAliasedProps(props)) {
    ({
      descriptionParagraphs: description,
      eyebrowText: eyebrow,
      imageObject: image,
    } = props);
  } else {
    ({ description, eyebrow, image } = props);
  }

  return (
    <div
      className={"Feature"}
      css={css`
        box-sizing: border-box;
        max-width: ${layoutWidth1200};
        padding: ${padding};
        width: 100%;

        ::before {
          border-top: ${slash ? "1px" : 0} solid
            ${reverseText ? colorWhite : colorBlueMedium};
          content: "";
          position: absolute;
          transform: translate(-48px, 12px) rotate(120deg);
          transform-origin: 0% 0%;
          width: 300px;
        }

        @media (max-width: ${mediaTabletLandscape}) {
          margin: 0;
          padding: 3.5rem 2rem 4rem;
        }

        @media (max-width: ${mediaPhoneOnly}) {
          padding-left: 1rem;
          padding-right: 1rem;
        }

        @media (min-width: ${mediaDesktop}) {
          padding-left: 0;
          padding-right: 0;
        }
      `}
    >
      {eyebrow ? (
        <div
          css={css`
            margin-bottom: 1.5rem;
          `}
        >
          <Text
            as={"div"}
            color={reverseText ? "white" : "blue"}
            responsive={true}
            size={"large"}
            weight={"semibold"}
          >
            {eyebrow}
          </Text>
        </div>
      ) : null}
      <div
        css={css`
          display: ${fullwidth ? "block" : "grid"};
          padding-bottom: 0;
          grid-template-columns: ${reverseLayout ? "5fr 4fr" : "4fr 5fr"};
          grid-template-areas: "left right";
          gap: 10%;

          @media (max-width: ${mediaTabletLandscape}) {
            display: block;
            margin: 0 auto;
          }
        `}
      >
        <div
          css={css`
            margin-bottom: ${fullwidth ? "2rem" : 0};
            grid-area: ${reverseLayout ? "right" : "left"};
            width: ${fullwidth ? "60%" : "100%"};
            @media (max-width: ${mediaTabletLandscape}) {
              margin-bottom: 3rem;
              width: 100%;
            }
          `}
        >
          <Heading
            as={"h3"}
            color={reverseText ? "white" : "base"}
            css={css`
              margin-bottom: 2rem;
            `}
            responsive={true}
            size={titleSize}
          >
            {title}
          </Heading>
          {subtitle ? (
            <Text
              as={"div"}
              color={reverseText ? "white" : "base"}
              css={css`
                margin-bottom: 2rem;
              `}
              size={"medium"}
              weight={"medium"}
            >
              {subtitle}
            </Text>
          ) : null}

          {description?.length
            ? description.map((paragraph) => {
                return (
                  <Text
                    as={"p"}
                    color={reverseText ? "white" : "midgray"}
                    css={css`
                      margin-bottom: 1.5rem;
                    `}
                    key={paragraph}
                    responsive={true}
                    size={"medium"}
                  >
                    {paragraph}
                  </Text>
                );
              })
            : null}

          {list?.length ? (
            <ul
              css={css`
                display: flex;
                flex-direction: column;
                gap: 1rem;
                margin: 0 0 1.5rem;
                padding-left: 1rem;
              `}
            >
              {list.map((item) => {
                return (
                  <Text
                    as={"li"}
                    color={reverseText ? "white" : "midgray"}
                    key={item}
                    responsive={true}
                    size={"medium"}
                  >
                    {item}
                  </Text>
                );
              })}
            </ul>
          ) : null}

          {links?.length ? (
            <ul css={linksCss}>
              {links.map((link, index) => {
                const { label, type, url, icon } = link;
                return (
                  <li key={url}>
                    <Anchor
                      color={reverseText ? "white" : "blue"}
                      css={css`
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        ${icon && index != links.length - 1
                          ? "margin-bottom: 1rem"
                          : null}
                      `}
                      hoverColor={colorBlueDark}
                      href={url}
                    >
                      {icon ? <Icon size={"26px"} source={icon} /> : null}
                      <Text
                        as={"span"}
                        color={"inherit"}
                        size={"small"}
                        weight={"semibold"}
                      >
                        {label}
                      </Text>
                      <Icon size={"12px"} source={type} yPos={"1px"} />
                    </Anchor>
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>

        {image?.url ? (
          <div
            css={css`
              width: 100%;
              grid-area: ${reverseLayout ? "left" : "right"};

              @media (max-width: ${mediaTabletLandscape}) {
                width: 100%;
              }
            `}
          >
            <ConditionalLink href={links?.[0].url}>
              <Image alt={image.alt} src={image.url} />
            </ConditionalLink>
          </div>
        ) : null}

        {wistiaID ? (
          <div
            css={css`
              width: 100%;
              grid-area: ${reverseLayout ? "left" : "right"};

              @media (max-width: ${mediaTabletLandscape}) {
                width: 100%;
              }
            `}
          >
            <WistiaVideo wistiaID={wistiaID} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Feature;
