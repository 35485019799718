import { colorBlueLighter, colorGrayLightest } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Card from "../Card";
import Heading from "../Heading";
import Icon from "../Icon";
import type { IconSource } from "../Icon/types";
import Text from "../Text";

interface Props {
  active: boolean;
  description: string;
  href: string;
  iconSource: IconSource;
  target?: "_blank";
  title: string;
}

const SubNavCard: FunctionComponent<Props> = ({
  description,
  href,
  iconSource,
  target,
  title,
  active,
}) => {
  const textColor = active ? "blue" : "base";
  return (
    <Anchor
      css={css`
        display: block;
        margin: 0;
      `}
      href={href}
      target={target}
    >
      <Card
        css={css`
          background: ${active ? colorBlueLighter : colorGrayLightest};
          min-height: 215px;
          padding: 1.5rem 3rem 2rem 2rem;
        `}
        hoverable={true}
      >
        <div>
          <Icon
            color={textColor}
            css={{
              paddingBottom: "15px",
            }}
            size={"2rem"}
            source={iconSource}
          />
        </div>

        <div>
          <Heading
            as={"h4"}
            color={textColor}
            css={css`
              margin-bottom: 10px;
            `}
            responsive={true}
            size={"large"}
            weight={"semibold"}
          >
            {title}
            <Icon
              color={textColor}
              css={{
                marginLeft: "12px",
              }}
              size={"0.75rem"}
              source={"nav-right"}
            />
          </Heading>
        </div>

        <div>
          <Text
            as={"p"}
            color={"midgray"}
            responsive={true}
            size={"medium"}
            weight={"regular"}
          >
            {description}
          </Text>
        </div>
      </Card>
    </Anchor>
  );
};

export default SubNavCard;
