import { colorWhite, mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ForwardedRef, FunctionComponent } from "react";
import { useState, forwardRef } from "react";

import { ImageCtaCard } from "../../Cards";
import { NewToSCBlocks } from "../NewToSingleCell";
import { Workflow, WorkflowShelf } from "../Workflow";
import Advantage from "../Advantage";
import Announcement from "../../Announcement";
import Assays from "../Assays";
import Collection from "../Collection";
import ComparisonTable from "../ComparisonTable/ComparisonTable";
import CompatibilityTable from "../CompatibilityTable";
import CTA from "../CTA";
import CTAPanel from "../CTAPanel";
import DataAnalysis from "../ProductPage/DataAnalysis";
import Explore from "../Explore";
import Extensions from "../Extensions";
import FAQs from "../FAQs";
import Feature from "../Feature";
import FeatureOptions from "../FeatureOptions";
import FilterCarousel from "../FilterCarousel";
import Gallery from "../Gallery";
import GetStarted from "../GetStarted";
import HeadingList from "../HeadingList";
import IconList from "../../IconList";
import ImageCarousel from "../ImageCarousel";
import Incentives from "../ProductPage/Incentives";
import Info from "../Info";
import InstrumentComparison from "../InstrumentComparison";
import KeyMetrics from "../../XeniumDatasetExplorer/KeyMetrics";
import LinkGroup from "../LinkGroup";
import Masthead from "../Masthead";
import MDX from "../MDX";
import Metrics from "../Metrics";
import NewsSubNav from "../NewsSubNav";
import Overview from "../Overview";
import PageTitle from "../PageTitle";
import PanelOptions from "../PanelOptions";
import Panels from "../Panels";
import PanelsGraphic from "../PanelsGraphic";
import PlatformOverview from "../PlatformOverview";
import ProductPageComponents from "../ProductPage/Components";
import ProductPageDatasets from "../ProductPage/Datasets";
import ProductPageMasthead from "../ProductPage/Masthead";
import ProductPagePublications from "../ProductPage/Publications";
import ProductPageRelatedProducts from "../ProductPage/RelatedProducts";
import ProductPageResources from "../ProductPage/Resources";
import ProductPageVideos from "../ProductPage/Videos";
import ProductSolutions from "../ProductSolutions";
import RelatedProducts from "../RelatedProducts";
import Requirements from "../Requirements/Requirements";
import RequirementsPanels from "../RequirementsPanels";
import ResourceList from "../ResourceList";
import Resources from "../Resources";
import Results from "../Results";
import Section from "../Section";
import SectionInfo from "../SectionInfo";
import SectionWithLink from "../../XeniumDatasetExplorer/SectionWithLink";
import ServiceProviderSignup from "../../ServiceProvider";
import SocialCarousel from "../SocialCarousel";
import Solution from "../Solution";
import SpatialVisualization from "../SpatialVisualization";
import Specifications from "../ProductPage/Specifications";
import StickyHeader from "../StickyHeader";
import Subnav from "../Subnav";
import TabsWithPills from "../../XeniumDatasetExplorer/TabsWithPills/Block";
import Update from "../Update";
import XeniumCardCollection from "../../XeniumDatasetExplorer/XeniumCardCollection";
import XeniumPageSection from "../../XeniumDatasetExplorer/XeniumPageSection";
interface Props {
  data?: any;
  isPreview?: boolean;
  ref?: ForwardedRef<HTMLDivElement>;
}

const Block: FunctionComponent<Props> = forwardRef<HTMLDivElement, Props>(
  ({ data, isPreview }: Props, ref) => {
    const { backgroundColor, id, slug, type } = data;

    const [blockTypes] = useState<Record<string, FunctionComponent<any>>>({
      ...NewToSCBlocks,
      advantage: Advantage,
      announcement: Announcement,
      assays: Assays,
      collection: Collection,
      comparisonTable: ComparisonTable,
      compatibilityTable: CompatibilityTable,
      cta: CTA,
      ctaPanel: CTAPanel,
      dataAnalysis: DataAnalysis,
      explore: Explore,
      extensions: Extensions,
      faqs: FAQs,
      feature: Feature,
      featureOptions: FeatureOptions,
      filterCarousel: FilterCarousel,
      gallery: Gallery,
      getStarted: GetStarted,
      headingList: HeadingList,
      imageCarousel: ImageCarousel,
      imageCtaCard: ImageCtaCard,
      incentives: Incentives,
      info: Info,
      instrumentComparison: InstrumentComparison,
      keyMetrics: KeyMetrics,
      linkGroup: LinkGroup,
      masthead: Masthead,
      mdx: MDX,
      metrics: Metrics,
      newsSubNav: NewsSubNav,
      overview: Overview,
      pageTitle: PageTitle,
      panelOptions: PanelOptions,
      panels: Panels,
      panelsGraphic: PanelsGraphic,
      platformOverview: PlatformOverview,
      productBenefits: IconList,
      productPageComponents: ProductPageComponents,
      productPageDatasets: ProductPageDatasets,
      productPageMasthead: ProductPageMasthead,
      productPagePublications: ProductPagePublications,
      productPageRelatedProducts: ProductPageRelatedProducts,
      productPageResources: ProductPageResources,
      productPageVideos: ProductPageVideos,
      productSolutions: ProductSolutions,
      relatedProducts: RelatedProducts,
      requirements: Requirements,
      requirementsPanels: RequirementsPanels,
      resourceList: ResourceList,
      resources: Resources,
      results: Results,
      section: Section,
      sectionInfo: SectionInfo,
      sectionWithLink: SectionWithLink,
      serviceProviderSignup: ServiceProviderSignup,
      socialCarousel: SocialCarousel,
      solution: Solution,
      spatialVisualization: SpatialVisualization,
      specifications: Specifications,
      stickyHeader: StickyHeader,
      subnav: Subnav,
      tabsWithPills: TabsWithPills,
      update: Update,
      workflow: Workflow,
      workflowShelf: WorkflowShelf,
      xeniumCardCollection: XeniumCardCollection,
      xeniumPageSection: XeniumPageSection,
    });

    // Some block functionality - for example, main site navigation -
    // is problematic or even explosive in Charlie previews. For that
    // reason we completely disable some block types in preview mode.
    const [disabledInPreview] = useState([
      "announcement",
      "explore",
      "footer",
      "getStarted",
      "masthead",
      "relatedProducts",
      "results",
    ]);

    const getElement = (type: string) => {
      switch (type) {
        case "announcement":
          return "announcement";
        case "footer":
          return "footer";
        case "masthead":
          return "header";
        default:
          return "section";
      }
    };

    const Element = getElement(type);
    const Content = blockTypes[type];
    const isDisabledInPreview = disabledInPreview.includes(type)
      ? "none"
      : "all";
    const [background, setBackground] = useState(backgroundColor || colorWhite);

    // We don't want the announcement banner to
    // get wrapped in an outer block element.
    if (Element === "announcement") {
      return <Announcement />;
    }

    return Content ? (
      <Element
        css={css`
          background: ${background};
          display: flex;
          justify-content: center;
          margin: 0 auto;
          padding: 0;
          pointer-events: ${isPreview ? isDisabledInPreview : "all"};

          @media (max-width: ${mediaTabletLandscape}) {
            justify-content: flex-start;

            > * {
              overflow-x: scroll;
            }
          }
        `}
        id={id || slug}
        ref={ref}
      >
        <Content
          {...data}
          isPreview={isPreview}
          setBackground={setBackground}
        />
      </Element>
    ) : null;
  },
);

export default Block;
