import { mediaPhoneOnly, sizeXxlarge } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../../Heading";
import type { FaqItem } from "./FaqAccordion";
import { FaqAccordion } from "./FaqAccordion";

interface Props {
  questions: Array<FaqItem>;
  title?: string;
}

const FAQs: FunctionComponent<Props> = ({ questions, title }) => {
  return questions && questions.length ? (
    <div
      className={"FAQs"}
      css={css`
        max-width: 720px;
        padding: 4rem 1rem;

        > h3 {
          text-align: center;
        }

        @media (max-width: ${mediaPhoneOnly}) {
          > h3 {
            font-size: 2.5rem;
            line-height: 1;
            text-align: left;
          }
        }
      `}
    >
      <Heading
        as={"h3"}
        css={css`
          margin-bottom: ${sizeXxlarge};
        `}
        size={"xxlarge"}
      >
        {title}
      </Heading>

      <div>
        {questions.map((item) => {
          return <FaqAccordion item={item} key={item.question} />;
        })}
      </div>
    </div>
  ) : null;
};

export default FAQs;
