import CardD from "../Section/Cards/CardD";
import BlogCard from "./CarouselCards/BlogCard";
import ImageCard from "./CarouselCards/ImageCard";
import PanelCard from "./CarouselCards/PanelCard";
import SCAssayCard from "./CarouselCards/SCAssayCard";

export const Components = {
  BlogCard,
  CardD,
  ImageCard,
  PanelCard,
  SCAssayCard,
};
