import {
  colorBlueDark,
  colorBlueLighter,
  colorBlueMedium,
  colorSteelDarker,
  colorSteelLighter,
  colorSteelMedium,
  colorWhite,
  fontWeightSemibold,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";

import { EASE } from "../../../constants";

interface Props {
  active: boolean;
  children: ReactNode;
  mode?: "dark" | "light";
  onClick: () => void;
}

const Tab = ({ children, active, mode = "dark", onClick }: Props) => {
  const theme =
    mode === "dark"
      ? {
          activeBackgroundColor: colorBlueMedium,
          backgroundColor: "transparent",
          borderColor: colorSteelMedium,
          color: colorWhite,
          hoverBackgroundColor: "rgba(255, 255, 255, 0.12)",
          hoverColor: colorWhite,
        }
      : {
          activeBackgroundColor: colorBlueMedium,
          backgroundColor: `${colorWhite}`,
          borderColor: colorSteelLighter,
          color: colorSteelDarker,
          hoverBackgroundColor: colorBlueLighter,
          hoverColor: colorSteelMedium,
        };

  return (
    <button
      css={css`
        background-color: ${active
          ? theme.activeBackgroundColor
          : theme.backgroundColor};
        border: solid 1px ${active ? colorBlueMedium : theme.borderColor};
        border-radius: 3rem;
        color: ${active ? colorWhite : theme.color};
        cursor: pointer;
        font-weight: ${fontWeightSemibold};
        height: 38px;
        padding: 0rem 1.5rem;
        transition: all 0.1s ${EASE};
        :hover {
          border: solid 1px ${active ? colorBlueDark : theme.borderColor};
          background: ${active ? colorBlueDark : theme.hoverBackgroundColor};
        }
      `}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Tab;
