import {
  borderRadiusLarge,
  borderRadiusMedium,
  borderStandard,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { v4 as uuidv4 } from "uuid";

import Anchor from "../../../Anchor/Anchor";
import Icon from "../../../Icon/Icon";
import type { IconSource } from "../../../Icon/types";
import Image from "../../../Image/Image";
import Text from "../../../Text/Text";

interface Props {
  available: boolean;
  description: string;
  details: {
    data: string;
    label: string;
  }[];
  image: {
    alt: string;
    src: string;
  };
  links: {
    icon: IconSource;
    label: string;
    url: string;
  }[];
  title: string;
}

const PanelCard = ({
  available = true,
  description,
  details,
  image,
  links,
  title,
}: Props) => {
  return (
    <div
      css={css`
        align-content: start;
        border-radius: ${borderRadiusLarge};
        border: ${borderStandard};
        box-shadow: 0px 1.7px 4.3px 0px rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        display: grid;
        flex: 1 0 348px;
        grid-row-gap: 1rem;
        grid-template-columns: 1fr;
        grid-template-rows: auto ${available ? "8rem" : "9.625rem"} auto auto;
        padding: 1.5rem 1.5rem 1rem;
        @media (min-width: ${mediaTabletLandscape}) {
          max-width: 348px;
          padding: 1.5rem 1.5rem 1rem;
        }
      `}
    >
      <Image
        alt={image.alt}
        css={css`
          border-radius: ${borderRadiusMedium};
        `}
        src={image.src}
      />
      <div>
        {!available ? (
          <Text
            as={"div"}
            color={"blue"}
            css={css`
              margin-bottom: 0.5rem;
            `}
            size={"xsmall"}
            weight={"semibold"}
          >
            {"Coming soon"}
          </Text>
        ) : null}
        <Text
          as={"h4"}
          css={css`
            margin-bottom: 0.5rem;
          `}
          size={"medium"}
          weight={"semibold"}
        >
          {title}
        </Text>
        <Text
          as={"p"}
          color={"midgray"}
          css={css`
            margin: 0;
          `}
          size={"small"}
        >
          {description}
        </Text>
      </div>
      <div
        css={css`
          display: flex;
          gap: 1.5rem;
        `}
      >
        {details.map((item) => {
          return (
            <div
              css={css`
                display: grid;
              `}
              key={uuidv4()}
            >
              <Text
                as={"div"}
                color={"midgray"}
                css={css`
                  margin: 0;
                  line-height: 1.2;
                `}
                size={"xsmall"}
              >
                {item.label}
              </Text>
              <Text
                as={"div"}
                css={css`
                  margin: 0;
                  line-height: 1.2;
                `}
                size={"small"}
                weight={"medium"}
              >
                {item.data}
              </Text>
            </div>
          );
        })}
      </div>

      {available && links.length ? (
        <div
          css={css`
            display: grid;
            gap: 0.25rem;
          `}
        >
          {links.map((link) => {
            return (
              <Anchor
                css={css`
                  align-items: center;
                  display: flex;
                  gap: 0.75rem;
                  height: 2rem;
                `}
                href={link.url}
                key={link.url}
              >
                <Text
                  as={"span"}
                  color={"inherit"}
                  size={"small"}
                  weight={"semibold"}
                >
                  {link.label}
                </Text>
                <Icon
                  color={"inherit"}
                  size={"12px"}
                  source={link.icon}
                  yPos={"1px"}
                />
              </Anchor>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default PanelCard;
