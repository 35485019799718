import { css } from "@emotion/react";

import Grid from "../../Grid";
import Text from "../../Text";
import type { CTA } from "../types";
import Link from "../Link";
import type { Link as LinkType } from "../types";
import { mediaTabletLandscape } from "@10xdev/design-tokens";

interface Props {
  title: string;
  links?: LinkType[];
  description?: string;
  cta?: CTA;
}

const Resource = ({ title, description, links, cta }: Props) => {
  return (
    <Grid
      css={css`
        padding: 0.75rem 0;
      `}
      gap={"1rem 6.67%"}
      gridTemplateColumns={{
        base: "1fr",
        tabletLandscape: "27% fit-content(45%) 1fr",
      }}
    >
      <Text as={"h4"} size={"large"} weight={"semibold"}>
        {title}
      </Text>
      {links?.length ? (
        <Grid gap={".25rem"}>
          {links.map((link) => {
            return (
              <Link
                key={link.label}
                label={link.label}
                url={link.url}
                icon={link.icon}
              />
            );
          })}
        </Grid>
      ) : null}
      {description ? (
        <Text as={"p"} size={"small"}>
          {description}
        </Text>
      ) : null}
      {cta ? (
        <Link
          css={css`
            @media (min-width: ${mediaTabletLandscape}) {
              justify-self: end;
            }
          `}
          label={cta.label}
          url={cta.url}
          icon={cta.icon}
        />
      ) : null}
    </Grid>
  );
};

export default Resource;
