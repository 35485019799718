import type { LinkItem } from "@10xdev/cms/types";
import {
  colorBlueDark,
  mediaTabletLandscape,
  mediaTabletPortrait,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { kebabCase } from "lodash-es";
import type { FunctionComponent } from "react";

import Anchor from "../../../Anchor";
import Icon from "../../../Icon";
import Image from "../../../Image";
import Text from "../../../Text";
import type { Bullet, ButtonType, ImageType, Query } from "../types";
import Header from "./Header";

interface Props {
  activeTab: Query;
  button?: ButtonType;
  description?: string;
  image?: ImageType;
  link?: LinkItem;
  list?: Array<Bullet>;
  secondaryLink?: LinkItem;
  title?: string;
}

const Illustration: FunctionComponent<Props> = ({
  activeTab,
  button,
  description,
  image,
  link,
  list,
  secondaryLink,
  title,
}) => {
  const slug = kebabCase(title);

  return (
    <div
      css={css`
        display: ${activeTab === slug ? "block" : "none"};
        padding: 2rem;
        margin-bottom: 1.5rem;
        @media (min-width: ${mediaTabletLandscape}) {
          padding: 2.5rem 3.5rem;
        }
      `}
      id={slug}
    >
      <Header
        button={button}
        description={description}
        link={link}
        title={title}
      />

      <div
        css={css`
          padding-top: 2rem;

          @media (min-width: ${mediaTabletLandscape}) {
            display: flex;
            justify-content: space-between;
          }
        `}
      >
        <div
          css={css`
            @media (min-width: ${mediaTabletPortrait}) {
              width: 75%;
            }

            @media (min-width: ${mediaTabletLandscape}) {
              width: 50%;
            }
          `}
        >
          {image?.url ? <Image alt={image.altText} src={image.url} /> : null}
        </div>

        <div
          css={css`
            padding-top: 7.5%;
            flex-basis: 360px;
            width: 100%;

            @media (min-width: ${mediaTabletLandscape}) {
              padding-top: 7.5%;
              width: 50%;
            }
          `}
        >
          <ul
            css={css`
              list-style: none;
              margin: 0 0 3rem;
            `}
          >
            {list && list.length
              ? list.slice(0, 3).map((item) => {
                  return (
                    <li
                      css={css`
                        background: url("https://cdn.10xgenomics.com/image/upload/v1588968667/icons/checkmark.svg")
                          no-repeat;
                        margin-bottom: 1.5rem;
                        padding-left: 3rem;
                        padding-top: 0.25rem;

                        :last-of-type {
                          margin-bottom: 0;
                        }
                      `}
                      key={item.description}
                    >
                      <Text as={"span"} color={"midgray"} size={"medium"}>
                        {item.description}
                      </Text>
                    </li>
                  );
                })
              : null}
          </ul>

          {secondaryLink?.url ? (
            <Text
              as={"span"}
              color={"blue"}
              css={css`
                display: block;
                padding-left: 5.5rem;
              `}
              size={"small"}
              weight={"semibold"}
            >
              <Anchor
                color={"inherit"}
                hoverColor={colorBlueDark}
                href={secondaryLink.url}
                target={secondaryLink.target}
              >
                {secondaryLink.label}
                {secondaryLink.type ? (
                  <Icon
                    size={"12px"}
                    source={secondaryLink.type}
                    xPos={"16px"}
                    yPos={"1px"}
                  />
                ) : null}
              </Anchor>
            </Text>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Illustration;
