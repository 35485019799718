import { css } from "@emotion/react";

import Grid from "../Grid";
import Text from "../Text";
import Button from "../ProductPage/Button";
import Icon from "../Icon";

export interface Props {
  title?: string;
  description?: string;
  cta?: {
    label: string;
    url: string;
  };
}

const SectionHeader = ({ title, description, cta }: Props) => {
  return (
    <Grid
      alignItems={description ? "end" : "center"}
      gridTemplateColumns={{ base: "1fr", tabletLandscape: "60% auto" }}
      justifyContent={"space-between"}
      gap={"1rem"}
      css={css`
        max-width: 1200px;
        width: 100%;
      `}
    >
      <Grid gap={".75rem"}>
        <Text as={"h3"} size={"xxlarge"} weight={"semibold"}>
          {title}
        </Text>
        {description ? (
          <Text as={"p"} color={"midgray"} size={"medium"}>
            {description}
          </Text>
        ) : null}
      </Grid>
      {cta ? (
        <Button href={cta.url}>
          <Text as={"span"} color={"white"} size={"medium"} weight={"medium"}>
            {cta.label}
          </Text>
          <Icon
            color={"inherit"}
            css={css`
              flex-shrink: 0;
            `}
            size={"11.5px"}
            source={"nav-right"}
            yPos={"2px"}
          />
        </Button>
      ) : null}
    </Grid>
  );
};

export default SectionHeader;
