import { css } from "@emotion/react";
import { borderStandard } from "@10xdev/design-tokens";
import Grid from "../Grid";

interface Props {
  card: any;
  list: any[];
}

const Repeater = ({ list, card }: Props) => {
  const Card = card;
  return (
    <Grid
      css={css`
        box-sizing: border-box;
      `}
    >
      {list.map((card) => {
        return (
          <div
            css={css`
              padding: 0.75rem 0;
              border-bottom: ${borderStandard};
            `}
          >
            <Card {...card} />
          </div>
        );
      })}
    </Grid>
  );
};

export default Repeater;
