import {
  borderRadiusLarge,
  borderStandard,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Anchor from "../../../Anchor/Anchor";
import Icon from "../../../Icon/Icon";
import type { IconSource } from "../../../Icon/types";
import Image from "../../../Image/Image";
import Text from "../../../Text/Text";

interface Props {
  description: string;
  image: {
    alt: string;
    src: string;
  };
  links: {
    icon: IconSource;
    label: string;
    url: string;
  }[];
  title: string;
}

const BlogCard = ({ description, image, links, title }: Props) => {
  return (
    <div
      css={css`
        border-radius: ${borderRadiusLarge};
        border: ${borderStandard};
        box-shadow: 0px 1.7px 4.3px 0px rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        flex: 1 0 auto;
        overflow: hidden;
        @media (min-width: ${mediaTabletLandscape}) {
          max-width: 448px;
          flex: 1 0 448px;
        }
      `}
    >
      <Image alt={image.alt} src={image.src} />
      <div
        css={css`
          padding: 1.5rem 2rem 1.5rem;
        `}
      >
        <Text
          as={"h4"}
          css={css`
            margin-bottom: 1.5rem;
          `}
          size={"large"}
          weight={"semibold"}
        >
          {title}
        </Text>
        <Text
          as={"p"}
          color={"midgray"}
          css={css`
            margin: 0 0 0.75rem;
          `}
          size={"small"}
        >
          {description}
        </Text>

        {links.length ? (
          <div
            css={css`
              display: grid;
            `}
          >
            {links.map((link) => {
              return (
                <Anchor
                  css={css`
                    align-items: center;
                    display: flex;
                    gap: 0.75rem;
                    height: 2rem;
                  `}
                  href={link.url}
                  key={link.url}
                >
                  <Text
                    as={"span"}
                    color={"inherit"}
                    size={"small"}
                    weight={"semibold"}
                  >
                    {link.label}
                  </Text>
                  <Icon
                    color={"inherit"}
                    size={"12px"}
                    source={link.icon}
                    yPos={"1px"}
                  />
                </Anchor>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default BlogCard;
