import {
  borderStandard,
  colorBlueDark,
  layoutWidth878,
  layoutWidth1200,
  sizeMedium,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Cell from "../../ComparisonTable/Cell";
import CellList from "../../ComparisonTable/CellList";
import RowHead from "../../ComparisonTable/RowHead";
import Table from "../../ComparisonTable/Table";
import TableBody from "../../ComparisonTable/TableBody";
import TableHead from "../../ComparisonTable/TableHead";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Image from "../../Image";
import Text from "../../Text";
import Compatibility from "./Compatibility";
import Throughput from "./Throughput";
import type { InstrumentAttributes } from "./types";

interface Props {
  footnote?: string;
  instruments?: InstrumentAttributes[];
  title?: string;
}

const InstrumentComparison: FunctionComponent<Props> = ({
  footnote,
  instruments,
  title,
}) => {
  return (
    <div
      className={"InstrumentComparison"}
      css={css`
        max-width: ${layoutWidth1200};
        padding: 4rem 1rem 8rem;

        & tr:not(:last-of-type) {
          border-bottom: ${borderStandard};
        }

        & tr > td {
          text-align: left;
          vertical-align: top;
        }

        @media (max-width: ${layoutWidth878}) {
          margin-bottom: 6rem;

          & tr > td {
            padding: ${sizeMedium};
            max-width: 200px;
          }
        }
      `}
      id={"instrument-comparison"}
    >
      <Heading
        as={"h3"}
        css={css`
          margin-bottom: 2rem;
        `}
        size={"xlarge"}
      >
        {title}
      </Heading>
      {instruments && instruments.length ? (
        <>
          <Table columns={instruments.length}>
            <TableHead>
              <RowHead label={"Instruments"} />

              {instruments.map((instrument) => {
                const { image, instrument: slug } = instrument;
                const { name, url } = slug;
                return (
                  <Cell key={name}>
                    <Image
                      alt={""}
                      css={css`
                        margin-bottom: 1rem;
                      `}
                      src={image}
                      width={"100%"}
                    />
                    <Heading
                      as={"h3"}
                      css={css`
                        margin-bottom: 2rem;
                      `}
                      size={"large"}
                    >
                      {name}
                    </Heading>
                    <Anchor
                      aria-label={`view ${name} details`}
                      color={"blue"}
                      hoverColor={colorBlueDark}
                      href={url}
                    >
                      <Text
                        as={"span"}
                        color={"inherit"}
                        css={css`
                          display: inline-block;
                          margin-right: 1rem;
                        `}
                        size={"small"}
                        weight={"medium"}
                      >
                        {"View details"}
                      </Text>
                      <Icon size={"12px"} source={"nav-right"} yPos={"2px"} />
                    </Anchor>
                  </Cell>
                );
              })}
            </TableHead>

            <TableBody>
              <tr>
                <RowHead label={"Description"} />
                {instruments.map((instrument) => {
                  const { description } = instrument;
                  return (
                    <Cell key={description}>
                      <Text as={"p"} size={"small"}>
                        {description}
                      </Text>
                    </Cell>
                  );
                })}
              </tr>
              <tr>
                <RowHead label={"Assay Compatibility"} />
                {instruments.map((instrument) => {
                  const { assays = [], instrument: slug } = instrument;
                  const { name } = slug;
                  return (
                    <Cell key={name}>
                      {assays ? (
                        <Compatibility assays={assays} instrument={name} />
                      ) : null}
                    </Cell>
                  );
                })}
              </tr>
              <tr>
                <RowHead
                  description={"Throughput determined by assay type"}
                  label={"Cell Throughput"}
                />
                {instruments.map((instrument) => {
                  const { instrument: slug, throughput } = instrument;

                  return (
                    <Cell key={slug.name}>
                      {throughput ? <Throughput versions={throughput} /> : null}
                    </Cell>
                  );
                })}
              </tr>
              <tr>
                <RowHead label={"Workflow Type"} />
                {instruments.map((instrument) => {
                  const { workflowType } = instrument;
                  return (
                    <Cell key={workflowType}>
                      <Text as={"p"} size={"small"}>
                        {workflowType}
                      </Text>
                    </Cell>
                  );
                })}
              </tr>
              <tr>
                <RowHead label={"Workflow Steps"} />
                {instruments.map((instrument) => {
                  const { workflowSteps } = instrument;
                  return (
                    <>
                      {workflowSteps && workflowSteps.length ? (
                        <CellList key={workflowSteps[0]}>
                          {workflowSteps.map((step) => {
                            return (
                              <Text
                                as={"li"}
                                css={css`
                                  margin-bottom: 4px;
                                `}
                                key={step}
                                size={"small"}
                              >
                                {step}
                              </Text>
                            );
                          })}
                        </CellList>
                      ) : null}
                    </>
                  );
                })}
              </tr>
              <tr>
                <RowHead label={"Connectivity"} />
                {instruments.map((instrument) => {
                  const { connectivity = {}, instrument: slug } = instrument;
                  const { label, note } = connectivity;
                  return (
                    <Cell key={slug.name}>
                      {label ? (
                        <Text
                          as={"p"}
                          css={css`
                            margin-bottom: 4px;
                          `}
                          size={"small"}
                        >
                          {label}
                        </Text>
                      ) : null}

                      {note ? (
                        <Text as={"p"} color={"midgray"} size={"xsmall"}>
                          {note}
                        </Text>
                      ) : null}
                    </Cell>
                  );
                })}
              </tr>
            </TableBody>
          </Table>
          <Text
            as={"p"}
            css={css`
              padding-top: 24px;
            `}
            size={"small"}
          >
            {footnote}
          </Text>
        </>
      ) : null}
    </div>
  );
};

export default InstrumentComparison;
