import {
  borderRadiusMedium,
  borderStandard,
  colorBlueDark,
  colorBlueLightest,
  layoutWidth1200,
  mediaPhoneOnly,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { Fragment } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Text from "../../Text";

interface Props {
  asideTitle?: string;
  materials?: {
    description?: string;
    items?: Array<{
      material?: string;
    }>;
    links?: Array<{
      label: string;
      name: string;
      url: string;
    }>;
    title?: string;
  };
  productsLists?: {
    items?: Array<{
      id?: string;
      name?: string;
      url: string;
    }>;
    title?: string;
  };
  resourcesLinks?: {
    description?: string;
    links?: Array<{
      label: string;
      name: string;
      url: string;
    }>;
    title?: string;
  };
  software?: {
    items?: Array<{
      name?: string;
      url: string;
      version?: string;
    }>;
    title?: string;
  };
  title?: string;
}

const Requirements: FunctionComponent<Props> = ({
  asideTitle,
  materials,
  productsLists,
  software,
  resourcesLinks,
  title,
}) => {
  return (
    <div
      className={"Requirements"}
      css={css`
        max-width: ${layoutWidth1200};
        padding: 4rem 1rem;

        @media (max-width: ${mediaPhoneOnly}) {
          margin-bottom: 6rem;
        }
      `}
    >
      <div>
        <Heading
          as={"h3"}
          css={css`
            margin-bottom: 2rem;
          `}
          responsive={true}
          size={"xlarge"}
        >
          {title}
        </Heading>
      </div>

      <div
        css={css`
          display: flex;
          justify-content: space-between;

          @media (max-width: ${mediaPhoneOnly}) {
            display: block;
          }
        `}
      >
        <div
          css={css`
            width: 51%;

            @media (max-width: ${mediaPhoneOnly}) {
              margin-bottom: 2rem;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              border-top: ${borderStandard};
              padding-bottom: 0.5rem;
              padding-top: 2rem;
            `}
          >
            <Heading
              as={"h4"}
              css={css`
                margin-bottom: 2rem;
              `}
              responsive={true}
              size={"medium"}
            >
              {productsLists?.title}
            </Heading>

            <ul
              css={css`
                list-style: none;
                padding: 0;
              `}
            >
              {productsLists?.items?.map((item) => {
                const { id, name, url } = item;
                return (
                  <li
                    css={css`
                      margin-bottom: 1.5rem;
                    `}
                    key={(id || "") + name}
                  >
                    <Text as={"span"} size={"small"} weight={"medium"}>
                      <Anchor href={url}>{name}</Anchor>
                    </Text>
                    <Text as={"p"} color={"midgray"} size={"small"}>
                      {id}
                    </Text>
                  </li>
                );
              })}
            </ul>
          </div>

          {software?.items?.length ? (
            <div
              css={css`
                border-top: ${borderStandard};
                padding-top: 1.5rem;
              `}
            >
              <Heading
                as={"h4"}
                css={css`
                  margin-bottom: 1rem;
                `}
                responsive={true}
                size={"small"}
              >
                {software?.title}
              </Heading>

              <ul
                css={css`
                  list-style: none;
                  padding: 0;
                `}
              >
                {software?.items?.map((item) => {
                  const { name, url, version } = item;
                  return (
                    <li
                      css={css`
                        margin-bottom: 1.5rem;
                      `}
                      key={name}
                    >
                      <Text as={"span"} size={"small"} weight={"medium"}>
                        <Anchor href={url}>{name}</Anchor>
                      </Text>
                      <Text as={"p"} color={"midgray"} size={"small"}>
                        {version}
                      </Text>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>

        <div
          css={css`
            background-color: ${colorBlueLightest};
            border-radius: ${borderRadiusMedium};
            width: 30%;

            @media (max-width: ${mediaTabletLandscape}) {
              width: 40%;
            }

            @media (max-width: ${mediaPhoneOnly}) {
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              border-bottom: ${borderStandard};
              padding: 2rem;
            `}
          >
            {asideTitle ? (
              <Heading
                as={"h4"}
                css={css`
                  margin-bottom: 1rem;
                `}
                responsive={true}
                size={"small"}
              >
                {asideTitle}
              </Heading>
            ) : null}

            {materials?.title ? (
              <Heading
                as={"h4"}
                color={"midgray"}
                css={css`
                  margin-bottom: 1rem;
                `}
                responsive={true}
                size={"small"}
              >
                {materials?.title}
              </Heading>
            ) : null}

            {materials?.description ? (
              <Text
                as={"p"}
                color={"midgray"}
                css={css`
                  margin-bottom: 1.25rem;
                `}
                size={"small"}
              >
                {materials?.description}
              </Text>
            ) : null}

            {materials?.items ? (
              <ul
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 1rem;
                  margin: 0;
                  padding-left: 1rem;
                `}
              >
                {materials?.items?.map((item) => {
                  const { material } = item || {};
                  return (
                    <li key={material}>
                      <Text as={"span"} color={"midgray"} size={"small"}>
                        {material}
                      </Text>
                    </li>
                  );
                })}
              </ul>
            ) : null}

            {materials?.links?.map((link) => {
              const { name, url } = link;
              return (
                <Text
                  as={"div"}
                  color={"blue"}
                  css={css`
                    margin-bottom: 0.5rem;
                  `}
                  key={url}
                  size={"small"}
                  weight={"medium"}
                >
                  <Anchor
                    color={"inherit"}
                    hoverColor={colorBlueDark}
                    href={url}
                  >
                    {name}
                    <Icon
                      size={"12px"}
                      source={"nav-right"}
                      xPos={"16px"}
                      yPos={"2px"}
                    />
                  </Anchor>
                </Text>
              );
            })}
          </div>

          <div
            css={css`
              padding: 2rem 0.5rem 2rem 2rem;
            `}
          >
            {resourcesLinks?.title ? (
              <Heading
                as={"h4"}
                color={"midgray"}
                responsive={true}
                size={"small"}
              >
                {resourcesLinks?.title}
              </Heading>
            ) : null}
            <Text
              as={"p"}
              color={"midgray"}
              css={css`
                margin-bottom: 1.5rem;
              `}
              responsive={true}
              size={"small"}
            >
              {resourcesLinks?.description}
            </Text>

            {resourcesLinks?.links?.map((link) => {
              const { label, name, url } = link;
              return (
                <Fragment key={label}>
                  {label ? (
                    <Text
                      as={"p"}
                      color={"midgray"}
                      css={css`
                        margin-bottom: 0.5rem;
                        margin-top: 1.5rem;
                      `}
                      responsive={true}
                      size={"small"}
                    >
                      {label}
                    </Text>
                  ) : null}

                  <Text
                    as={"div"}
                    color={"blue"}
                    css={css`
                      margin-bottom: 0.5rem;
                    `}
                    size={"small"}
                    weight={"medium"}
                  >
                    <Anchor
                      color={"inherit"}
                      hoverColor={colorBlueDark}
                      href={url}
                    >
                      {name}
                      <Icon
                        size={"12px"}
                        source={"nav-right"}
                        xPos={"16px"}
                        yPos={"2px"}
                      />
                    </Anchor>
                  </Text>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Requirements;
