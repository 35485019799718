import PositiveNarrative from "./Cards/PositiveNarrative";
import PositiveList from "./Cards/PositiveList";
import PositiveLink from "./Cards/PositiveLink";
import PositiveHeader from "./Cards/PositiveHeader";
import type { Positive } from "./ClarificationCards";

export const getPositiveCard = (positive: Positive) => {
  const { details, gif, image, label, link, list, subtitle, summary, title } =
    positive;
  switch (positive.type) {
    case "positiveNarrative":
      return (
        <PositiveNarrative
          label={label}
          title={title}
          summary={summary}
          subtitle={subtitle}
          details={details}
        />
      );
    case "positiveList":
      return (
        <PositiveList
          label={label}
          title={title}
          summary={summary}
          list={list}
        />
      );
    case "positiveLink":
      return (
        <PositiveLink
          image={image}
          label={label}
          title={title}
          summary={summary}
          link={link}
          gif={gif}
        />
      );
    case "positiveHeader":
      return (
        <PositiveHeader
          image={image}
          label={label}
          title={title}
          summary={summary}
          link={link}
        />
      );
    default:
      return null;
  }
};
