import type { LinkItem } from "@10xdev/cms/types";
import {
  borderStandard,
  colorBlueDark,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Link from "next/link";
import type { FunctionComponent } from "react";

import Anchor from "../../../Anchor";
import { buttonCss } from "../../../Button";
import Heading from "../../../Heading";
import Icon from "../../../Icon";
import Text from "../../../Text";
import type { ButtonType } from "../types";

interface Props {
  button?: ButtonType;
  description?: string;
  link?: LinkItem;
  title?: string;
}

const Header: FunctionComponent<Props> = ({
  button,
  description,
  link,
  title,
}) => {
  const { icon, label, url } = button || {};

  const StyledAnchor = styled("a")([buttonCss, { width: "240px" }]);

  return (
    <div
      css={css`
        border-bottom: ${borderStandard};
        padding-bottom: 2rem;

        @media (min-width: ${mediaTabletLandscape}) {
          align-items: center;
        }
      `}
    >
      {title ? (
        <Heading
          as={"h5"}
          css={css`
            margin-bottom: 1.5rem;
          `}
          size={"large"}
        >
          {title}
        </Heading>
      ) : null}

      <div
        css={css`
          align-items: center;
          flex-basis: 600px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 3rem;

          @media (min-width: ${mediaTabletLandscape}) {
            margin-bottom: 0;
          }
        `}
      >
        <div
          css={css`
            flex-basis: 600px;
          `}
        >
          {description ? (
            <Text
              as={"p"}
              color={"midgray"}
              css={css`
                margin-bottom: 1rem;
              `}
              size={"medium"}
            >
              {description}
            </Text>
          ) : null}
        </div>

        {label && url ? (
          <Link href={url} legacyBehavior passHref>
            <StyledAnchor>
              {icon ? (
                <Icon
                  color={"inherit"}
                  css={{ marginRight: "10px" }}
                  size={"14px"}
                  source={icon}
                  yPos={"1px"}
                />
              ) : null}
              <Text
                as={"div"}
                color={"inherit"}
                size={"small"}
                weight={"medium"}
              >
                {label}
              </Text>
            </StyledAnchor>
          </Link>
        ) : null}
      </div>

      {link?.url ? (
        <Text
          as={"span"}
          color={"blue"}
          css={css`
            white-space: nowrap;
          `}
          size={"small"}
          weight={"semibold"}
        >
          <Anchor
            color={"inherit"}
            hoverColor={colorBlueDark}
            href={link.url}
            target={link.target}
          >
            {link.label}
            {link.type ? (
              <Icon
                size={"12px"}
                source={link.type}
                xPos={"16px"}
                yPos={"1px"}
              />
            ) : null}
          </Anchor>
        </Text>
      ) : null}
    </div>
  );
};

export default Header;
