import AssayCards from "./AssayCards";
import ClarificationCards from "./ClarificationCards";
import CTA from "./CTA";
import Introduction from "./Introduction";
import Masthead from "./Masthead";
import ResearchCards from "./ResearchCards";
import ResourceCards from "./ResourceCards";
import Table from "./Table";

export {
  AssayCards,
  ClarificationCards,
  CTA,
  Introduction,
  Masthead,
  ResearchCards,
  ResourceCards,
  Table,
};

export const NewToSCBlocks = {
  newToSCAssayCards: AssayCards,
  newToSCClarificationCards: ClarificationCards,
  newToSCCTA: CTA,
  newToSCIntroduction: Introduction,
  newToSCMasthead: Masthead,
  newToSCResearchCards: ResearchCards,
  newToSCResourceCards: ResourceCards,
  newToSCTable: Table,
};
