import type { Positive } from "../ClarificationCards";
import Card from "./Card";
import CardHeader from "./CardHeader";
import Text from "../../../../Text";
import Image from "../../../../Image";
import Link from "../../Link";

const PositiveHeader = ({
  label,
  title,
  summary,
  image,
  link,
}: Omit<Positive, "type">) => {
  return (
    <Card>
      <CardHeader title={title} label={label} />
      {image && <Image src={image.src} alt={image?.alt} />}
      <Text as={"p"} size={"small"}>
        {summary}
      </Text>
      {link?.url && <Link label={link.label} url={link.url} />}
    </Card>
  );
};

export default PositiveHeader;
