import { css } from "@emotion/react";
import type { Props as Image } from "../../../Image";
import type { Link } from "../types";

import Grid from "../../../Grid";
import Flex from "../../../Flex";
import Text from "../../../Text";
import Icon from "../../../Icon";
import { borderRadiusMedium, colorGrayLightest } from "@10xdev/design-tokens";
import { getPositiveCard } from "./utils";
import Header from "../Header";
import type { Props as HeaderInterface } from "../Header/Header";

type Negative = {
  title: string;
  label: string;
};

export type ListItem = {
  label: string;
  image: Image;
};

type List = {
  items: ListItem[];
  title: string;
};

type Gif = {
  src: string;
};

export interface Positive {
  details?: string;
  gif?: Gif;
  image?: Image;
  label: string;
  link?: Link;
  list?: List;
  subtitle?: string;
  summary: string;
  title: string;
  type:
    | "positiveHeader"
    | "positiveLink"
    | "positiveList"
    | "positiveNarrative";
}

type Perception = {
  negative: Negative;
  positive: Positive[];
};

interface Props {
  header: HeaderInterface;
  perceptions: Perception[];
}

const ClarificationCards = ({ header, perceptions }: Props) => {
  return (
    <Grid gap={"2rem"}>
      <Header {...header} />
      <Grid gap={"1rem"}>
        {perceptions.map((perception) => {
          const { negative, positive } = perception;
          return (
            <Grid
              css={css`
                background: ${colorGrayLightest};
                border-radius: ${borderRadiusMedium};
              `}
              gap={"1rem"}
              gridTemplateColumns={{ base: "1fr", tabletLandscape: "1fr 3fr" }}
              padding={"2rem"}
            >
              <Grid alignContent={"start"} padding={"1.5rem 0 0"} gap={"1rem"}>
                <Text
                  as={"h4"}
                  color={"maroon"}
                  size={"medium"}
                  weight={"semibold"}
                >
                  {negative.label}
                </Text>
                <Flex alignItems={"start"} gap={".5rem"}>
                  {negative.label && (
                    <Icon
                      css={css`
                        flex-shrink: 0;
                      `}
                      source={"close"}
                      color={"maroon"}
                      size={"10px"}
                      yPos={"8px"}
                    />
                  )}
                  <Text as={"p"} size={"medium"} weight={"semibold"}>
                    {negative.title}
                  </Text>
                </Flex>
              </Grid>

              <Grid gap={"1rem"}>
                {positive.map((positive) => {
                  return getPositiveCard(positive);
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default ClarificationCards;
