import { css } from "@emotion/react";
import Grid from "../../../Grid";
import { IconSource } from "../../../Icon/types";
import type { Props as Header } from "../../../ProductPage/SectionHeader";
import SectionHeader from "../../../ProductPage/SectionHeader";
import Categories from "./Categories";
import Description from "./Description";
import List from "./List";

export type ListProps = {
  icon?: IconSource;
  label: string;
  link?: string;
};

export type CategoryProps = {
  title: string;
  list: {
    icon?: IconSource;
    label: string;
    link?: string;
  }[];
};

export type Link = {
  label: string;
  url: string;
  icon: IconSource;
};

type Specification = {
  title: string;
  list?: ListProps[];
  link?: Link;
  categories?: CategoryProps[];
  description?: string;
};

export interface Props {
  header?: Header;
  specifications: Specification[];
}

const Specifications = ({ header, specifications }: Props) => {
  return (
    <Grid
      css={css`
        max-width: 1200px;
        width: 100%;
      `}
      gap={{ base: "2rem", tabletPortrait: "3.5rem" }}
      padding={{ base: "0 1rem 2rem", tabletPortrait: "0 1rem 4.5rem" }}
    >
      {header ? <SectionHeader {...header} /> : null}
      <Grid>
        {specifications.map((spec) => {
          const { title, list, link, categories, description } = spec;
          return (
            <>
              {list?.length ? (
                <List list={list} title={title} link={link} />
              ) : null}
              {categories?.length ? (
                <Categories categories={categories} title={title} link={link} />
              ) : null}
              {description ? (
                <Description description={description} title={title} />
              ) : null}
            </>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Specifications;
