import { css } from "@emotion/react";
import type { Assay } from "./AssayCards";
import Grid from "../../../Grid";
import Image from "../../../Image";
import Text from "../../../Text";
import Anchor from "../../../Anchor";
import Icon from "../../../Icon";
import { borderRadiusMedium, borderStandard } from "@10xdev/design-tokens";

const Card = ({ title, image, heading, list, products, price }: Assay) => {
  return (
    <Grid
      alignContent={"start"}
      css={css`
        border: ${borderStandard};
        border-radius: ${borderRadiusMedium};
      `}
      padding={"1.5rem"}
    >
      <Grid
        alignItems={"center"}
        css={css`
          border-bottom: ${borderStandard};
        `}
        gridTemplateColumns={"40px 1fr"}
        gap={".5rem"}
        padding={"0 0 1rem"}
      >
        <Image
          css={css`
            border-radius: ${borderRadiusMedium};
          `}
          src={image.src}
          alt={image.alt}
        />
        <Text as={"h4"} size={"large"} weight={"semibold"}>
          {title}
        </Text>
      </Grid>
      <Grid
        alignContent={"start"}
        css={css`
          min-height: 200px;
          border-bottom: ${borderStandard};
        `}
        gap={"1rem"}
        padding={"1.5rem 0"}
      >
        <Text as={"h5"} size={"small"} color={"base"} weight={"semibold"}>
          {heading}
        </Text>
        <ul
          css={css`
            list-style: none;
            margin: 0;
            padding: 0;
            display: grid;
            gap: 1rem;
          `}
        >
          {list.map((item, index) => (
            <Text as={"li"} key={index} size={"small"}>
              <Grid
                alignItems={"start"}
                gridTemplateColumns={"20px 1fr"}
                gap={"1rem"}
              >
                <Icon
                  source={"gradient-blue-check"}
                  size={"20px"}
                  yPos={"4px"}
                />
                {item}
              </Grid>
            </Text>
          ))}
        </ul>
      </Grid>
      <Grid
        alignContent={"start"}
        css={css`
          min-height: 90px;
          border-bottom: ${borderStandard};
        `}
        padding={"1rem 0"}
        gap={"0.5rem"}
      >
        <Text as={"h5"} size={"small"} color={"base"} weight={"semibold"}>
          {products.label}
        </Text>
        <ul
          css={css`
            list-style: none;
            margin: 0;
            padding: 0;
            display: grid;
            gap: 0.5rem;
          `}
        >
          {products.links.map((item) => (
            <li>
              <Anchor href={item.url} key={item.label}>
                <Text
                  as={"span"}
                  color={"inherit"}
                  size={"xsmall"}
                  weight={"medium"}
                >
                  {item.label}
                </Text>
              </Anchor>
            </li>
          ))}
        </ul>
      </Grid>
      <Grid padding={"1rem 0 0"} gap={"0.25rem"}>
        <Text as={"h5"} size={"small"} color={"base"} weight={"semibold"}>
          {price.label}
        </Text>
        <Text as={"p"} size={"small"}>
          {price.summary}
        </Text>
      </Grid>
    </Grid>
  );
};

export default Card;
