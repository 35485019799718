import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Text from "../../Text";

interface Props {
  level: {
    cells: string;
    label: string;
    note?: string;
  };
}

const ThroughputLevel: FunctionComponent<Props> = ({ level }) => {
  const { label, note, cells } = level;
  return (
    <div
      css={css`
        :first-of-type {
          margin-bottom: 1rem;
        }
      `}
    >
      <Text
        as={"p"}
        color={"base"}
        css={css`
          margin-bottom: 4px;
        `}
        size={"small"}
      >
        {label}
      </Text>
      <Text
        as={"p"}
        color={"midgray"}
        css={css`
          margin-bottom: ${note ? "8px" : 0};
        `}
        size={"xsmall"}
      >
        {cells}
      </Text>
      {level.note ? (
        <Text as={"p"} color={"midgray"} size={"xsmall"}>
          {note}
        </Text>
      ) : null}
    </div>
  );
};

export default ThroughputLevel;
