import type { Link } from "../types";
import type { Props as Image } from "../../../Image";
import Card from "./Card";
import Grid from "../../../Grid";
import Header from "../Header";
import type { Props as HeaderInterface } from "../Header/Header";
import Cta from "../CTA";
import type { Props as CtaInterface } from "../CTA/CTA";

export type Assay = {
  title: string;
  image: Image;
  heading: string;
  list: string[];
  products: {
    label: string;
    links: Link[];
  };
  price: {
    label: string;
    summary: string;
  };
};

interface Props {
  header: HeaderInterface;
  assays: Assay[];
  cta: CtaInterface;
}

const AssayCards = ({ header, assays, cta }: Props) => {
  return (
    <Grid gap={"2rem"}>
      <Header {...header} />
      <Grid
        gridTemplateColumns={"repeat(auto-fit, minmax(320px, 1fr))"}
        gap={"1.5rem"}
      >
        {assays.map((assay) => {
          return <Card {...assay} />;
        })}
      </Grid>
      <Cta {...cta} />
    </Grid>
  );
};

export default AssayCards;
