import {
  colorSteelMedium,
  mediaTabletLandscape,
  spacing16,
  spacing24,
  spacing48,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Text from "../../Text";
import type { SectionSpacings } from "../Section";
import Section from "../Section";

interface Props {
  body: string;
  bottomSpacing?: SectionSpacings;
  links: { title: string; url: string }[];
  title: string;
  topSpacing?: SectionSpacings;
}

const SectionWithLink = ({
  body,
  bottomSpacing,
  links,
  title,
  topSpacing,
}: Props) => {
  return (
    <Section
      bottomSpacing={bottomSpacing}
      css={css`
        border-bottom: 1px solid ${colorSteelMedium};
        border-top: 1px solid ${colorSteelMedium};
        padding: ${spacing24} 0;

        @media (min-width: ${mediaTabletLandscape}) {
          padding: ${spacing48} 0;
        }
      `}
      mode={"dark"}
      slash={false}
      topSpacing={topSpacing}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing24};

          @media (min-width: ${mediaTabletLandscape}) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            flex: 1;
          `}
        >
          <Heading
            as={"h3"}
            color={"white"}
            css={css`
              margin: 0;
            `}
            responsive={true}
            size={"xlarge"}
          >
            {title}
          </Heading>
        </div>
        <div
          css={css`
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: ${spacing24};
          `}
        >
          <Text as={"p"} color={"white"} size={"small"}>
            {body}
          </Text>
          {links.map((link) => (
            <Anchor href={link.url} key={link.url}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: ${spacing16};
                `}
              >
                <Text
                  as={"span"}
                  color={"white"}
                  size={"small"}
                  weight={"semibold"}
                >
                  {link.title}
                </Text>
                <Icon
                  color={"white"}
                  role={"presentation"}
                  size={"11px"}
                  source={"nav-right"}
                  yPos={"1px"}
                />
              </div>
            </Anchor>
          ))}
        </div>
      </div>
    </Section>
  );
};

export default SectionWithLink;
