import { css } from "@emotion/react";
import Text from "../../../../Text";
import Flex from "../../../../Flex";
import Icon from "../../../../Icon";
import { TextSize } from "@10xdev/types";

interface Props {
  icon?: boolean;
  label?: string;
  size?: TextSize;
  title: string;
}

const CardHeader = ({ title, label, size = "medium" }: Props) => {
  return (
    <>
      {label ? (
        <Text as={"h4"} color={"success"} size={"medium"} weight={"semibold"}>
          {label}
        </Text>
      ) : null}
      <Flex alignItems={"start"} gap={".5rem"}>
        {label && (
          <Icon
            css={css`
              flex-shrink: 0;
            `}
            source={"checkmark"}
            color={"success"}
            size={"13px"}
            yPos={"7px"}
          />
        )}
        <Text as={"p"} size={size} weight={"semibold"}>
          {title}
        </Text>
      </Flex>
    </>
  );
};

export default CardHeader;
