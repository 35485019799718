import { useEffect, useRef, useState } from "react";

export const useReel = (itemWidth: number) => {
  const [current, setCurrent] = useState(0);
  const [offset, setOffset] = useState(0);
  const [showToggle, setShowToggle] = useState({ next: true, prev: false });

  const reelRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (reelRef.current) {
      setOffset(reelRef.current.scrollLeft);
    }
  };

  const handleToggleLeft = () => {
    if (reelRef.current) {
      reelRef.current.scrollTo({
        behavior: "smooth",
        left: (current - 1) * itemWidth,
      });
    }
  };

  const handleToggleRight = () => {
    if (reelRef.current) {
      reelRef.current.scrollTo({
        behavior: "smooth",
        left: (current + 1) * itemWidth,
      });
    }
  };

  // set the current item based on scroll position
  useEffect(() => {
    const curr = Math.round(offset / itemWidth);
    setCurrent(curr);
  }, [offset, itemWidth]);

  // show toggles based on scroll position
  useEffect(() => {
    if (reelRef.current) {
      const scrollMax =
        reelRef.current.scrollWidth - reelRef.current.offsetWidth;
      setShowToggle({
        next: offset < scrollMax,
        prev: current >= 1,
      });
    }
  }, [offset, current]);

  return {
    handleScroll,
    handleToggleLeft,
    handleToggleRight,
    reelRef,
    showToggle,
  };
};

export default useReel;
