import { css } from "@emotion/react";

import Grid from "../../Grid";
import Text from "../../Text";
import type { CTA } from "../types";
import Link from "../Link";
import { mediaPhoneOnly } from "@10xdev/design-tokens";

interface Props {
  title: string;
  cta: CTA;
}

const Dataset = ({ title, cta }: Props) => {
  return (
    <Grid
      alignItems={"start"}
      css={css`
        padding: 0.75rem 0;
      `}
      gap={{ base: "1rem", tabletPortrait: "5rem" }}
      gridTemplateColumns={{ base: "1fr", tabletPortrait: "45% auto" }}
    >
      <Text as={"h4"} size={"small"} weight={"semibold"}>
        {title}
      </Text>

      {cta ? (
        <Link
          css={css`
            @media (min-width: ${mediaPhoneOnly}) {
              justify-self: end;
            }
          `}
          label={cta.label}
          url={cta.url}
          icon={cta.icon}
        />
      ) : null}
    </Grid>
  );
};

export default Dataset;
