import { css } from "@emotion/react";

import Text from "../../../Text";

interface Props {
  content: string[];
}

const Cell = ({ content }: Props) => {
  return (
    <td
      css={css`
        vertical-align: top;
      `}
    >
      <div
        css={css`
          display: grid;
          gap: 1rem;
          padding: 1rem 0.75rem;
        `}
      >
        {content.map((cell) => (
          <Text as={"p"} size={"xsmall"}>
            {cell}
          </Text>
        ))}
      </div>
    </td>
  );
};

export default Cell;
