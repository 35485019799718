import type { ListItem as ListItemType } from "../ClarificationCards";
import Grid from "../../../../Grid";
import Image from "../../../../Image";
import Text from "../../../../Text";

const ListItem = ({ label, image }: ListItemType) => {
  return (
    <Grid alignItems={"center"} gap={"1rem"} gridTemplateColumns={"62px 1fr"}>
      <Image src={image.src} alt={image.alt} />
      <Text as={"p"} size={"small"}>
        {label}
      </Text>
    </Grid>
  );
};

export default ListItem;
