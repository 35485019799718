import { css } from "@emotion/react";
import type { Link } from "../types";
import Grid from "../../../Grid";
import Card from "./Card";
import Header from "../Header";
import type { Props as HeaderInterface } from "../Header/Header";

export type Group = {
  title: string;
  list: Link[];
};

interface Props {
  header: HeaderInterface;
  groups: Group[];
}

const ResourceCards = ({ header, groups }: Props) => {
  return (
    <Grid
      css={css`
        width: 100%;
      `}
      gap={"2rem"}
    >
      <Header {...header} />
      <Grid
        gridTemplateColumns={"repeat(auto-fit, minmax(320px, 1fr))"}
        gap={"1.5rem"}
      >
        {groups.map((group) => {
          return <Card {...group} />;
        })}
      </Grid>
    </Grid>
  );
};

export default ResourceCards;
