import {
  borderRadiusMedium,
  colorWarningBase,
  spacing8,
  spacing12,
  spacing16,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import type { colors } from "../../Text";
import Text from "../../Text";
import type { ComponentColorMode, Mode } from "../types";

interface Props {
  mode?: Mode;
  text: string;
  title?: string;
  width?: string;
}
interface ThemeProps {
  color: keyof typeof colors;
}
const themes: ComponentColorMode<ThemeProps> = {
  dark: {
    color: "white",
  },
  light: {
    color: "steelDarkest",
  },
};

const Callout = ({ mode = "dark", text, title, width = "100%" }: Props) => {
  const theme = mode === "dark" ? themes.dark : themes.light;
  return (
    <div
      css={css`
        background: rgba(242, 153, 74, 0.1);
        border-radius: ${borderRadiusMedium};
        border: 1px solid ${colorWarningBase};
        display: flex;
        flex-direction: column;
        gap: ${spacing8};
        justify-content: flex-start;
        max-width: ${width};
        padding: ${spacing12} ${spacing16};
      `}
    >
      {title ? (
        <Text as={"div"} color={theme.color} size={"medium"} weight={"medium"}>
          {title}
        </Text>
      ) : null}
      <Text as={"div"} color={theme.color} size={"medium"} weight={"regular"}>
        {text}
      </Text>
    </div>
  );
};

export default Callout;
