import type { FunctionComponent, ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

const TableBody: FunctionComponent<Props> = ({ children }) => {
  return <tbody>{children}</tbody>;
};

export default TableBody;
