import type { Positive } from "../ClarificationCards";
import Card from "./Card";
import CardHeader from "./CardHeader";
import Text from "../../../../Text";

const PositiveNarrative = ({
  label,
  title,
  summary,
  subtitle,
  details,
}: Omit<Positive, "type">) => {
  return (
    <Card>
      <CardHeader title={title} label={label} />
      <Text as={"p"} size={"small"}>
        {summary}
      </Text>
      <Text as={"p"} size={"small"} weight={"medium"}>
        {subtitle}
      </Text>
      <Text as={"p"} size={"small"}>
        {details}
      </Text>
    </Card>
  );
};

export default PositiveNarrative;
