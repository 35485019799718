import { css } from "@emotion/react";

import Grid from "../../../Grid";
import Text from "../../../Text";
import WistiaVideo from "../../../WistiaVideo";
import type { Props as HeaderInterface } from "../Header/Header";

interface Props {
  header: HeaderInterface;
  video?: {
    wistiaId: string;
    caption: string;
  };
}

const Introduction = ({ header, video }: Props) => {
  const { title, summary, emphasis } = header;
  return (
    <Grid gap={"1rem"}>
      {title && (
        <Grid
          gridTemplateColumns={{
            base: "1fr",
            tabletLandscape: "1fr 400px",
          }}
          gap={{ base: "2rem", tabletLandscape: "4.5rem" }}
        >
          <Text
            as={"h3"}
            size={title.size || "xlarge"}
            color={title.color || "base"}
            weight={"semibold"}
          >
            {title.content}
          </Text>
        </Grid>
      )}

      <Grid
        gridTemplateColumns={{
          base: "1fr",
          tabletLandscape: "1fr 400px",
        }}
        gap={{ base: "2rem", tabletLandscape: "4.5rem" }}
        alignItems={"start"}
      >
        <Grid gap={"1rem"}>
          {summary && (
            <Text
              as={"p"}
              size={summary.size || "small"}
              color={summary.color || "base"}
            >
              {summary.content}
            </Text>
          )}
          {emphasis && (
            <Text
              as={"p"}
              css={css`
                font-style: ${emphasis.style === "italic"
                  ? "italic"
                  : "normal"};
              `}
              size={emphasis.size || "small"}
              color={emphasis.color || "base"}
              weight={emphasis.style === "italic" ? "regular" : "semibold"}
            >
              {emphasis.content}
            </Text>
          )}
        </Grid>
        {video && (
          <Grid gap={"1rem"}>
            <WistiaVideo wistiaID={video?.wistiaId} />
            <Text
              as={"p"}
              css={css`
                text-align: center;
              `}
              size={"small"}
              color={"base"}
            >
              {video.caption}
            </Text>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Introduction;
