import { css } from "@emotion/react";
import {
  borderRadiusMedium,
  borderStandard,
  colorGrayLightest,
} from "@10xdev/design-tokens";

import type { Application } from "./ResearchCards";

import Anchor from "../../../Anchor";
import Grid from "../../../Grid";
import Image from "../../../Image";
import Text from "../../../Text";
import Icon from "../../../Icon";

const Card = ({ image, title, summary, publication }: Application) => {
  return (
    <Grid
      css={css`
        background: ${colorGrayLightest};
        border-radius: ${borderRadiusMedium};
      `}
      padding={"1.5rem 2rem"}
      gridTemplateColumns={{ base: "1fr", tabletPortrait: "1.3fr 1fr" }}
      alignItems={"center"}
    >
      <Grid
        alignContent={"start"}
        gap={"2rem"}
        gridTemplateColumns={{ base: "1fr", tabletPortrait: "96px 1fr" }}
        padding={"0 2rem 0 0"}
      >
        <Image
          css={css`
            border-radius: ${borderRadiusMedium};
          `}
          src={image.src}
          alt={image.alt}
          width={"96px"}
        />
        <Grid
          alignContent={"start"}
          gap={".5rem"}
          padding={{ phoneOnly: "0 0 2rem 0", tabletPortrait: 0 }}
        >
          <Text as={"h4"} size={"medium"} weight={"semibold"}>
            {title}
          </Text>
          <Text as={"p"} size={"xsmall"}>
            {summary}
          </Text>
        </Grid>
      </Grid>

      <Grid
        css={css`
          border-top: ${borderStandard};
          @media (min-width: 600px) {
            border-top: none;
            border-left: ${borderStandard};
          }
        `}
        gap={".5rem"}
        padding={{ base: "2rem 0 0 0", tabletPortrait: "0 0 0 2rem" }}
      >
        <Grid
          gap={".5rem"}
          alignItems={"center"}
          gridTemplateColumns={"1.5rem 1fr"}
        >
          <Icon color={"transparent"} source={"publications"} size={"24px"} />
          <Text as={"p"} size={"xsmall"} weight={"semibold"}>
            {publication.label}
          </Text>
        </Grid>
        <Anchor href={publication.url}>
          <Text as={"p"} color={"inherit"} size={"xsmall"} weight={"semibold"}>
            {publication.title}
          </Text>
        </Anchor>
        <Text as={"p"} size={"xsmall"}>
          {publication.summary}
        </Text>
      </Grid>
    </Grid>
  );
};

export default Card;
