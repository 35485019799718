import { css } from "@emotion/react";
import { mediaPhoneOnly } from "@10xdev/design-tokens";

import Grid from "../../Grid";
import Text from "../../Text";
import type { CTA } from "../types";
import Link from "../Link";

interface Props {
  title: string;
  author?: string;
  journal?: string;
  date?: string;
  cta: CTA;
}

const Publication = ({ title, author, journal, date, cta }: Props) => {
  return (
    <Grid
      css={css`
        padding: 0.75rem 0;
      `}
      gap={"1rem 4.67%"}
      gridTemplateColumns={{ base: "1fr", tabletLandscape: "46.2% 30% 1fr" }}
    >
      <Text as={"h4"} size={"small"} weight={"semibold"}>
        {title}
      </Text>
      <div>
        <Text as={"p"} size={"small"} weight={"regular"}>
          {`${journal}, ${date}`}
        </Text>
        <Text as={"p"} size={"small"} weight={"regular"}>
          {author}
        </Text>
      </div>

      {cta ? (
        <Link
          css={css`
            @media (min-width: ${mediaPhoneOnly}) {
              justify-self: end;
            }
          `}
          label={cta.label}
          url={cta.url}
          icon={cta.icon}
        />
      ) : null}
    </Grid>
  );
};

export default Publication;
