import { borderStandard } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

interface Props {
  columns: number;
}
const ColGroup: FunctionComponent<Props> = ({ columns }) => {
  const items = [...Array(columns).keys()];
  return (
    <colgroup
      css={css`
        > col {
          border-right: ${borderStandard};
        }
      `}
    >
      {items.map((item) => (
        <col key={`col-${item}`}></col>
      ))}
    </colgroup>
  );
};

export default ColGroup;
