import {
  mediaTabletLandscape,
  spacing24,
  spacing32,
  spacing64,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ComponentProps } from "react";

import Heading from "../Heading";
import type { SectionSpacings } from "./Section";
import Section from "./Section";
import XeniumCard from "./XeniumCard";

type XeniumCardProps = ComponentProps<typeof XeniumCard>;
interface Props {
  bottomSpacing?: SectionSpacings;
  cards: XeniumCardProps[];
  heading: string;
  slash?: boolean;
  topSpacing?: SectionSpacings;
}

function XeniumCardCollection({
  bottomSpacing,
  cards,
  heading,
  slash = false,
  topSpacing,
}: Props) {
  return (
    <Section
      bottomSpacing={bottomSpacing}
      mode={"dark"}
      slash={slash}
      topSpacing={topSpacing}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing32};
        `}
      >
        <Heading
          as={"h3"}
          color={"white"}
          css={css`
            margin: 0;
          `}
          responsive={true}
          size={"xlarge"}
          weight={"semibold"}
        >
          {heading}
        </Heading>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr;
            gap: ${spacing24};
            width: 100%;
            overflow: auto;

            @media (min-width: ${mediaTabletLandscape}) {
              grid-template-columns: repeat(2, 1fr);
              gap: ${spacing64};
            }
          `}
        >
          {cards.map((card) => (
            <XeniumCard key={card.link} {...card} />
          ))}
        </div>
      </div>
    </Section>
  );
}

export default XeniumCardCollection;
