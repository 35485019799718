import { css } from "@emotion/react";
import type { Props as ImageProps } from "../../../Image";
import Banner from "../../Masthead/Banner";
import Grid from "../../../Grid";
import { colorSteelDarkest } from "@10xdev/design-tokens";
import Image from "../../../Image";
import Text from "../../../Text";
import type { Text as TextInterface } from "../types";

interface Props {
  images?: ImageProps[];
  title?: TextInterface;
  summary?: TextInterface;
  eyebrow?: string;
  textAlign: "left" | "center";
  maxWidth?: string;
}

const ImageStyles = css`
  @media (max-width: 1070px) {
    display: none;
  }
`;

const Masthead = ({
  images,
  title,
  summary,
  eyebrow,
  textAlign = "center",
  maxWidth = "1500px",
}: Props) => {
  return (
    <Grid
      css={css`
        background: ${colorSteelDarkest};
        width: 100%;
      `}
      justifyItems={"center"}
      gap={{ base: "2rem", tabletPortrait: "3rem" }}
      padding={{ base: "0 1rem 2rem", tabletPortrait: "0 1rem 4.5rem" }}
      gridTemplateColumns={"1fr"}
    >
      <Banner mode={"dark"} maxWidth={maxWidth} />

      <Grid
        css={css`
          max-width: ${maxWidth};
          grid-template-columns: 1fr;
          @media (min-width: 1071px) {
            grid-template-columns: 1.5fr 4.5fr 1.5fr;
          }
        `}
        gap={"4.4%"}
        alignItems={"center"}
      >
        {images && images[0] && (
          <Image css={ImageStyles} src={images[0].src} alt={images[0].alt} />
        )}
        <Grid
          css={css`
            text-align: ${textAlign};
          `}
          gap={"1.5rem"}
        >
          <Grid gap={"0.5rem"}>
            <Text
              as={"h1"}
              color={"greenLight"}
              size={"large"}
              weight={"semibold"}
            >
              {eyebrow}
            </Text>
            {title && (
              <Text
                as={"h2"}
                color={"white"}
                size={title.size || "xxlarge"}
                weight={"semibold"}
              >
                {title.content}
              </Text>
            )}
          </Grid>
          {summary && (
            <Text
              as={"p"}
              css={css`
                padding: 0 5%;
              `}
              color={"white"}
              size={summary.size || "small"}
            >
              {summary.content}
            </Text>
          )}
        </Grid>
        {images && images[1] && (
          <Image css={ImageStyles} src={images[1].src} alt={images[1].alt} />
        )}
      </Grid>
    </Grid>
  );
};

export default Masthead;
