import {
  borderRadiusMedium,
  colorGreyscale7,
  colorSteelMedium,
  mediaPhoneOnly,
  spacing16,
  spacing24,
  spacing32,
  spacing48,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { MDXRemoteSerializeResult } from "next-mdx-remote";
import { MDXRemote } from "next-mdx-remote";

import Anchor from "../../Anchor";
import { MDXComponents } from "../../Blocks/MDX";
import Icon from "../../Icon";
import Text from "../../Text";

interface Props {
  description: string | MDXRemoteSerializeResult;
  image: {
    alt: string;
    src: string;
  };
  link: string;
  linkText: string;
  title: string;
}

const XeniumCard = ({ image, link, title, description, linkText }: Props) => {
  return (
    <Anchor
      css={css`
        background-color: ${colorGreyscale7};
        border: 1px solid ${colorSteelMedium};
        border-radius: ${borderRadiusMedium};
        box-shadow: none;
        display: inline-block;
        max-width: 556px;
        &:hover {
          box-shadow: 0px 2px 24px rgba(68, 89, 121, 0.5);
        }
      `}
      href={link}
      target={"_blank"}
    >
      <div
        css={css`
          background-image: url(${image.src});
          background-size: cover;
          border-radius: 0.375rem 0.375rem 0 0;
          box-sizing: border-box;
          height: 257px;
          overflow: hidden;
          width: 100%;
        `}
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing32};
          padding: ${spacing32} ${spacing48} ${spacing48};

          @media (max-width: ${mediaPhoneOnly}) {
            gap: ${spacing16};
            padding: ${spacing32};
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${spacing24};

            @media (max-width: ${mediaPhoneOnly}) {
              gap: ${spacing16};
            }
          `}
        >
          <Text as={"div"} color={"white"} size={"xlarge"} weight={"semibold"}>
            {title}
          </Text>
          {typeof description === "string" ? (
            <Text
              as={"div"}
              color={"steelLighter"}
              size={"medium"}
              weight={"regular"}
            >
              {description}
            </Text>
          ) : (
            <MDXRemote {...description} components={MDXComponents} />
          )}
        </div>
        <div
          css={css`
            align-items: center;
            display: flex;
            gap: ${spacing16};
          `}
        >
          <Text as={"div"} color={"white"} size={"medium"} weight={"medium"}>
            {linkText}
          </Text>
          <Icon
            color={"white"}
            role={"presentation"}
            size={"11px"}
            source={"nav-right"}
            yPos={"1px"}
          />
        </div>
      </div>
    </Anchor>
  );
};

export default XeniumCard;
