import {
  borderRadiusMedium,
  borderStandard,
  mediaDesktop,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../../Heading";
import Icon from "../../Icon";
import TableHead from "../../Table/TableHead";
import Text from "../../Text";

interface Props {
  compatibilityTableRows: Array<{
    options: Array<boolean>;
    products: Array<{
      name: string;
      slug: string;
    }>;
    reference: string;
  }>;
  subtitle: string;
  tableHeadings: Array<{
    colspan?: number;
    label?: string;
  }>;
  tableSubheadings: Array<string>;
  title: string;
}

const CompatibilityTable: FunctionComponent<Props> = ({
  compatibilityTableRows,
  subtitle,
  tableHeadings,
  tableSubheadings,
  title,
}) => {
  return (
    <div
      className={"CompatibilityTable"}
      css={css`
        max-width: 1200px;
        padding: 4rem 1rem;
        width: 100%;
      `}
    >
      <Heading
        as={"h3"}
        css={css`
          margin-bottom: 1rem;
        `}
        size={"xxlarge"}
      >
        {title}
      </Heading>
      <Text
        as={"p"}
        color={"midgray"}
        css={css`
          margin-bottom: 2.5rem;
        `}
        size={"medium"}
      >
        {subtitle}
      </Text>

      <div
        css={css`
          border: ${borderStandard};
          border-radius: ${borderRadiusMedium};
          width: 88%;

          @media (max-width: ${mediaDesktop}) {
            min-width: 1010px;
            width: 100%;
          }
        `}
      >
        <table
          css={css`
            border-collapse: collapse;
            width: 100%;
          `}
        >
          <TableHead>
            <tr
              css={css`
                border-bottom: ${borderStandard};

                :not(th:last-of-type) {
                  border-right: ${borderStandard};
                  width: 40%;
                }
              `}
            >
              {tableHeadings.map((heading) => {
                const { colspan, label } = heading;
                return (
                  <th colSpan={colspan} key={label}>
                    <Text
                      as={"div"}
                      css={css`
                        padding: 0.75rem 0;
                      `}
                      size={"large"}
                      weight={"semibold"}
                    >
                      {label}
                    </Text>
                  </th>
                );
              })}
            </tr>
          </TableHead>

          <tbody>
            <tr
              css={css`
                border-top: ${borderStandard};

                > td {
                  padding: 1rem;
                }

                > *:not(td:last-of-type) {
                  border-right: ${borderStandard};
                }

                > td:nth-of-type(n + 3) {
                  padding: 1rem 2rem;
                  text-align: center;
                  width: 20%;
                }
              `}
            >
              {tableSubheadings.map((subheading) => {
                return (
                  <Text
                    as={"td"}
                    key={subheading}
                    size={"medium"}
                    weight={"semibold"}
                  >
                    {subheading}
                  </Text>
                );
              })}
            </tr>

            {compatibilityTableRows.map((row, index) => {
              const { options, products, reference } = row;
              return (
                <tr
                  css={css`
                    border-top: ${borderStandard};
                  `}
                  key={`${reference}-${index}`}
                >
                  {products.length ? (
                    <th
                      align={"left"}
                      css={css`
                        border-right: ${borderStandard};
                        padding: 1rem;

                        > :not(div:last-of-type) {
                          margin-bottom: 0.75rem;
                        }
                      `}
                      rowSpan={2}
                    >
                      {products.map((product) => {
                        return (
                          <div key={product.name}>
                            <Text as={"span"} size={"medium"} weight={"medium"}>
                              {product.name}
                            </Text>
                          </div>
                        );
                      })}
                    </th>
                  ) : null}

                  <Text
                    as={"td"}
                    css={css`
                      border-right: ${borderStandard};
                      padding-left: 1rem;
                    `}
                    size={"medium"}
                    weight={"medium"}
                  >
                    {reference}
                  </Text>

                  {options.map((option, index) => {
                    return (
                      <td
                        align={"center"}
                        css={css`
                          padding: 1.25rem 1rem;

                          :not(td:last-of-type) {
                            border-right: ${borderStandard};
                          }
                        `}
                        key={`${option}-${index}`}
                        valign={"middle"}
                      >
                        {option ? (
                          <Icon
                            color={"success"}
                            size={"1rem"}
                            source={"checkmark"}
                          />
                        ) : (
                          <Icon
                            color={"midgray"}
                            size={"15px"}
                            source={"remove"}
                          />
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CompatibilityTable;
