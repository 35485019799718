import {
  colorBlueDark,
  colorBlueDarkest,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Text from "../../Text";

interface Props {
  linkObjects: Array<{
    optionalDescription: string | null;
    optionalTitle: string | null;
    url: string;
  }>;
}

const CTAPanel: FunctionComponent<Props> = ({ linkObjects: links }) => {
  return (
    <div
      className={"CTAPanel"}
      css={css`
        background-color: ${colorBlueDark};
        box-sizing: border-box;
        max-width: 1440px;
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;
      `}
    >
      {links ? (
        <div
          css={css`
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            width: 100%;
          `}
        >
          {links.slice(0, 2).map((link) => {
            const {
              optionalDescription: description,
              optionalTitle: title,
              url,
            } = link;
            return (
              <div
                css={css`
                  flex-basis: 100%;
                  margin-right: 0;

                  @media (min-width: ${mediaTabletLandscape}) {
                    box-sizing: border-box;
                    flex: 1;
                  }
                `}
                key={title}
              >
                <Anchor href={url}>
                  <div
                    css={css`
                      box-sizing: border-box;
                      height: 100%;
                      padding: 4rem 2rem;
                      transition: background 0.25s ease;

                      @media (min-width: ${mediaTabletLandscape}) {
                        padding: 4rem 16.66% 6rem;
                        padding-right: 33.33%;

                        :hover {
                          background: ${colorBlueDarkest};
                        }
                      }
                    `}
                  >
                    <Heading as={"h3"} color={"white"} size={"xxlarge"}>
                      {title}
                    </Heading>
                    <Text as={"div"} color={"white"} size={"medium"}>
                      {description}
                      <Icon
                        color={"white"}
                        size={"11px"}
                        source={"nav-right"}
                        xPos={"12px"}
                        yPos={"2px"}
                      />
                    </Text>
                  </div>
                </Anchor>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default CTAPanel;
