import { borderStandard } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const TableHead = ({ children }: Props) => {
  return (
    <th
      css={css`
        padding: 1rem 0.75rem;
        text-align: left;
        border-bottom: ${borderStandard};
      `}
    >
      {children}
    </th>
  );
};

export default TableHead;
