import { css } from "@emotion/react";
import Card from "./Card";
import CardHeader from "./CardHeader";
import Text from "../../../../Text";
import Grid from "../../../../Grid";
import Image from "../../../../Image";
import type { Positive } from "../ClarificationCards";
import Link from "../../Link";
import { borderRadiusMedium } from "@10xdev/design-tokens";

const PositiveLink = ({
  image,
  title,
  summary,
  link,
  gif,
}: Omit<Positive, "type">) => {
  const gridTemplateColumns = image ? "auto 1fr" : "1fr";

  return (
    <Card>
      <Grid
        alignItems={"center"}
        gridTemplateColumns={{
          base: "1fr",
          tabletLandscape: gridTemplateColumns,
        }}
        gap={"1.5rem"}
      >
        {image && <Image src={image.src} alt={image?.alt} width={"200px"} />}
        <Grid gap={"1rem"}>
          <Grid alignContent={"start"} gap={".5rem"}>
            <CardHeader title={title} size={"small"} />
            <Text as={"p"} size={"small"}>
              {summary}
            </Text>
          </Grid>
          {link?.url && <Link label={link.label} url={link.url} />}
        </Grid>
        {gif && (
          <video
            autoPlay
            css={css`
              border-radius: ${borderRadiusMedium};
              height: auto;
              width: 100%;
            `}
            loop
            muted
            playsInline
          >
            <source src={gif.src} type={"video/mp4"} />
          </video>
        )}
      </Grid>
    </Card>
  );
};

export default PositiveLink;
