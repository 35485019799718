import { borderStandard, mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

const TableHeadStyle = css`
  border-bottom: ${borderStandard};
  @media (max-width: ${mediaTabletLandscape}) {
    width: auto;
  }
`;

const TableHead: FunctionComponent<Props> = ({ children }) => {
  return (
    <thead css={TableHeadStyle}>
      <tr>{children}</tr>
    </thead>
  );
};

export default TableHead;
