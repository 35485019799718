import {
  borderRadiusMedium,
  colorWhite,
  layoutWidth878,
  mediaTabletPortrait,
  sizeMedium,
  sizeSmall,
  sizeXlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useState } from "react";

import Card from "../Card";
import Heading from "../Heading";
import Icon from "../Icon";
import Text from "../Text";
import WistiaPopover from "../WistiaPopover";

interface Props {
  background?: string;
  hover?: boolean;
  info: string;
  thumbnail: string;
  title: string;
  wistiaId: string;
}

const VideoCard: FunctionComponent<Props> = ({
  background,
  hover,
  info,
  thumbnail,
  title,
  wistiaId,
}) => {
  const [showVideo, setShowVideo] = useState(false);

  const handleClick = () => setShowVideo(true);
  const handleClose = () => setShowVideo(false);

  return (
    <button
      css={css`
        background: transparent;
        border: 0;
        box-sizing: border-box;
        color: inherit;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        font: inherit;
        padding: 0;
        margin: 0;
        margin-bottom: ${sizeMedium};
        outline: inherit;

        @media (max-width: ${layoutWidth878}) {
          margin-right: 1rem;
        }
      `}
      onClick={handleClick}
    >
      <Card
        css={css`
          align-items: center;
          background: ${background};
          box-shadow: rgb(21 48 87 / 15%) 0px 1px 0px;
          flex-direction: row;
          height: auto;
          min-height: 214px;
          overflow: hidden;
          padding: ${sizeMedium} 4rem ${sizeMedium} ${sizeMedium};
          position: relative;
          text-align: left;
          transition: all 0.2s ease-in;
          width: 100%; /* for IE11 */
          flex-direction: row;

          &:hover {
            .video-card {
              opacity: 1;
            }
          }

          @media (max-width: ${layoutWidth878}) {
            align-items: flex-start;
            background-color: ${colorWhite};
            flex-direction: column;
            min-height: 298px;
            min-width: 280px;
            padding: 0;
          }
        `}
        hoverable={hover}
        outline={false}
      >
        <div
          css={css`
            border-radius: ${borderRadiusMedium};
            flex: 0 0 48%;
            margin-right: ${sizeXlarge};
            max-height: 182px;
            overflow-y: hidden;
            position: relative;

            :before {
              background: url("https://cdn.10xgenomics.com/image/upload/v1588968680/icons/play.svg")
                no-repeat;
              background-position: center center;
              content: "";
              height: 100%;
              position: absolute;
              width: 100%;
              z-index: 1;
            }

            @media (max-width: ${layoutWidth878}) {
              border-radius: 0;
              max-height: 143px;
              width: 100%;
            }
          `}
        >
          <img
            alt={""}
            css={css`
              border-radius: ${borderRadiusMedium};
              filter: brightness(80%);
              max-width: 100%;
            `}
            src={thumbnail}
          />
        </div>
        <div
          css={css`
            @media (max-width: ${layoutWidth878}) {
              display: flex;
              flex-direction: column;
              height: 100%;
              justify-content: space-between;
              padding: 1rem 1.5rem;
            }
          `}
        >
          <div
            css={css`
              display: block;

              @media (max-width: ${layoutWidth878}) {
                display: none;
              }
            `}
          >
            <Heading
              as={"h4"}
              css={css`
                margin-bottom: ${sizeSmall};
              `}
              size={"large"}
            >
              {title}
            </Heading>
          </div>
          <div
            css={css`
              display: none;

              @media (max-width: ${layoutWidth878}) {
                display: block;
              }
            `}
          >
            <Heading
              as={"h4"}
              css={css`
                margin-bottom: ${sizeSmall};
              `}
              size={"medium"}
            >
              {title}
            </Heading>
          </div>
          <div
            css={css`
              display: block;

              @media (max-width: ${layoutWidth878}) {
                display: none;
              }
            `}
          >
            <Text as={"p"} color={"steelDark"} size={"xsmall"}>
              {info}
            </Text>
          </div>
          <div
            css={css`
              display: none;

              @media (max-width: ${layoutWidth878}) {
                display: block;
              }
            `}
          >
            <Text as={"p"} color={"steelDark"} size={"xxsmall"}>
              {info}
            </Text>
          </div>
        </div>
        <div
          css={css`
            position: absolute;
            right: 1.5rem;
            top: 50%;
            transform: translateY(-50%);
            transition: opacity 0.3s ease;

            @media (max-width: ${layoutWidth878}) {
              display: none;
            }
          `}
        >
          <div
            className={"video-card"}
            css={css`
              opacity: 0;
              transition: opacity 0.2s ease-in;

              @media (max-width: ${mediaTabletPortrait}) {
                opacity: 1;
              }
            `}
          >
            <Icon color={"blue"} size={"17px"} source={"nav-right"} />
          </div>
        </div>

        {showVideo ? (
          <WistiaPopover id={wistiaId} onClose={handleClose} />
        ) : null}
      </Card>
    </button>
  );
};

export default VideoCard;
