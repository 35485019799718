import type { Props as Image } from "../../../Image";
import Card from "./Card";
import Grid from "../../../Grid";
import Header from "../Header";
import type { Props as HeaderInterface } from "../Header/Header";
import Cta from "../CTA";
import type { Props as CtaInterface } from "../CTA/CTA";

export type Publication = {
  label: string;
  title: string;
  url: string;
  summary: string;
};

export type Application = {
  image: Image;
  title: string;
  summary: string;
  publication: Publication;
};

interface Props {
  header: HeaderInterface;
  applications: Application[];
  cta: CtaInterface;
}

const ResearchCards = ({ header, applications, cta }: Props) => {
  return (
    <Grid gap={"2rem"}>
      <Header {...header} />
      <Grid gap={"1rem"}>
        {applications.map((application) => {
          return <Card {...application} />;
        })}
      </Grid>
      <Cta {...cta} />
    </Grid>
  );
};

export default ResearchCards;
