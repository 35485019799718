import { css } from "@emotion/react";
import Flex from "../../Flex";
import Text from "../../Text";

const Pill = ({
  color,
  name,
  className,
}: {
  color: string;
  name: string;
  className?: string;
}) => {
  return (
    <Flex
      alignItems={"center"}
      css={css`
        background: ${color};
        border-radius: 6px;
        height: 20px;
        width: 90px;
      `}
      justifyContent={"center"}
      padding={"2px 6px"}
      className={className}
    >
      <Text as="span" color="steelDarker" size="xxxsmall" weight="semibold">
        {name.toUpperCase()}
      </Text>
    </Flex>
  );
};

export default Pill;
