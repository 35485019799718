import { css } from "@emotion/react";
import { borderRadiusMedium, borderStandard } from "@10xdev/design-tokens";

import Grid from "../../Grid";
import Text from "../../Text";
import Image from "../../Image";
import Link from "../Link";

interface Props {
  name: string;
  description: string;
  icon: string;
  slug: string;
}

const Product = ({ name, description, icon, slug }: Props) => {
  return (
    <Grid
      alignContent={"space-between"}
      css={css`
        border: ${borderStandard};
        border-radius: ${borderRadiusMedium};
        box-sizing: border-box;
        flex: 0 0 360px;
        padding: 1.5rem;
        width: 360px;
      `}
      gap={"1rem"}
    >
      <Grid gap={"1rem"}>
        <Image src={icon} alt={name} width={72} />
        <Text as={"h5"} size={"medium"} weight={"semibold"}>
          {name}
        </Text>
        <Text as={"p"} color={"midgray"} size={"medium"}>
          {description}
        </Text>
      </Grid>
      <Link label={"Learn more"} url={`/products/${slug}`} icon={"nav-right"} />
    </Grid>
  );
};

export default Product;
