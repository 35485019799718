import {
  colorSteelLightest,
  mediaTabletLandscape,
  sizeMedium,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Text from "../Text";

interface Props {
  className?: string;
  description?: string;
  label?: string;
}

const RowHeadStyle = css`
  box-sizing: border-box;
  background-color: ${colorSteelLightest};
  text-align: left;
  padding: 1.5rem 2rem;
  text-align: left;
  vertical-align: top;
  width: 20%;

  @media (max-width: ${mediaTabletLandscape}) {
    padding: ${sizeMedium};
    max-width: 200px;
  }
`;

const RowHead: FunctionComponent<Props> = ({
  className,
  label,
  description,
}) => {
  return (
    <th className={className} css={RowHeadStyle}>
      <Text
        as={"p"}
        css={css`
          margin-bottom: ${description ? "16px" : 0};
        `}
        responsive={true}
        size={"small"}
        weight={"medium"}
      >
        {label}
      </Text>
      {description ? (
        <Text as={"span"} color={"midgray"} responsive={true} size={"xsmall"}>
          {description}
        </Text>
      ) : null}
    </th>
  );
};

export default RowHead;
