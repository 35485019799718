import { colorBlueDark, fontLetterSpacingCaps } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../../Anchor";
import Icon from "../../../Icon";
import Text from "../../../Text";

interface Props {
  resources?: {
    links?: Array<any>;
    title?: string;
  };
}

const Resources: FunctionComponent<Props> = ({ resources }) => {
  return (
    <>
      <div
        css={css`
          margin-bottom: 0.5rem;
        `}
      >
        {resources ? (
          <Text
            as={"span"}
            color={"gray"}
            css={css`
              letter-spacing: ${fontLetterSpacingCaps};
              text-transform: uppercase;
            `}
            responsive={true}
            size={"xxsmall"}
            weight={"semibold"}
          >
            {resources.title}
          </Text>
        ) : null}
      </div>

      <ul
        css={css`
          display: grid;
          gap: 4px;
          list-style: none;
          padding: 0;
        `}
      >
        {resources?.links?.map((link) => {
          const { label, type, url } = link;

          return (
            <li key={label}>
              {url ? (
                <Text
                  as={"span"}
                  color={"blue"}
                  responsive={true}
                  size={"small"}
                  weight={"medium"}
                >
                  <Anchor
                    color={"inherit"}
                    hoverColor={colorBlueDark}
                    href={url}
                  >
                    {label}
                    <Icon
                      size={"12px"}
                      source={type}
                      xPos={"16px"}
                      yPos={"1px"}
                    />
                  </Anchor>
                </Text>
              ) : (
                <Text
                  as={"span"}
                  css={css`
                    display: block;
                  `}
                  responsive={true}
                  size={"small"}
                  weight={"semibold"}
                >
                  {label}
                </Text>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Resources;
