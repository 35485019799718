import {
  colorBlueMedium,
  colorSteelLighter,
  colorWhite,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import Button from "../../Button";
import Flex from "../../Flex";
import Text from "../../Text";
import { VersionDetails } from "./types";

interface VersionSelectorProps {
  isSearched: boolean;
  selectedVersion: VersionDetails;
  onSelectVersion: (index: string) => void;
  versions: VersionDetails[];
}

export const VersionSelector = ({
  isSearched,
  selectedVersion,
  onSelectVersion,
  versions,
}: VersionSelectorProps) => {
  return (
    <Flex flexWrap="wrap" gap="0.5rem" margin="0.75rem 0 0">
      {versions.map(({ name, subgroups, tag }) => {
        const isSelectedVersion = selectedVersion.name === name;

        const matchingProductCount = subgroups.reduce(
          (acc, subgroup) => acc + subgroup.details.length,
          0,
        );
        const matchLabel = (
          matchingProductCount > 1 ? "matches" : "match"
        ).toUpperCase();

        return (
          <Button
            key={name}
            onClick={() => onSelectVersion(name)}
            css={css`
              background-color: ${isSelectedVersion
                ? colorBlueMedium
                : colorWhite};
              border: 1px solid
                ${isSelectedVersion ? colorBlueMedium : colorSteelLighter};
              border-radius: 50px;
              flex-shrink: 0;
              padding: 0.25rem 0.75rem;
              height: 30px;
              width: auto;

              &:hover:not(:disabled) {
                background: ${isSelectedVersion ? colorBlueMedium : colorWhite};
              }
            `}
          >
            <Flex alignItems="center" gap="0.5rem">
              <Text
                as="span"
                color={isSelectedVersion ? "white" : "steelDarkest"}
                size="xsmall"
                weight="regular"
              >
                {name}
              </Text>
              {tag && !isSearched ? (
                <Text
                  as="span"
                  color={isSelectedVersion ? "white" : "blueMedium"}
                  size="xxxsmall"
                  weight="semibold"
                >
                  {tag.toUpperCase()}
                </Text>
              ) : null}
              {isSearched ? (
                <Text
                  as="span"
                  color={isSelectedVersion ? "white" : "blueMedium"}
                  size="xxxsmall"
                  weight="semibold"
                >
                  {matchingProductCount} {matchLabel}
                </Text>
              ) : null}
            </Flex>
          </Button>
        );
      })}
    </Flex>
  );
};
