import { css } from "@emotion/react";
import { borderStandard } from "@10xdev/design-tokens";
import Grid from "../../../Grid";
import Flex from "../../../Flex";
import Image from "../../../Image";
import Text from "../../../Text";
import Link from "../../../ProductPage/Link";
import type { ListProps } from "./Specifications";
import Anchor from "../../../Anchor";
import type { Link as LinkType } from "./Specifications";

interface Props {
  list: ListProps[];
  title: string;
  link?: LinkType;
  variant?: "list" | "category";
}

const List = ({ list, title, variant = "list", link }: Props) => {
  return (
    <Grid
      css={css`
        padding: 0.75rem 0;
        border-bottom: ${variant === "list" ? borderStandard : "none"};
      `}
      gap={"1rem 5rem"}
      gridTemplateColumns={{ base: "1fr", tabletPortrait: "32% auto" }}
    >
      <div>
        <Text
          as={"h4"}
          size={variant === "list" ? "large" : "medium"}
          weight={variant === "list" ? "semibold" : "medium"}
        >
          {title}
        </Text>
        {link ? (
          <Link url={link.url} label={link.label} icon={link.icon} />
        ) : null}
      </div>
      <Flex gap={"1rem"} alignItems={"start"} flexWrap={"wrap"}>
        {list.map((item) => {
          const { icon, label, link } = item;
          return (
            <Grid
              css={css`
                width: 112px;
              `}
              gap={"10px"}
              gridTemplateRows={"38px auto"}
            >
              {icon ? <Image src={icon} /> : null}
              {link ? (
                <Anchor href={link}>
                  <Text
                    as={"span"}
                    color={"inherit"}
                    size={"xsmall"}
                    weight={"semibold"}
                  >
                    {label}
                  </Text>
                </Anchor>
              ) : (
                <Text as={"span"} color={"midgray"} size={"xsmall"}>
                  {label}
                </Text>
              )}
            </Grid>
          );
        })}
      </Flex>
    </Grid>
  );
};

export default List;
