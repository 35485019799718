import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { Children } from "react";

interface Props {
  children: any;
  colMinWidth?: string;
  wrap?: boolean;
}

const Columns: FunctionComponent<Props> = ({
  children,
  colMinWidth = "400px",
  wrap = true,
}) => {
  const count = Children.toArray(children).length;
  return (
    <div
      css={css`
        display: flex;
        flex-wrap: ${wrap ? "wrap" : "nowrap"};
        width: 100%;

        > * {
          box-sizing: border-box;
          flex: 1 0 ${100 / count}%;
          min-width: ${colMinWidth};
        }
      `}
    >
      {children}
    </div>
  );
};

export default Columns;
