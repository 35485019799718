import {
  borderRadiusMedium,
  borderStandard,
  colorBlueDark,
  colorBlueLightest,
  layoutWidth1200,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Paragraph from "../../Paragraph";
import Text from "../../Text";

interface Props {
  callouts: Array<any>;
  features: Array<{
    description?: string;
    title?: string;
  }>;
  footnote?: string;
  overview?: string;
  title?: string;
}

const Assays: FunctionComponent<Props> = ({
  callouts,
  features,
  footnote,
  overview,
  title,
}) => {
  return (
    <div
      className={"Assays"}
      css={css`
        max-width: ${layoutWidth1200};
        padding: 4rem 1rem;
      `}
    >
      <Heading
        as={"h3"}
        css={css`
          border-bottom: ${borderStandard};
          margin-bottom: 3.5rem;
          padding-bottom: 1.5rem;
        `}
        responsive={true}
        size={"xlarge"}
      >
        {title || ""}
      </Heading>

      <div
        css={css`
          display: flex;

          @media (max-width: ${mediaTabletLandscape}) {
            display: block;
          }
        `}
      >
        <div
          css={css`
            width: 50%;

            @media (max-width: ${mediaTabletLandscape}) {
              margin-bottom: 4rem;
              width: 100%;
            }
          `}
        >
          <Heading
            as={"h4"}
            css={css`
              margin-bottom: 2rem;
            `}
            responsive={true}
            size={"medium"}
          >
            {overview}
          </Heading>
          {callouts?.map((callout) => {
            const { description, link, title } = callout;

            // Keying on description is a little strange,
            // but title can be undefined.
            return (
              <div
                css={css`
                  background-color: ${colorBlueLightest};
                  border-radius: ${borderRadiusMedium};
                  margin-bottom: 1.5rem;
                  padding: 1.5rem;
                `}
                key={title || description}
              >
                <Heading
                  as={"h5"}
                  color={"midgray"}
                  css={css`
                    margin-bottom: 1rem;
                  `}
                  size={"small"}
                >
                  {title}
                </Heading>
                <Text
                  as={"p"}
                  color={"midgray"}
                  css={css`
                    margin-bottom: 1.5rem;
                  `}
                  size={"small"}
                >
                  {description}
                </Text>
                <Text
                  as={"div"}
                  color={"blue"}
                  size={"small"}
                  weight={"semibold"}
                >
                  <Anchor
                    color={"inherit"}
                    hoverColor={colorBlueDark}
                    href={link.url}
                  >
                    {link.label}
                    {link.type ? (
                      <Icon
                        size={"12px"}
                        source={link.type}
                        xPos={"16px"}
                        yPos={"2px"}
                      />
                    ) : null}
                  </Anchor>
                </Text>
              </div>
            );
          })}

          {footnote ? (
            <Text
              as={"p"}
              color={"midgray"}
              css={css`
                margin-bottom: 0;
              `}
              responsive={true}
              size={"small"}
            >
              {footnote}
            </Text>
          ) : null}
        </div>

        <div
          css={css`
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding-left: 10%;
            width: 50%;

            @media (max-width: ${mediaTabletLandscape}) {
              padding: 0;
              width: 100%;
            }
          `}
        >
          {features?.map((feature) => {
            const { description, title } = feature;
            return (
              <div key={title || description}>
                <Heading
                  as={"h5"}
                  css={css`
                    margin-bottom: 0.5rem;
                  `}
                  responsive={true}
                  size={"medium"}
                >
                  {title}
                </Heading>
                <Paragraph
                  color={"midgray"}
                  css={css`
                    margin-bottom: 0;
                  `}
                  responsive={true}
                  size={"medium"}
                >
                  {description}
                </Paragraph>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Assays;
