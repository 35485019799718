import { css } from "@emotion/react";
import {
  borderRadiusMedium,
  borderStandard,
  colorWhite,
  mediaTabletPortrait,
} from "@10xdev/design-tokens";

import Grid from "../../Grid";
import Text from "../../Text";
import Image from "../../Image";
import Icon from "../../Icon";
import Button from "../Button";
import type { Callout } from "../../Blocks/ProductPage/Components";

const Analysis = ({ title, description, image, cta }: Callout) => {
  return (
    <Grid
      alignItems={"center"}
      css={css`
        background-color: ${colorWhite};
        border: ${borderStandard};
        border-radius: ${borderRadiusMedium};
        box-shadow: 0px 1.72px 4.29px 0px rgba(0, 0, 0, 0.06);
        padding: 1.5rem;
        @media (min-width: ${mediaTabletPortrait}) {
          padding: 0.5rem 2.5rem;
        }
      `}
      gap={{ base: "1rem", tabletPortrait: "2.5rem" }}
      gridTemplateColumns={{ base: "1fr", tabletPortrait: "142px 1fr auto" }}
    >
      <Image src={image.src} alt={image.alt} />
      <Grid gap={".25rem"} alignItems={"start"}>
        <Text as={"h5"} size={"medium"} weight={"semibold"}>
          {title}
        </Text>
        <Text as={"p"} color={"midgray"} size={"medium"}>
          {description}
        </Text>
      </Grid>
      {cta ? (
        <Button href={cta.url}>
          <Text as={"span"} color={"white"} size={"medium"} weight={"medium"}>
            {cta.label}
          </Text>
          <Icon
            color={"inherit"}
            css={css`
              flex-shrink: 0;
            `}
            size={"11.5px"}
            source={"nav-right"}
            yPos={"2px"}
          />
        </Button>
      ) : null}
    </Grid>
  );
};

export default Analysis;
